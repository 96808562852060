const short = require("short-uuid");
const { API, graphqlOperation } = require("aws-amplify");
const {
  listKonoikeConcreteData,
  getKonoikeConcreteData,
} = require("@/graphql/queries");
const baseModel = require("../baseModel")

/**
 * 打ち重ね情報用のデータ取得モデルクラス
 */
class OverlayModel extends baseModel {
  /**
   * 打ち重ね情報リストを取得する
   * @param {str} tenantId テナントID
   * @param {str} skPrefix skのプレフィックス
   * @returns {Array} 打ち重ね情報リスト
   */
  static async getOverlayListByPrefix(tenantId, skPrefix) {
    try {
      const result = await API.graphql(
        graphqlOperation(listKonoikeConcreteData, {
          pk: `${tenantId}`,
          sk: { beginsWith: skPrefix }
        })
      );

      // APIレスポンスが存在し、データがあればそのアイテムを返す
      return result?.data?.listKonoikeConcreteData.items || [];
    } catch (error) {
      console.error("Error fetching overlay list:", error);
      return [];
    }
  }

  /**
   * 打ち重ね情報リストを取得する（テナント単位）
   * @param {str} tenantId テナントID
   * @returns {Array} 打ち重ね情報リスト
   */
  static async getOverlayList(tenantId) {
    return this.getOverlayListByPrefix(tenantId, "overlay#");
  }

  /**
   * 打ち重ね情報リストを取得する（プロジェクト単位）
   * @param {str} tenantId テナントID
   * @param {str} projectId プロジェクトID
   * @returns {Array} 打ち重ね情報リスト
   */
  static async getOverlayListByProject(tenantId, projectId) {
    return this.getOverlayListByPrefix(tenantId, `overlay#${projectId}#`);
  }

  /**
   * 打ち重ね情報リストを取得する（図面単位）
   * @param {str} tenantId テナントID
   * @param {str} projectId プロジェクトID
   * @param {str} overlayDrawingId 図面ID
   * @returns {Array} 打ち重ね情報リスト
   */
  static async getOverlayListByDrawing(tenantId, projectId, overlayDrawingId) {
    return this.getOverlayListByPrefix(tenantId, `overlay#${projectId}#${overlayDrawingId}#`);
  }

  /**
   * 打ち重ね情報リストを取得する（ポイント単位）
   * @param {str} tenantId テナントID
   * @param {str} projectId プロジェクトID
   * @param {str} overlayDrawingId 図面ID
   * @param {str} overlayPointId ポイントID
   * @returns {Array} 打ち重ね情報リスト
   */
  static async getOverlayListByPoint(tenantId, projectId, overlayDrawingId, overlayPointId) {
    return this.getOverlayListByPrefix(tenantId, `overlay#${projectId}#${overlayDrawingId}#${overlayPointId}#`);
  }

  /**
   * 打ち重ね情報レコードを追加する
   * @param {*} data
   */
  static async newOverlay(projectId, overlayDrawingId, overlayPointId, num, status, startTime) {
    const data = await this.getNewData(projectId, overlayDrawingId, overlayPointId);
    data.overlay.puringNumber = num;
    data.overlay.puringStatus = status;
    data.overlay.startTime= startTime;
    await super.addData(data);

    return data;
  }
  
  // The variables input contains a field that is not defined for input object type 'CreateKonoikeConcreteDataInput' 
  /**
   * 打ち重ね情報レコードを追加する
   * @param {*} data
   */
  static async addOverlay(data) {
    await super.addData(data);
  }

  /**
   * 打ち重ね情報レコードを更新する
   * @param {*} data
   */
  static async updateOverlay(data) {
    await super.updateData(data);
  }

  /**
   * 打ち重ね情報レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteOverlay(data) {
    await super.deleteData(data);
  }

  /**
   * 打ち重ね情報レコードを取得する
   * @param {str} tenantId          テナントID
   * @param {str} projectId         プロジェクトID
   * @param {str} overlayDrawingId  打ち重ね図面ID
   * @param {str} overlayPointId    打ち重ね範囲ID
   * @param {str} overlayId         打ち重ね情報Id
   */
  static async getOverlay(tenantId, projectId, overlayDrawingId, overlayPointId, overlayId) {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteData, { 
        pk: tenantId, 
        sk: `overlay#${projectId}#${overlayDrawingId}#${overlayPointId}#${overlayId}`
      })
    );

    return result.data.getKonoikeConcreteData;
  }

  /**
   * skの文字列を生成
   * overlay#プロジェクトID#打ち重ね図面ID#打ち重ね範囲ID#打ち重ね情報Id
   * @param {*}
   * @returns
   */
  static createSk(projectId, overlayDrawingId, overlayPointId, id) {
    return `overlay#${projectId}#${overlayDrawingId}#${overlayPointId}#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, overlayDrawingId, overlayPointId) {
    let newRowData = await super.getNewData()
    const id = short.generate()

    newRowData.sk = this.createSk(projectId, overlayDrawingId, overlayPointId, id);
    newRowData.overlay = {
      id: id,
      puringNumber: 0,
      puringStatus: 0,
      startTime: "",
      endTime: "",
      elapsedTime: "",
      completeTime: ""
    };

    return newRowData;
  }
}

module.exports = OverlayModel;

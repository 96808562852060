const short = require("short-uuid");
const { API, graphqlOperation } = require("aws-amplify");
const {
  listKonoikeConcreteData,
  getKonoikeConcreteData,
} = require("@/graphql/queries");
const baseModel = require("../baseModel")

/**
 * 経過時間設定用のデータ取得モデルクラス
 */
class OverlayTimeSettingModel extends baseModel {
  /**
   * 経過時間設定リストを取得する
   * @returns
   */
  static async getOverlayTimeSettingList(tenantId) {
    const result = await API.graphql(
      graphqlOperation(listKonoikeConcreteData, {
        pk: `${tenantId}`,
        sk: { beginsWith: "overlayTimeSetting#" }
      })
    );

    if (result) {
      if (result.data) {
        if (result.data.listKonoikeConcreteData.items.length === 0) {
          return [await this.getDefaultTimeSetting(tenantId)];
        }

        return result.data.listKonoikeConcreteData.items;
      }
    }

    return [];
  }

  /**
   * 経過時間設定レコードを追加する
   * @param {*} data
   */
  static async addOverlayTimeSetting(data) {
    await super.addData(data);
  }

  /**
   * 経過時間設定レコードを更新する
   * @param {*} data
   */
  static async updateOverlayTimeSetting(data) {
    await super.updateData(data);
  }

  /**
   * 経過時間設定レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteOverlayTimeSetting(data) {
    await super.deleteData(data);
  }

  /**
   * 経過時間設定レコードを取得する
   * @param {*} data
   */
  static async getOverlayTimeSetting(tenantId, overlayTimeSettingId) {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteData, {
        pk: `${tenantId}`,
        sk: `overlayTimeSetting#${overlayTimeSettingId}`
      })
    );

    return result.data.getKonoikeConcreteData;
  }

  /**
   * デフォルト経過時間設定
   * @param {*} tenantId
   * @returns
   */
  static async getDefaultTimeSetting(tenantId) {
    let timeSettingData = await this.getNewData(tenantId);
    const overlayTimeSets = [
      { "itemNumber": 1, "elapsedFrom": 0, "elapsedTo": 30, "colorId": "#00B0F0", "elapsedAlert": 1 },
      { "itemNumber": 2, "elapsedFrom": 30, "elapsedTo": 60, "colorId": "#92D050", "elapsedAlert": 0 },
      { "itemNumber": 3, "elapsedFrom": 60, "elapsedTo": 90, "colorId": "#FFFFC0", "elapsedAlert": 0 },
      { "itemNumber": 4, "elapsedFrom": 90, "elapsedTo": 120, "colorId": "#FFFC00", "elapsedAlert": 1 },
      { "itemNumber": 5, "elapsedFrom": 120, "elapsedTo": 150, "colorId": "#FFC000", "elapsedAlert": 0 },
      { "itemNumber": 6, "elapsedFrom": 150, "elapsedTo": null, "colorId": "#FF0000", "elapsedAlert": 0 }
    ];
    timeSettingData.overlayTimeSetting.name = "デフォルト設定";
    timeSettingData.overlayTimeSetting.isBaseTemplate = true;
    timeSettingData.overlayTimeSetting.overlayTimeSets = overlayTimeSets;

    return timeSettingData;
  }

  /**
   * skの文字列を生成
   * overlayTimeSetting#経過時間設定Id
   * @param {*}
   * @returns
   */
  static createSk(id) {
    return `overlayTimeSetting#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(tenantId) {
    let newRowData = await super.getNewData();
    const id = short.generate();

    newRowData.pk = tenantId;
    newRowData.sk = this.createSk(id);
    newRowData.overlayTimeSetting = {
      id: id,
      name: "",
      overlayTimeSets: []
    };

    return newRowData;
  }
}

module.exports = OverlayTimeSettingModel;

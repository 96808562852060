<template>
  <application-header
    :title="appTitle"
    :isPlandate="true"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isDriveVue="true"
    :isOverlay="true"
    @driveVue="onClickDriveVue"
  >
  </application-header>
  <div class="d-flex">
    <div class="row ml-0 mt-2">
      <div class="col-sm-12">
        <a href="#!" @click.prevent="onClickReturn()">{{returnScreen}}</a>
      </div>
    </div>
  </div>
  <!----------------------------------------------------
    打ち重ね図面登録、打設範囲登録、経過時間設定ボタン
  ------------------------------------------------------>
  <div class="d-flex button-area">
    <div class="col-sm-2">
          <base-button type="primary" :disabled="true" block >図面登録</base-button>
      </div>
      <div class="col-sm-2">
          <base-button type="primary" :disabled="!isAdd" block @click="onClickOverlayPointRegister">打設範囲登録</base-button>
      </div>
      <div class="col-sm-2">
          <base-button type="primary" :disabled="!isAdd" block @click="onClickOverlayTimeSetting">経過時間設定</base-button>
      </div>
  </div>
  <el-form :model="formData" :rules="rules" ref="form" >
    <div class="overlaydrawing_area">
      <div style="margin-left:15px;margin-top:20px" >
        <div class="row">
          <div class="col-lg-12 mt-3">
            <h4>図面名<require-tag /></h4>
            <el-form-item prop="overlaydrawingname">
              <el-input
                placeholder="図面名"
                v-model="formData.overlaydrawingname"   :disabled="isAdd"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <h4>図面ファイル<require-tag /></h4>
            <div class="d-flex flex-column  pr-3"  >
              <el-upload
                class="flex-fill w-100 justify-content-left align-items-baselin upload-demo"
                action=""
                drag
                v-if="uploadFiles.length == 0"
                ref="upload"
                :auto-upload="false"
                :thumbnail-mode="true"
                :multiple="false"
                :accept="'.pdf,.png,.jpg'"
                :on-change="addFile"
                :on-remove="removeFile"
                :disabled="uploadFiles.length > 0 || isPassedDay"
                :file-list="uploadFiles"
              >
                <div class="d-flex flex-column">

                  <div class="flex-fill ">
                    <div class="align-self-center mt-3">ここにファイルをドラッグ＆ドロップ、</div>
                  </div>
                  <div class="flex-fill ">
                    <div class="align-self-center">または</div>
                  </div>
                  <div class="flex-fill">

                      <el-button size="small" type="primary" :disabled="isPassedDay" class="bg-primary border-0 ml-2 mr-2 mt-3 mb-3">図面ファイル選択</el-button>

                    </div>
                  <div style="font-size:0.8rem;">1ファイル100MBまでアップロードできます</div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="row" v-if="uploadFiles.length > 0">
          <div class="d-flex col-lg-12">
            <div class="flex-grow-1">
            <el-input
                placeholder="図面名"
                v-model="uploadFiles[0].name"   :disabled="true"
              ></el-input></div>
              <base-button style="margin-left:15px;"
                type="primary" v-if="isAdd==false"
                @click="onClickRemoveFile"
                >図面ファイル選択解除</base-button
              >
          </div>
        </div>
        <div class="row">
          <div class="my-3">
            <div style="width: 480px; margin:0 auto;margin-left: 400px;">
              <div class="row mt-2 mb-2 ml-2 mr-2">

                <div class="col-sm-6" v-if="isAdd==false">
                  <base-button type="primary" block @click="onClickSave" :disabled="isPassedDay"
                    >保存</base-button
                  >
                </div>
                <div class="col-sm-6" v-if="isAdd">
                  <base-button type="primary" block @click="onClickDelete" :disabled="isPassedDay || usePouring"
                    >図面削除</base-button
                  >
                </div>
              </div>
            </div>
          </div>
           <!-- テナント削除確認モーダル -->
          <modal v-model:show="modals.confirmDlg">
            <template v-slot:header>
                <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
            </template>
            <div class="mb-5">
              <p class="message">以下のデータは全部削除されます。よろしいですか？</p>
              <ul class="data-list">
                  <li>打ち重ね図面データ</li>
                  <li>打設範囲データ</li>
                  <li>経過時間設定データ</li>
              </ul>
            </div>
            <div class="row mt-2 mb-2 ml-2 mr-2">
              <div class="col-sm-5">
                <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
              </div>
              <div class="col-sm-2">
              </div>
              <div class="col-sm-5">
                <base-button type="danger" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </el-form>
</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */
.button-area {
  margin-top: 10px;
  width: 100%;
  height: 10px;
}

.overlaydrawing_area {
  /* position:absolute; */
  margin-top: 50px;
  width: 100%;
  height: 100%;
  background-color: white;
}

::v-deep .el-upload-dragger {
 width: 580px;
}

</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import { useToast } from "vue-toastification";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import mime from 'mime-types';
import OverlayDrawingModel from "@/appModel/Overlay/OverlayDrawingModel";
import UserInfo from "@/appUtils/UserInfo";
import TenantStorage from "@/appUtils/TenantStorage"
import volumeModel from "@/appModel/Volume/VolumeModel";
import Modal from "@/components/Modal";
import DateUtil from "@/appUtils/DateUtil"
import OverlayPointModel from "@/appModel/Overlay/OverlayPointModel";
import OverlayModel from "@/appModel/Overlay/OverlayModel";
import ValidateUtil from "@/appUtils/ValidateUtil";
const path = require("path")

export default {
  components: {
    ApplicationHeader,
    // TableToolMenu,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      
      projectId: null,
      projectName: null,
      datestring: null,
      appTitle: "打ち重ね図面登録",
      iconUrl: null,
      baseAreaList: [],
      removalList: [],
      confirmResultOK: true,
      uploadFiles: [],
      filePath: "",
      tenantStorage: null,
      overlaydrawingId: "",
      isAdd: false,
      modals: {
        editDateDlg: false,
        confirmDlg: false,
      },
      confirmTitle: `削除`,
      confirmMessage: `削除します。よろしいですか？`,
      confirmOK: `削除`,
      overlayData: null,
      returnScreen: '',
      isPassedDay: false,
      usePouring: false,
      formData: {
        overlaydrawingname: "",
      },
      rules: {
        overlaydrawingname: [
          { required: true, validator: this.checkName, trigger: "blur" },
        ],
      },
    };
  },
  // コンピュートプロパティ
  computed: {
    currentProject() {
      return this.$store.state.project
    },
    vuename() {
      return "OverlayDrawing.vue";
    },
  },
  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },
  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "BaseArea.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.plan.projectid})、projectname(${this.$store.state.plan.projectname})、datestring(${this.$store.state.plan.datestring})`
    );
    const _this = this
    this.tenantId = this.getTenantId().then(id => {
      _this.tenantId = id
    })
  },
  async beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
    this.init().then(function(){
      console.log(``)
    })
  },
  mounted() {
    // インスタンスが DOM 要素にマウントされた後
    this.init();
  },
  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },
  updated() {
    // データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },
  unmounted() {
    // Vue インスタンスが破壊された後
  },
  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    appLog.infoLog("BaseArea.vue", this.$store.state.user.userId, `End`);
    next();
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        // store(vuex)から値取得
        this.projectId = this.$store.state.plan.projectid;
        this.datestring = this.$store.state.plan.datestring;
        this.projectName = this.$store.state.plan.projectname;

        this.returnScreen = '＜ ' + DateUtil.dateStringBase(this.$store.state.plan.createdate, 'YYYY/MM/DD') + ' 予定数量';

        const user = await UserInfo.getUserInfo();
        this.tenantStorage = new TenantStorage(user.group);
        this.tenantId = await this.getTenantId();
        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);
        this.overlaydrawingId = this.$store.state.overlay.overlayrawingid;
        let listdata = await OverlayDrawingModel.getOverlayDrawing(this.tenantId, this.projectId, this.overlaydrawingId);
        if (listdata!=null){
          this.overlayData = listdata;
          this.formData.overlaydrawingname=listdata.overlayDrawing.name;
          this.filePath=listdata.overlayDrawing.path;
          let filename = path.basename(this.filePath);
          this.uploadFiles= [{ name: filename}];
          this.uploadFiles.push();
          this.isAdd=true;
        }

        let createDate = this.$store.state.plan.createdate
        let nowDate = DateUtil.getFormatString(Date.now(), 'YYYYMMDD')
        let chkCreateDate = DateUtil.getFormatString(createDate, 'YYYYMMDD')
        if (nowDate > chkCreateDate){
          this.isPassedDay = true
        }

        //図面の使用をチェックする
        this.usePouring = await this.checkPouring();
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e));
      } finally {
        this.hideLoader(loader);
      }
    },
    getProjectId() {
      return this.currentProject.sk.split("#")[1];
    },
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }
      return loginInfo.group;
    },
    /**
     * 予定登録日一覧画面に遷移
     */
    onClickReturn(){
      this.$router.push({
        path: this.getRoutePath(`planEdit`),
      });
    },
    /**
     * フォームのバリデーション(図面名)
    */
    checkName(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        callback();
      }
    },

    addFile(file) {
      // 前回のファイルをクリア
      this.removeFile();

      // サイズオーバーか
      const sizeMb = file.size / 1024 / 1024;
      const isOverSize = sizeMb > 100;

      if (isOverSize) {
        this.runToast("ファイルサイズが100MBを超えています。", "error");
        this.$refs.upload.clearFiles();
        this.uploadFiles = [];
        return
      }

      // ファイル拡張子チェック
      let contentType = mime.lookup(file.name);

      if (contentType != "application/pdf" && contentType != "image/jpeg" && contentType != "image/png") {
        this.runToast("対応していないファイル形式です", "error");
        this.$refs.upload.clearFiles();
        this.uploadFiles = [];
        return;
      }

      this.uploadFiles.push(file);
      console.log("uploadFiles "+this.uploadFiles);
      this.$nextTick(() => {
      // You can perform any additional logic here after DOM is updated
        console.log('Upload successful, component state updated');
      });

    },
    removeFile() {
    // 前回のファイルをクリア
      this.$refs.upload.clearFiles();
      this.uploadFiles = [];
    },
    onClickRemoveFile(){
      // this.$refs.upload.clearFiles()
      this.uploadFiles = [];
      this.$nextTick(function(){
        this.$refs.upload.clearFiles();
      }.bind(this));
    },
    /**
     * 図面をアップロード
     */
    async uploadFile() {
      // ファイル指定がない
      if (this.uploadFiles.length == 0) {
        return false;
      }
      // ファイル指定はあるが実体が無い
      const file = this.uploadFiles[0];
      if (!file.raw) {
        return false;
      }
      try {
        if (!this.tenantId || !this.projectId) {
          throw `パラメータ不足`;
        }
        // S3キー名
        const fileName = file.name;
        const s3Key = this.tenantStorage.createOverlaydrawingKey(this.tenantId, this.projectId, this.overlaydrawingId, fileName);

        const fileBody = file.raw;

        let contentType = mime.lookup(file.name);
        const option = {"ContentType": contentType};

        try {
          // S3バケットにファイルを保存
          await this.tenantStorage.put(s3Key, fileBody, option);
        } catch (e) {
          console.log(`S3アップロード失敗 ${e.stack}`);
          throw e;
        }
        // 更新データにファイル名とキーをセット
        this.filePath = s3Key;

        return true;
      } catch (e) {
        alert(e.message);
        throw e;
      }
    },
    async deleteFile(s3key) {
      try {
        // もしなければ終了
        if (!s3key) {
          return;
        }

        await this.tenantStorage.delete(s3key);

      } catch (e) {
        console.log(`削除失敗 ${JSON.stringify(e)}`);
      }
    },
    /**
     * 保存ボタンクリックイベント
     */
    async onClickSave() {
      let isUploaded = false;
      this.$refs.form.validate(
        async function(result) {
          let loader = this.showLoader();
          try {
            // フォーム入力基本チェック
            if (!result) {
              console.log(`error`);
              this.showBottomToast("入力内容にエラーがあります。", "error");
              return;
            }
           
            if (this.uploadFiles.length == 0) {
              this.showBottomToast(
                `図面ファイルを選択してください`,
                "warning"
              );
              return;
            }
            this.overlayData = await OverlayDrawingModel.getNewData(this.projectId);
            this.overlaydrawingId = this.overlayData.overlayDrawing.id;
            // 図面をアップロード
            isUploaded = await this.uploadFile();

            this.overlayData.overlayDrawing.name=this.formData.overlaydrawingname;
            this.overlayData.overlayDrawing.path=this.filePath;
            console.log("######overlayData"+this.overlayData);

            // 図面データ保存
            await OverlayDrawingModel.addOverlayDrawing(this.overlayData);

            //予定数量登録に打ち重ね図面IDを更新するAPIを呼び出し
            let VolumeEditData = this.$store.state.plan.plandata;
            VolumeEditData.volume.overlayDrawingId=this.overlaydrawingId;
            // 拠点名カラム削除
            delete VolumeEditData.volume["endAreaName"];
            delete VolumeEditData.volume["rockTypeName"];
            await volumeModel.updateVolume(VolumeEditData);

            // 完了メッセージ
            this.showBottomToast(`打ち重ね図面登録処理が完了しました。`, 'info');
            // ローディング終了
            this.hideLoader(loader);

            this.isAdd=true;
          } catch (e) {
            // アップロード済みファイルがあれば削除
            if (isUploaded) {
              await this.deleteFile(this.filePath);
            }
            this.showBottomToast(`打ち重ね図面保存時にエラーが発生しました。`, 'error');
            this.errorLog("onClickSave", this.parseErrorObject(e));
            this.isAdd=false;
          } finally {
            this.hideLoader(loader);
          }  
        }.bind(this));
    },
    onClickDelete() {
      // 削除確認
      this.confirmTitle = `削除`;
      this.confirmOK = `削除`;
      this.modals.confirmDlg = true;
    },
    /**
     * 削除処理確認モーダル キャンセルボタンクリックイベント
    */
    onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },
    /**
     * 削除処理確認モーダル 実行ボタンクリックイベント
    */
    async onClickConfirmOK() {
      let loader = this.showLoader();
      try {
        await OverlayDrawingModel.deleteOverlayDrawing({ pk: this.overlayData.pk, sk: this.overlayData.sk });
        await this.deleteFile(this.filePath);
        //予定数量登録に打ち重ね図面IDを更新するAPIを呼び出し
        let VolumeEditData = this.$store.state.plan.plandata;
        VolumeEditData.volume.overlayDrawingId="";
        // 拠点名カラム削除
        delete VolumeEditData.volume["endAreaName"];
        delete VolumeEditData.volume["rockTypeName"];
        await volumeModel.updateVolume(VolumeEditData);
        
        let overlayPointList = await OverlayPointModel.getOverlayPointList(this.tenantId, this.projectId, this.overlayData.overlayDrawing.id);
        for await (let data of overlayPointList) {
          await OverlayPointModel.deleteOverlayPoint({ pk: data.pk, sk: data.sk });
        }
        this.modals.confirmDlg = false;
        this.formData.overlaydrawingname="";
        this.uploadFiles=[];
        this.isAdd=false;
      } catch (e) {
        console.log(e);
        this.showBottomToast(
          `図面削除時にエラーが発生しました。 ${JSON.stringify(
            e
          )}`,
          "error"
        );
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * 図面の使用をチェックする
     */
    async checkPouring(){
      let usePouring = false;
      let overlayPouringList = await OverlayModel.getOverlayListByDrawing(this.tenantId, this.projectId, this.overlayData.overlayDrawing.id);
      if (overlayPouringList.length >0){
        usePouring=true;
      }
      return usePouring;
    },
    /**
     *クリアボタンクリックイベント
     */
    onClickClear() {
      this.formData.overlaydrawingname="";
      this.uploadFiles = [];
      this.$nextTick(function(){
        this.$refs.upload.clearFiles();
      }.bind(this));
    },
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue(){
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },
    /**
     * 打設範囲登録ボタンクリック時の処理
     */
    async onClickOverlayPointRegister() {
      // store(vuex)に値をセット
      let overlay = this.$store.state.overlay;
      overlay=this.overlayData;
      overlay.projectid = this.projectId;
      overlay.projectname = this.projectName;
      overlay.datestring = this.datestring;
      overlay.overlayrawingid = this.overlaydrawingId;
      await this.$store.commit("setOverlay", overlay);
      // 打設範囲登録画面起動
      this.$router.push({
        path: "/overlayPoint",
      });
    },
    /**
     * 経過時間設定ボタンクリック時の処理
     */
    async onClickOverlayTimeSetting() {
      // store(vuex)に値をセット
      let overlay = this.$store.state.overlay;
      overlay=this.overlayData;
      overlay.projectid = this.projectId;
      overlay.projectname = this.projectName;
      overlay.datestring = this.datestring;
      overlay.overlayrawingid = this.overlaydrawingId;
      await this.$store.commit("setOverlay", overlay);
      // 経過時間設定画面起動
      this.$router.push({
        path: "/overlayTimeSetting",
      });
    },
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type);
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e);
      }
    },
  },
};
</script>

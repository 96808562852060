<template>
  <application-header
    :title="appTitle"
    :isPlandate="true"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isDriveVue="true"
    @driveVue="onClickDriveVue"
    ></application-header>

    <!----------------------------------------------------
    打ち重ね図面登録、打設範囲登録、経過時間設定ボタン
  ------------------------------------------------------>
  <!--
  <div class="d-flex button-area">
    <div class="col-sm-2">
        <base-button type="primary" :disabled="true" block @click="onClickOverlayDrawing">図面登録</base-button>
      </div>
      <div class="col-sm-2">
        <base-button type="primary" :disabled="true" block @click="onClickOverlayPointRegister">打設範囲登録</base-button>
      </div>
      <div class="col-sm-2">
        <base-button type="primary" block @click="onClickOverlayTimeSetting">経過時間設定</base-button>
      </div>
  </div>
  -->
  <div class="d-flex">
    <div class="row ml-0 mt-2">
      <div class="col-sm-12">
        <a href="#!" @click.prevent="onClickReturn()">{{returnScreen}}</a>
      </div>
    </div>
  </div>
  <el-form :model="formData" :rules="rules" ref="form">
    <div class="overlaytimesetting_area">
      <div style="margin-left:15px;margin-top:-35px" >
        <div class="row">
          <div class="col-lg-12">
            <h3>経過時間設定作成</h3>
          </div>
        </div>
        <!-- 動作モード -->
        <div class="row timeset_area">
          <div class="col-lg-8 mx-1 p-2 rounded">
            <h4>操作モード</h4>
            <el-radio-group
              class="mb-2"
              v-model="operationMode"
              size="large"
              :fill="'#154CA3'"
              @change="changeMode"
            >
              <el-radio-button label="1">作成</el-radio-button>
              <el-radio-button label="0">削除</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <!-- 参照元の設定を選択 -->
        <div class="row timeset_area">
          <div class="col-lg-8 mx-1 p-2 rounded">
            <h4>参照元の設定を選択</h4>
            <el-form-item prop="name">
              <base-input name="timeSetId">
                <el-select
                  placeholder="選択してください"
                  v-model="changeTimeSettingId"
                  @change="onChangeTimeSet"
                  readonly="true"
                  :clearable="isSelectClearable"
                >
                  <el-option v-for="item in timeSettings"
                            class="select-primary"
                            :value="item.id"
                            :label="item.name"
                            :key="item.id"
                            >
                  </el-option>
                </el-select>
              </base-input>
            </el-form-item>
          </div>
        </div>
        <!-- 経過時間設定名 -->
        <div class="row timeset_area" v-if="createTable">
          <div class="col-lg-8">
            <h4>新規設定名<require-tag /></h4>
            <el-form-item prop="overlayTimeSettingName">
              <el-input
                placeholder="新規設定名"
                v-model="formData.overlayTimeSettingName"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <!-- 経過時間設定テーブル作成用 -->
        <div class="row timeset_area" v-if="createTable">
          <div class="col-lg-8">
            <div class="time-list">
              <el-table
                :cell-style="{ textAlign: 'center' }"
                :header-cell-style="{ textAlign: 'center' }"
                class="table-responsive align-items-center table-flush"
                header-row-class-name="app-th-row"
                :select-on-indeterminate="true"
                :data="editTimeSets"
              >
                <el-table-column label="経過時間（始）" min-width="80px" width="170px" >
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="分単位です" placement="right">
                      <span>
                        経過時間（始）
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                  <template v-slot="scope">
                    <div style="text-align: left;">
                      <el-input v-model="scope.row.elapsedFrom" disabled class="el-input-timeset" style="padding: 8px"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="設定番号" min-width="80px" width="150px" style="padding: 8px" >
                  <template v-slot="scope">
                    <div style="text-align: left;">
                      ＜ 設定{{ scope.row.itemNumber }} ≦
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="経過時間（終）" min-width="80px" width="170px" style="padding: 8px">
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="分単位です" placement="right">
                      <span>
                        経過時間（終）
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                  <template v-slot="scope">
                    <div style="text-align: left;">
                      <el-input
                      v-model="scope.row.elapsedTo"
                      class="el-input-timeset"
                      v-if="scope.row.elapsedTo !== null"
                      @change="onNumberEnter(scope.row.itemNumber, $event)"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="設定色" min-width="80px" width="150px" style="padding: 8px">
                  <template v-slot="scope">
                    <!-- 線色指定 -->
                    <div style="text-align: left;">
                      <el-dropdown class="dropdown" trigger="click">
                        <div class="color-box" :style="{ backgroundColor: scope.row.colorId}"></div>
                        <template #dropdown>
                          <div class="d-flex pl-2 pr-2" style="">
                            <el-dropdown-menu
                              v-for="(color, index) in selectColors"
                              :key="index"
                              style="display: inline-block;"
                            >
                              <li class="ml-1 mr-1" style="display: inline-block;">
                                <span
                                  class="btn btn-sm round"
                                  style="border-radius: 50%; width:16px; height:16px;"
                                  v-bind:style="{ backgroundColor: color.hex }"
                                  @click.prevent="selectedColor(color, scope.row.itemNumber)">
                                </span>
                              </li>
                            </el-dropdown-menu>
                          </div>
                        </template>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="push通知" min-width="80px" width="100px" style="padding: 8px">
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="経過時間（始）を経過したタイミングでPUSH通知を送信します" placement="right">
                      <span>
                        push通知
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                  <template v-slot="scope">
                    <div style="text-align: center;">
                      <el-checkbox
                      v-model="scope.row.elapsedAlert"
                      :true-label="1"
                      :false-label="0"
                      @click.prevent="onCheckClick(scope.row.itemNumber)"/>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- 経過時間設定テーブル削除用 -->
        <div class="row timeset_area" v-if="deleteTable">
          <div class="col-lg-8">
            <div class="time-list">
              <el-table
                :cell-style="{ textAlign: 'center' }"
                :header-cell-style="{ textAlign: 'center' }"
                class="table-responsive align-items-center table-flush"
                header-row-class-name="app-th-row"
                :select-on-indeterminate="true"
                :data="editTimeSets"
              >
              <el-table-column label="経過時間（始）" min-width="80px" width="170px" prop="elapsedFrom">
                <template #header>
                    <el-tooltip class="box-item" effect="dark" content="分単位です" placement="right">
                      <span>
                        経過時間（始）
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
              </el-table-column>
                <el-table-column label="設定番号" min-width="50px" width="150px">
                  <template #default="{row}">
                    <div style="text-align: left;">
                      ＜ 設定{{ row.itemNumber }} ≦
                    </div>
                  </template></el-table-column>
                <el-table-column label="経過時間（終）" min-width="80px" width="170px" prop="elapsedTo">
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="分単位です" placement="right">
                      <span>
                        経過時間（終）
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="設定色" min-width="80px" width="150px" prop="colorId">
                  <template #default="{row}">
                    <div class="color-box" :style="{ backgroundColor: row.colorId}"></div>
                  </template>
                </el-table-column>
                <el-table-column label="push通知" min-width="80px" width="100px">
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="経過時間（始）を経過したタイミングでPUSH通知を送信します" placement="right">
                      <span>
                        push通知
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                  <template #default="{row}">
                    <div style="text-align: center;">
                      <el-checkbox v-model="row.elapsedAlert" :true-label="1" :false-label="0" disabled />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- ＋・－ボタン-->
        <div class="row timeset_area" v-if="createTable">
          <div class="col-lg-8">
            <div class="my-3">
              <div class="row mt-2 mb-2 ml-2 mr-2">
                <div class="col-sm-1.5">
                  <base-button
                    block
                    type="primary"
                    @click="onClickAddRow"
                    >＋</base-button
                  >
                </div>
                <div class="button-plusminas"></div>
                <div class="col-sm-1.5">
                  <base-button
                    block
                    type="primary"
                    @click="onClickRemoveRow"
                    >－</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- リセット・保存ボタン-->
        <div class="row timeset_area" v-if="createTable">
          <div class="col-lg-8">
            <div class="my-3">
              <div class="row mt-2 mb-2 ml-2 mr-2">
                <div class="col-sm-1"></div>
                <div class="col-sm-3">
                  <base-button
                    block
                    type="secondary"
                    @click="onClickClear"
                    >キャンセル</base-button
                  >
                </div>
                <div class="col-sm-3">
                  <base-button
                    block
                    type="primary"
                    :disabled="!isValidDate"
                    @click="onClickSave"
                    >保存</base-button
                  >
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 削除ボタン-->
        <div class="row timeset_area" v-if="deleteTable">
          <div class="col-lg-8">
            <div class="my-3">
              <div class="row mt-2 mb-2 ml-2 mr-2">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">
                  <base-button
                    block
                    type="primary"
                    @click="onClickDelete"
                    >設定を削除</base-button
                  >
                </div>
                <div class="col-sm-3"></div>
              </div>
            </div>
          </div>
          <!-- テナント削除確認モーダル -->
          <modal v-model:show="modals.confirmDlg">
            <template v-slot:header>
                <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
            </template>
            <div class="mb-5">
              <p class="message message-wrap">{{confirmMessage}}</p>
            </div>
            <div class="row mt-2 mb-2 ml-2 mr-2" v-if="!isUsed">
              <div class="col-sm-5">
                <base-button type="secondary" block @click="onClickConfirmCancel">{{confirmCancel}}</base-button>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-5">
                <base-button type="danger" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
              </div>
            </div>
            <div class="row mt-2 mb-2 ml-2 mr-2" v-if="isUsed">
              <div class="col-sm-3"></div>
              <div class="col-sm-5">
                <base-button type="secondary" block @click="onClickConfirmCancel">{{confirmCancel}}</base-button>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </el-form>
</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */

.el-input-timeset {
  width: 70% !important;
}

.time-list {
  width: 100%;
  height: 100%;
}

.button-plusminas {
  margin: 5px;
}
.button-margin {
  margin: 10px 0 0 52px !important;
}
.td-center {
  text-align: center;
}

.button-area {
  margin-top: 5px;
  width: 100%;
  height: 10px;
}

.timeset_area {
  overflow-x: hidden;
  border-radius: 0;
  height: calc(100% - 180px);
  margin-left: 30px;
}

.overlaytimesetting_area {
  /* position:absolute; */
  margin-top: 50px;
  width: 100%;
  height: 100%;
  background-color: white;
}

.color-box {
  width: 70px;
  height: 30px;
  border: 1px solid #ccc;
}

.message-wrap {
  white-space: pre-wrap;
}
</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import { useToast } from "vue-toastification";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import OverlayDrawingModel from "@/appModel/Overlay/OverlayDrawingModel";
import OverlayTimeSettingModel from "@/appModel/Overlay/OverlayTimeSettingModel";
import UserInfo from "@/appUtils/UserInfo";
import Modal from "@/components/Modal";
import ValidateUtil from "@/appUtils/ValidateUtil";

export default {
  components: {
    ApplicationHeader,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      appTitle: "打ち重ね経過時間設定作成",
      tenantId: "",
      planid: "",
      projectId: "",
      projectName: "",
      datestring: "",
      overlayData: null,
      overlayDrawingId: "",
      operationMode: "1",
      form: {},
      selectColors: [
        {rgb: "192,0,0", hex: "#C00000"},
        {rgb: "255,0,0", hex: "#FF0000"},
        {rgb: "255,192,0", hex: "#FFC000"},
        {rgb: "255,255,0", hex: "#FFFF00"},
        {rgb: "148,208,80", hex: "#92D050"},
        {rgb: "0,176,80", hex: "#00B050"},
        {rgb: "0,176,240", hex: "#00B0F0"},
        {rgb: "0,112,192", hex: "#0070C0"},
        {rgb: "112,48,160", hex: "#7030A0"},
        {rgb: "0,0,0", hex: "#000000"}
      ],
      overlayTimeSets: "",
      isSelectClearable: true,
      overlayTimeSettingName: "",
      overlayDrawingData: "",
      timeSettings: [],
      overlayTimeSettingList: [],
      changeTimeSettingId: null,
      createTable: false,
      deleteTable: false,
      timeSets: [],
      editTimeSets: [],
      isBaseTemplate: false,
      selectedIndex: "",
      modals: {
        editDateDlg: false,
        confirmDlg: false,
      },
      confirmTitle: `削除`,
      confirmMessage: "",
      confirmOK: `削除`,
      confirmCancel: `キャンセル`,
      isUsed: false,
      isValidDate: false,
      formData: {
        overlayTimeSettingName: "",
      },
      rules: {
        overlayTimeSettingName: [
          { required: true, validator: this.checkName, trigger: "blur" },
        ],
      },
      returnScreen: '＜ 経過時間設定',
    };
  },
  // コンピュートプロパティ
  computed: {
    currentProject() {
      return this.$store.state.project;
    },
    vuename() {
      return "OverlayTimeSets.vue";
    }
  },
  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },
  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "BaseArea.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.drivespot.projectid})、projectname(${this.$store.state.drivespot.projectname})、datestring(${this.$store.state.drivespot.datestring})`
    );

    this.init();
    const _this = this;
    this.tenantId = this.getTenantId().then(id => {
      _this.tenantId = id;
    });
  },
  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
    this.init().then(function(){
      console.log(``);
    })
  },
  mounted() {
    // インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },
  updated() {
    // データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },
  unmounted() {
    // Vue インスタンスが破壊された後
  },
  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    appLog.infoLog("BaseArea.vue", this.$store.state.user.userId, `End`);
    next();
  },
  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        await this.initialData(); // 初期データ

        const tenantId = await this.getTenantId();
        this.tenantId = tenantId;
        this.operationMode = "1";
        this.selectedIndex = "";

        await this.fetchOverlayDrawingData(tenantId); // 打ち重ね図面データを取得
        if (!this.overlayDrawingData || this.overlayDrawingData.length === 0) {
          this.showOverlayDrawingModal(); // モーダルを表示
          return;
        }

        await this.fetchOverlayTimeSettingList(); // 時間設定リストをフェッチ
        await this.fetchTimeSettings(); // タイム設定をフェッチ

        const overlayTimeSettingId = this.overlayDrawingData.overlayDrawing.overlayTimeSettingId;
        if (this.isValidOverlayTimeSettingId(overlayTimeSettingId)) {
          await this.initTimeSet(overlayTimeSettingId);
        }
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e));
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * プロジェクトID修得
     */
    getProjectId() {
      return this.currentProject.sk.split("#")[1];
    },
    /**
     * テナントID修得
     */
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }
      return loginInfo.group;
    },
    /**
     * 打ち重ね経過時間設定画面に遷移
     */
    onClickReturn(){
      this.$router.push({
        path: "/overlayTimeSetting",
      });
    },
    
    /**
     * 初期データをフェッチ
     */
    async initialData() {
      // store(vuex)から値取得
      this.planid = this.$store.state.plan.planid;
      this.projectId = this.$store.state.overlay.projectid;
      this.datestring = this.$store.state.overlay.datestring;
      this.projectName = this.$store.state.overlay.projectname;
      this.overlayDrawingId = this.$store.state.overlay.overlayrawingid;

      // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
      this.userAuthority = this.$store.state.user.userType;
      if (this.userAuthority == 0) {
        this.editableFlag = false;
      }

      // タイトルをセット
      let navInfo = this.$store.state.navInfo;
      navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
      navInfo.description = "";
      this.$store.commit("setNavInfo", navInfo);
    },
    /**
     * 打ち重ね図面データをフェッチ
     */
    async fetchOverlayDrawingData(tenantId) {
      const overlayDrawingData = await this.getOverlayDrawingData(tenantId);
      if (overlayDrawingData.length === 0) {
        this.showOverlayDrawingModal(); // モーダル表示
      } else {
        this.overlayDrawingData = overlayDrawingData;
      }
    },
    /**
     * 経過時間設定をフェッチ
     */
    async fetchTimeSettings() {
      const timeSettings = await this.getTimeSettings();
      this.timeSettings = timeSettings;
    },
    /**
     * 経過時間設定リストをフェッチ
     */
    async fetchOverlayTimeSettingList() {
      const overlayTimeSettingList = await this.getOverlayTimeSettingList();
      this.overlayTimeSettingList = overlayTimeSettingList;
    },
    /**
     * 経過時間設定ID確認
     * @param {int} overlayTimeSettingId
     */
    isValidOverlayTimeSettingId(overlayTimeSettingId) {
      return overlayTimeSettingId && overlayTimeSettingId.trim() !== "" && overlayTimeSettingId.length > 0;
    },
    /**
     * モーダルを表示
     */
    showOverlayDrawingModal() {
      this.modals.confirmDlg = true;
    },
    /**
     * 打ち重ね図面情報取得
     * @param {int} tenantId
     */
    async getOverlayDrawingData(tenantId) {
      return await OverlayDrawingModel.getOverlayDrawing(
        tenantId,
        this.projectId,
        this.overlayDrawingId
      );
    },
    /**
     * 経過時間設定選択ボックス表示用
     */
    async getTimeSettings() {
      let overlayTimeSettingList = await this.getOverlayTimeSettingList();
      let returnList = [];
      for (let i = 0; i < overlayTimeSettingList.length; i++) {
        let el = {};
        el["sk"] = overlayTimeSettingList[i].sk;
        el["id"] = overlayTimeSettingList[i].overlayTimeSetting.id;
        el["name"] = overlayTimeSettingList[i].overlayTimeSetting.name;
        returnList.push(el);
      }
      return returnList;
    },
    /**
     * テナントに属してる経過時間設定テンプレートリスト取得
     */
    async getOverlayTimeSettingList() {
      const tenantId = await this.getTenantId();
      this.overlayTimeSettingList = "";
      this.overlayTimeSettingList = await OverlayTimeSettingModel.getOverlayTimeSettingList(tenantId);
      // 取得したテンプレートリストがデフォルト設定かを確認
      if (this.overlayTimeSettingList.length == 1 && this.overlayTimeSettingList[0].overlayTimeSetting.isBaseTemplate) {
        delete this.overlayTimeSettingList[0].overlayTimeSetting["isBaseTemplate"];
        this.isBaseTemplate = true; // デフォルト設定
      }
      return this.overlayTimeSettingList;
    },
    /**
     * 経過時間設定Display用
     */
    async getTimeSets(index) {
      let timeList = this.overlayTimeSettingList;
      this.timeSets = timeList[index].overlayTimeSetting.overlayTimeSets;
      this.editTimeSets = this.timeSets;
    },
    /**
     * 初期経過時間表示
     */
    async initTimeSet(id) {
      const data = await OverlayTimeSettingModel.getOverlayTimeSetting(this.tenantId, id);
      this.timeSets = data.overlayTimeSetting.overlayTimeSets;
    },
    /**
     * フォームのバリデーション(新規設定名)
    */
    checkName(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        this.isValidDate = true ;
        callback();
      } else {
        this.isValidDate = false;
      }
    },
    /**
     * 選択ボックスのonChangeイベント
     * 経過時間テンプレート選択
     */
    async onChangeTimeSet() {
      const selectedId = this.changeTimeSettingId;
      const timeList = this.overlayTimeSettingList;
      for (let i = 0; i < timeList.length; i++) {
        if (selectedId == timeList[i].overlayTimeSetting.id) {
          await this.getTimeSets(i);
          if (this.operationMode === "1") {
            this.createTable = true;
            this.deleteTable = false;
          } else {
            this.createTable = false;
            this.deleteTable = true;
          }
          this.selectedIndex = i;
          break;
        }
      }
    },
    /**
     * 経過時間設定をリセット
     */
    async resetTimeSets() {
      const overlayTimeSettingList = await this.getOverlayTimeSettingList();
      await this.fetchTimeSettings();
      this.createTable = false;
      this.deleteTable = false;
      this.changeTimeSettingId = null;
      this.timeSets = overlayTimeSettingList[this.selectedIndex].overlayTimeSetting.overlayTimeSets
      this.editTimeSets = this.timeSets;
    },
    /**
     * 操作モード変更
     * @param {string} mode
     */
    async changeMode(mode){
      // `mode` は選択されたラジオボタンの値
      if (mode === "1") {
        this.operationMode = "1";
      } else if (mode === "0") {
        this.operationMode = "0";
      }
      await this.resetTimeSets();
    },
    
    /**
     * 表の経過時間（終）値の変更に伴い後行の経過時間を自動に変更
     * @param {int} itemNumber
     * @param {string} value
     */
    onNumberEnter(itemNumber, value) {
      let target = null;
      let targetId = null;
      for (let i = 0; i < this.editTimeSets.length; i++) {
        if (this.editTimeSets[i].itemNumber === itemNumber) {
          target = this.editTimeSets[i];
          targetId = i;
          break; // 最初に見つかったアイテムでループを終了
        }
      }

      if (isNaN(value) || value < 0 || value > 999) {
        const alertMsg = `${this.editTimeSets[targetId].elapsedFrom + 1} 以上 999 以下の数字を入力してください。`;
        this.errorLog("onNumberEnter", alertMsg);
        this.showBottomToast(alertMsg, 'warning');
        this.editTimeSets[targetId].elapsedTo = "";
        return;
      }

      if (target) {
        if (value=="")
          target.elapsedTo = ""
        else 
          target.elapsedTo = this.changeStringToInt(value);
      } else {
        console.error(`Index ${itemNumber}がありません。`);
        return;
      }

      for (let i = targetId; i < this.editTimeSets.length - 1; i++) {
        let current = this.editTimeSets[i];
        let next = this.editTimeSets[i + 1];

        if (current.elapsedTo !== undefined && next.elapsedFrom !== undefined) {
          if (current.elapsedTo=="")
            next.elapsedFrom = "";
          else 
            next.elapsedFrom = this.changeStringToInt(current.elapsedTo);
        }

        if (value!=""){
          if (next.elapsedFrom < next.elapsedTo) {
            break;
          }
        }

        /*
        if (next.elapsedTo !== undefined && next.elapsedFrom !== undefined) {
          if (i !== this.editTimeSets.length - 2) {
            if (next.elapsedFrom=="")
              next.elapsedTo = "";
            else 
              next.elapsedTo = this.changeStringToInt(next.elapsedFrom) + 10;
          }
        }
        */
      }
      this.isValidDate = true;
      //this.onValidate();
    },
    /**
     * 文字列数字を数字に変換
     * @param {string} n
     */
    changeStringToInt(n) {
      return parseInt(n, 10);
    },
    /**
     * 設定色のカラーピッカーでの色を適用処理
     * @param {object} color
     * @param {int} idx
     */
    selectedColor(color, idx) {
      this.editTimeSets[idx - 1].colorId = color.hex;
    },
    /**
     * push通知のToggle
     * @param {string} idx
     */
    onCheckClick(idx){
      let checkAlert = this.editTimeSets[idx - 1].elapsedAlert;
      this.editTimeSets[idx - 1].elapsedAlert = checkAlert === 0 ? 1 : 0;
    },
    /**
     * 経過時間行を追加
     */
    onClickAddRow() {
      let newRowData = { "itemNumber": 0, "elapsedFrom": 0, "elapsedTo": null, "colorId": "#FF0000", "elapsedAlert": 0 };
      let lastRow = this.editTimeSets[this.editTimeSets.length - 1];

      // pop() 前に存在する確認
      if (this.editTimeSets.length === 0) {
        console.error("editTimeSets is empty, cannot pop.");
        return;
      }

      lastRow.elapsedTo = "";
      newRowData.itemNumber = lastRow.itemNumber + 1;
      newRowData.elapsedFrom = lastRow.elapsedTo;
      this.editTimeSets.push(newRowData);

      //this.isValidDate = false;
    },
    /**
     * 経過時間行を削除
     */
    onClickRemoveRow() {
      const alertMsg = "時間設定の行はこれ以上削除できません。";

      if (this.editTimeSets.length < 3) {
        this.errorLog("onClickRemoveRow", alertMsg);
        this.showBottomToast(alertMsg, 'error');
        return;
      }
      this.editTimeSets.pop();
      this.editTimeSets[this.editTimeSets.length-1].elapsedTo = null;
    },
    /**
     * バリデーション
     */
    onValidate() {
      this.isValidDate = true;
 
      for (let i = 0; i < this.editTimeSets.length; i++) {
        let timeSet = this.editTimeSets[i]
        if (timeSet.elapsedFrom >= timeSet.elapsedTo && timeSet.itemNumber !== this.editTimeSets.length) {
          this.showBottomToast(`設定${timeSet.itemNumber}の経過時間（始）「${timeSet.elapsedFrom}」より大きい数を入力してください。`, 'error');
          this.isValidDate = false;
          break;
        }
      }
      return this.isValidDate;
    },
    /**
     * 保存ボタンクリックイベント
     */
    async onClickSave() {
      let loader = this.showLoader();
      this.$refs.form.validate(
        async function(result) {
          // フォーム入力基本チェック
          try {
            if (!result) {
              console.log(`error`);
              this.showBottomToast("入力内容にエラーがあります。", "error");
              return;
            }
          
            await this.onValidate()
            if (this.isValidDate ==false) {
              return;
            }

          
            // // 新規設定名を入力確認
            // if (this.formData.overlayTimeSettingName === null || this.formData.overlayTimeSettingName === "") {
            //   this.showBottomToast(`新規設定名を入力してください`, "warning");
            //   return;
            // }
            let timeSettingNewData = await OverlayTimeSettingModel.getNewData(this.tenantId);
            timeSettingNewData.overlayTimeSetting.name = this.formData.overlayTimeSettingName;
            timeSettingNewData.overlayTimeSetting.overlayTimeSets = this.editTimeSets;
            await OverlayTimeSettingModel.addOverlayTimeSetting(timeSettingNewData)
            // 完了メッセージ
            this.showBottomToast(`経過時間設定作成の登録処理が完了しました。`, 'info');
            this.resetTimeSets();
            // 親ビューに通知
            this.$emit("onSave");
          } catch (e) {
            this.errorLog("onClickSave", this.parseErrorObject(e))
            this.showBottomToast(`経過時間設定作成の保存する際、エラーが発生しました。`, 'error');
          } finally {
            this.isBaseTemplate = false;
            this.hideLoader(loader);
          }
        }.bind(this));
    },

    /**
     * 編集内容をキャンセル
     */
    async onClickClear() {
      this.formData.overlayTimeSettingName = "";
      await this.resetTimeSets();
    },
    /**
     * 削除確認用のモーダルを表示
     */
    async onClickDelete() {
      // 登録されてるいるかを確認、あったら削除できない。
      let overlayTimeSettingID = this.overlayTimeSettingList[this.selectedIndex].overlayTimeSetting.id;
      let isExist = await OverlayDrawingModel.isExistOverlayTimeSettingId(this.tenantId, overlayTimeSettingID);

      this.confirmTitle = `削除`;
      if (isExist) {
        this.isUsed = true;
        this.confirmMessage = `使用されています。\n削除はできません。`;
      } else {
        if (this.isBaseTemplate) {
          this.isUsed = true;
          this.confirmMessage = `デフォルト設定は削除できません。`;
        } else {
          this.isUsed = false;
          this.confirmOK = `削除`;
          this.confirmMessage = `選択した参照元の設定を削除されます。\nよろしいですか？`;
        }
      }
      this.showOverlayDrawingModal();
    },
    /**
     * 削除処理確認モーダル
     * 経過時間設定を実行
     */
    async onClickConfirmOK() {
      let loader = this.showLoader();
      try {
        let targetTimeSets = this.overlayTimeSettingList[this.selectedIndex];
        await OverlayTimeSettingModel.deleteOverlayTimeSetting({"pk": targetTimeSets.pk, "sk": targetTimeSets.sk});

        this.modals.confirmDlg = false;
        this.overlaydrawingname="";
        this.uploadFiles=[];
        this.isAdd=false;
        this.resetTimeSets();
      } catch (e) {
        console.log(e);
        this.showBottomToast(`参照元の設定削除時にエラーが発生しました。 ${JSON.stringify(e)}`, "error");
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * 削除モーダルでのキャンセルボタン処理
     */
    onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue(){
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring

      await this.$store.commit("setTimeline", store);

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },
    /**
     * 図面登録ボタンクリック時の処理
     */
    async onClickOverlayDrawing() {
      // 打ち重ね図面登録画面起動
      this.$router.push({
        path: "/overlayDrawing",
      });
    },
    /**
     * 打設範囲登録ボタンクリック時の処理
     */
    async onClickOverlayPointRegister() {
      // 打設範囲登録画面起動
      this.$router.push({
        path: "/overlayPoint",
      });
    },
    /**
     * 経過時間設定ボタンクリック時の処理
     */
    async onClickOverlayTimeSetting() {
      // 経過時間設定画面起動
      this.$router.push({
        path: "/overlayTimeSetting",
      });
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e);
      }
    },
  },
};
</script>

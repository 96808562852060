const short = require("short-uuid");
const { API, graphqlOperation } = require("aws-amplify");
const {
  listKonoikeConcreteData,
  getKonoikeConcreteData,
} = require("@/graphql/queries");
const baseModel = require("../baseModel")

/**
 * 打ち重ね範囲用のデータ取得モデルクラス
 */
class OverlayPointModel extends baseModel {
  /**
   * 打ち重ね範囲リストを取得する
   * @returns
   */
  static async getOverlayPointList(tenantId, projectId, overlayDrawingId) {
    const result = await API.graphql(
      graphqlOperation(listKonoikeConcreteData, {
        pk: `${tenantId}`,
        sk: { beginsWith: `overlayPoint#${projectId}#${overlayDrawingId}#` }
      })
    );

    if (result) {
      if (result.data) {
        return result.data.listKonoikeConcreteData.items;
      }
    }

    return [];
  }

  /**
   * 打ち重ね範囲レコードを追加する
   * @param {*} data
   */
  static async addOverlayPoint(data) {
    await super.addData(data);
  }

  /**
   * 打ち重ね範囲レコードを更新する
   * @param {*} data
   */
  static async updateOverlayPoint(data) {
    await super.updateData(data);
  }

  /**
   *打ち重ね範囲レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteOverlayPoint(data) {
    await super.deleteData(data);
  }

  /**
   * 打ち重ね範囲レコードを取得する
   * @param {*} data
   */
  static async getOverlayPoint(tenantId, projectId, overlayDrawingId, overlayPointId) {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteData, { pk: `${tenantId}`, sk: `overlayPoint#${projectId}#${overlayDrawingId}#${overlayPointId}` })
    );

    return result.data.getKonoikeConcreteData;
  }

  /**
   * skの文字列を生成
   * overlayPoint#プロジェクトID#打ち重ね図面ID#打ち重ね範囲ID
   * @param {*}
   * @returns
   */
  static createSk(projectId, overlayDrawingId, id) {
    return `overlayPoint#${projectId}#${overlayDrawingId}#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, overlayDrawingId) {
    let newRowData = await super.getNewData();
    const id = short.generate();

    newRowData.sk = this.createSk(projectId, overlayDrawingId, id);
    newRowData.overlayPoint = {
      id: id,
      pointName: "",
      x: 0.0,
      y: 0.0,
      radius: 0.0,
      polygon: "",
      shapeType: ""
    };

    return newRowData;
  }
}

module.exports = OverlayPointModel;

const baseModel = require("../baseModel");

/**
 * 運行スポット通過記録用のデータ取得モデルクラス
 */
class SpotPassLogModel extends baseModel {
  /**
   * テナント内の運行スポット通過記録リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getSpotPassLogList(projectId) {
    return await super.getList({ beginsWith: `spotPassLog#${projectId}` })
  }

  /**
   * プロジェクトID、対象日、到着拠点IDを指定して運行スポット通過記録リスト取得
   * @param {*} projectId 
   * @param {*} targetDate 
   * @param {*} endAreaId 
   * @returns
   */
  static async getSpotPassLogListbyAreaId(projectId, targetDate, endAreaId) {
    return await super.getList({ beginsWith: `spotPassLog#${projectId}#${targetDate}#${endAreaId}` });
  }
}

module.exports = SpotPassLogModel;

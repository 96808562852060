<template>
  <application-header
    ref="appHeader"
    :title="appTitle"
    :isPlandate="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="true"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isOverlay="false"
    :isOverlayPointVue="true"
    :isDriveVue="true"
    :dateStringProp="dateString"
    :overlayDrawingName="overlayDrawingName"
    :isDatePicker="false"
    :isNowTime="isNowTime"
    :isOverlayPointView="true"
    :isAcceptanceList="isAcceptanceList && isNamacon"
    @refresh="onRefreshOverlay"
    @driveVue="onClickDriveVue"
    @chengePouringPosition="onChangePouringPosition"
  >
  </application-header>

  <!-- :refreshIntervalSecond="refreshIntervalSecond" -->
  <!----------------------------------------------------
    打ち重ね図面登録、打ち重ねポイント登録、経過時間設定ボタン
  ------------------------------------------------------>
  <div class="overlaypoint_area">
    <overlay-point-vue-map class="overlaypoint-vue-map"
        ref="map"
        @onAreaClickSelect="onAreaClickSelect"
      >
    </overlay-point-vue-map>
  </div>
  <modal
    v-model:show="modalDlg"
    size="medium"
    modal-classes=""
    modal-content-classes=""
    body-classes="p-0 secondary"
    >
    <template v-slot:header>
      <h6 class="modal-title">図面未登録</h6>
    </template>
    <card
      type="secondary"
      header-classes="pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="col-md-12">
        <h3 class="mt-0 text-danger">図面情報が登録されていません。</h3>
        <p class="ml-2 my-2">
          予定数量登録画面より図面を登録してください。
        </p>
        <ul class="data-list">
                  <li>図面登録</li>
                  <li>打設範囲登録</li>
                  <li>経過時間設定</li>
          </ul>
      </div>
    </card>
    <div class="row mt-2 mb-2 ml-2 mr-2 d-flex justify-content-between">
      <div class="ml-2">
        <el-button type="text text-warning" @click="modalDlg = false"
          >後で登録する</el-button
        >
      </div>
      <div class="mr-2">
        <el-button
          type="primary"
          icon="ni ni-square-pin"
          @click="onClickOverlay"
          >予定数量登録へ →</el-button
        >
      </div>
    </div>
  </modal>
</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */
.button-area {
  margin-top: 5px;
  width: 100%;
  height: 10px;
}

.overlaypoint_area {
  /* position:absolute; */
  margin-top: 50px;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
  border-radius: 0;
}
.overlaypoint-vue-map {
  /* height: calc(100% - 12px); */
  /* height: calc(100% - 36px); */
  height: 80%;
}

::v-deep .el-upload-dragger {
 width: 580px;
}
.overlaypoint-list {
  width: 270px;
  height: 380px;
}

</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import DateUtil from "@/appUtils/DateUtil";
import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
const { Storage } = require("aws-amplify");
import { useToast } from "vue-toastification";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import mime from 'mime-types';
import UserInfo from "@/appUtils/UserInfo";
import TenantStorage from "@/appUtils/TenantStorage";
import OverlayPointVueMap from "@/appViews/Overlay/OverlayPointVueMap";
import xss from 'xss';
import OverlayPointModel from "@/appModel/Overlay/OverlayPointModel";
import OverlayDrawingModel from "../../appModel/Overlay/OverlayDrawingModel.js";
import OverlayModel from "@/appModel/Overlay/OverlayModel";
import Modal from "@/components/Modal";

const path = require("path")
const MAX_POLYGON_VERTEX_COUNT = 100;
// 図形種別: 円
const ID_SHAPE_TYPE_CIRCLE = "0";
// 図形種別: ポリゴン
const ID_SHAPE_TYPE_POLYGON = "1";

export default {
  components: {
    ApplicationHeader,
    OverlayPointVueMap,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      defaultPointName: "ポイント",
      pointName: "",
      pointCount: 1,
      tenantId: null,
      projectId: null,
      projectName: null,
      datestring: null,
      appTitle: "打ち重ね図面表示",
      isNowTime: true,
      // refreshIntervalSecond: 60,
      isAcceptanceList: true,
      iconUrl: null,
      baseAreaList: [],
      removalList: [],
      confirmResultOK: true,
      uploadFiles: [],
      filePath: "",
      tenantStorage: null,
      overlaydrawingId: "",
      imageUrl: null,
      pdfUrl: null,
      filePreview: false,
      spotCount: 0,
      spotRange: 1000,
      overlayTimeSettingId: null,
      overlayPointList: [],
      additionalList: [],
      spotTypeList: [],
      modificationList: [],
      selectedPoint: [],
      overlayDrawingName: "",
      pouringList: [],
      pouringListIndex: 0,
      modalDlg: false,
    };
  },

  // コンピュートプロパティ
  computed: {
    currentProject() {
      return this.$store.state.project;
    },
    vuename() {
      return "OverlayPointVue.vue";
    },
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "BaseArea.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.drivespot.projectid})、projectname(${this.$store.state.drivespot.projectname})、datestring(${this.$store.state.drivespot.datestring})`
    );

    this.init();
    const _this = this;
    this.tenantId = this.getTenantId().then(id => {
      _this.tenantId = id
    })
    this.mlMap = null;
  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
    this.init().then(function(){
      console.log(``);
    });

    // this.refreshIntervalSecond = this.getMapInterval();
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
    this.addResizeObserver();
    this.mlMap = new MLMap('map', MLMap.VIEW_TYPE.NON_GEOGRAPHICAL);
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
  },


  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    appLog.infoLog("BaseArea.vue", this.$store.state.user.userId, `End`);
    next();
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        // store(vuex)から値取得
        this.projectId = this.$store.state.plan.projectid;
        this.datestring = this.$store.state.plan.datestring;
        this.projectName = this.$store.state.plan.projectname;

        const user = await UserInfo.getUserInfo();
        this.tenantStorage = new TenantStorage(user.group);
        this.tenantId = await this.getTenantId();

        // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        this.userAuthority = this.$store.state.user.userType;
        if (this.userAuthority == 0) {
          this.editableFlag = false;
        }

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        this.pouringList = this.$store.state.plan.pouringList;
        this.pouringListIndex = this.$store.state.plan.pouringListIndex;
        this.$refs.appHeader.setSignagePouringList(this.pouringList, this.pouringListIndex);
        this.overlayDrawingId= this.pouringList[this.pouringListIndex].overlayDrawingId;
        if (await this.checkOverlayData()){
          await this.initData();
        }
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e));
      } finally {
        this.hideLoader(loader);
      }
    },
    async checkOverlayData(){
      let isOverlay=true;
      if (this.overlayDrawingId==null || this.overlayDrawingId==""){
        this.modalDlg = true;
        isOverlay = false;
      }
      return isOverlay;
    },
    /**
     *図面ボタンクリック時の処理
    */
    async onClickOverlay() {
      // storeに登録（プロジェクトID、予定ID、予定登録日）
      let plan = this.$store.state.plan
      plan.projectid = this.projectId
      plan.projectname = this.projectName
      plan.datestring = this.datestring
     
      // 予定数量画面へ遷移
      
      this.$router.push({
        path: this.getRoutePath(`planEdit`),
      }); 
    },
    /**
     * 図面データ取得
     */
    async initData() {

      console.log("overlayDrawingId################# "+this.overlayDrawingId)
      
      // ポイント取得
      this.overlayPointList = await OverlayPointModel.getOverlayPointList(this.tenantId, this.projectId, this.overlayDrawingId);
      // 経過時間取得
      const overlayDrawing = await OverlayDrawingModel.getOverlayDrawing(this.tenantId, this.projectId, this.overlayDrawingId);
      this.overlayTimeSettingId = overlayDrawing.overlayDrawing.overlayTimeSettingId;
      this.overlayDrawingName = overlayDrawing.overlayDrawing.name;
      let filepath = overlayDrawing.overlayDrawing.path;
      await this.$refs.map.mapCreate(this.tenantId, this.overlayDrawingId, filepath);

      this.drawOverlayPoint(this.overlayPointList);
    },
    getProjectId() {
      return this.currentProject.sk.split("#")[1];
    },
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }
      return loginInfo.group;
    },
    /**
     * 打ち重ねポイントを描画
    */
    async drawOverlayPoint(list) {
      let parameters = [];
      let applicableList = [];
      let item = null;

      for (let index = 0; index < list.length; index++) {
        item = list[index];

        // 削除済みのスポットは表示しない
        if (this.removalList.length > 0) {
          applicableList = this.removalList.filter(
            (ret) => ret.overlayPoint.id == item.overlayPoint.id
          );
          if (applicableList.length > 0) continue;
        }

        parameters.push({
          id: item.overlayPoint.id,
          radius: item.overlayPoint.radius,
          x: item.overlayPoint.x,
          y: item.overlayPoint.y,
          shapeType: item.overlayPoint.shapeType,
          polygon: item.overlayPoint.polygon,
          pointName: item.overlayPoint.pointName,
        });
      }
      // console.log("parameters: ", parameters)
      if (parameters.length > 0) {
        this.$refs.map.drawoverlay(parameters, this.overlayTimeSettingId);
      }
    },

    /**
     * OverlayPointVueMap.vueの図面クリックイベント(選択)
     *
     */
    onAreaClickSelect(item) {
      // item
      // [
      //     {
      //         "id": "uiUnbPtFxhPKC2xVsheYfo",
      //         "x": 489.194831,
      //         "y": 381.33332800000005,
      //         "label": "ポイント001"
      //     }
      // ]
      console.log("onAreaClickSelect - item: ", [item]);
      this.$refs.map.onStartBlinkingPoi([item]);
      // try {
      //   let overlayPoint = this.overlayPointList.filter((list) => list.overlayPoint.id == item.id);
      //   if (overlayPoint != null && overlayPoint.length > 0) {
      //     this.selectedPoint = overlayPoint[0];
      //     this.pointName = this.selectedPoint.overlayPoint.pointName;
      //     this.pointSize = this.selectedPoint.overlayPoint.radius;
      //   } else {
      //     this.selectedPoint = this.additionalList.filter(
      //       (list) => list.overlayPoint.id == item.id
      //     )[0];
      //   }
      //   // 図形の種別をセット

      //   this.operationShape = this.selectedPoint.overlayPoint.shapeType;
      //   this.$refs.map.operationShape = this.operationShape;
      //   if (this.operationShape == ID_SHAPE_TYPE_POLYGON){
      //     this.$refs.map.startDrawPolygonMode();
      //   } else if (this.operationShape == ID_SHAPE_TYPE_CIRCLE){
      //     this.$refs.map.startDrawCircleMode();
      //   }

      //   // OverlayPointDrawingの選択フラグを変更
      //   this.$refs.map.isSelected = true;
      // } catch (e) {
      //   this.errorLog("onAreaClickSelect", this.parseErrorObject(e))
      // }
    },
    
    /**
     * 保存ボタンクリックイベント
     */
    async onClickSave() {
      let loader = this.showLoader();
      try {

        this.infoLog("onClickSave", `追加${this.additionalList.length} 更新${this.modificationList.length} 削除${this.removalList.length}`)
        // 追加リストのデータをDBに登録
        for await (let data of this.additionalList) {
          console.log(`追加 ${JSON.stringify(data, null, "\t")}`)
          await OverlayPointModel.addOverlayPoint(data);
        }
        this.additionalList = [];

        // 変更リストのデータでDBを更新
        for await (let data of this.modificationList) {
          await OverlayPointModel.updateOverlayPoint(data);
        }
        this.modificationList = [];

        // 除去リストのデータをDBから削除
        for await (let data of this.removalList) {
          await OverlayPointModel.deleteOverlayPoint({ pk: data.pk, sk: data.sk });
        }
        this.removalList = [];

        // 完了メッセージ
        this.showBottomToast(`打ち重ねポイント登録処理が完了しました。`, 'info');
        // ローディング終了
        this.hideLoader(loader)

      } catch (e) {
        this.errorLog("onClickSave", this.parseErrorObject(e))

        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * モーダルのキャンセル
     */
    onClickCancel() {

    },
    /**
     * 図面登録ボタンクリック時の処理
     */
    async onClickOverlayDrawing() {
      // 打ち重ね図面登録画面起動
      this.$router.push({
        path: "/overlayDrawing",
      });
    },
    /**
     * 運行情報画面ボタンクリック
    */
    async onClickDriveVue(){
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },
    
    /**
     * 到着拠点（現場/工区） 打設箇所選択変更時処理です。
     * @param index 選択したリストのインデックス
    */
    async onChangePouringPosition(index) {
      this.pouringListIndex = index;
      this.overlayDrawingId= this.pouringList[this.pouringListIndex].overlayDrawingId;
      if (await this.checkOverlayData()){
        await this.initData();
      }
    },

    /**
     * 位置情報更新要求時の処理です。
     */
    async onRefreshOverlay() {
      console.log(`[START] onRefreshOverlay`);

      try {

        await this.initData();

        // ヘッダーの時刻を更新
        this.$refs.appHeader.updateTime();
      } finally {
        console.log(`[END] onRefreshOverlay`);
      }
    },

    // COMMON METHOD
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e);
      }
    },
  },
};
</script>

<template>
  <div ref="mapContainer" class="map-container" >
    <div id="map" class="map" ></div>
  </div>
</template>

<style scoped>
/** このVueだけのスタイル */
.map-container {
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
  background-color: white;
}

.leaflet-grab {
  cursor: crosshair;
}

.leaflet-dragging .leaflet-grab {
  cursor: grabbing;
}
</style>
<style>
/** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import xss from 'xss';
import TenantStorage from "@/appUtils/TenantStorage"
import UserInfo from '../../appUtils/UserInfo';

const path = require("path")

export default {
  components: {},
  data() {
    return {
      overlayTypeList: [],
      overlayTypeLayerName: [],
      operationMode: 0, // 操作モード - 0: 作成, 1: 編集, 2: 削除
      operationShape: 0, // 図形種別 -0: 円, 1:ポリゴン
      isSelected: false,
      latlngList: [],
      isMovingCurrentLocation: false,
      ext: "",
      fileUrl: "",
    };
  },
  mixins: [ValidSession, UseApps, Logger],
  computed: {
    vuename() {
      return "OverlayPointDrawing.vue";
    },
  },
  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },
  async created() {
    // インスタンスが生成され､且つデータが初期化された後

    // ※画面表示に関連しないメンバ変数を定義

    // MLMap
    // data()内に保持するとLeafletの挙動がおかしくなります。
    this.mlMap = null;

    // 地図コンテナリサイズ監視オブザーバー
    this.resizeObserver = null;

    // ジオフェンス描画スタイル
    this.geofenceStyle = {
      // color: "#B71C1C",
      color: "#E65100",
      weight: 2.5,
      fill: true,
      // fillColor: "#B71C1C",
      fillColor: "#E65100",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    // 打ち重ね範囲描画スタイル
    this.overlayStyle = {
      color: "#585858",
      weight: 2.5,
      fill: true,
      fillColor: "#585858",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
      radius: 5,
    };

    // 打ち重ね範囲間隔ポイントポリゴンスタイル
    this.intervaloverlayStyle = {
      color: "#585858",
      weight: 2.5,
      fill: true,
      fillColor: "#585858",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    this.poiLabelStyle = {
      color: '#FFFFFF',
      weight: 4,
      fillColor: '#263238',
      fontSize: 12,
      textAnchor: 'center',
      textBaseline: 'top',
    };
  },
  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },
  async mounted() {
    // インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`);

    // // 地図生成
    //await this.init();


    // 地図コンテナのリサイズ監視開始
    this.addResizeObserver();
    if (this.mlMap && this.mlMap.isReady) {
      this.mlMap.stopDirectDrawShapeMode();
    }
  },
  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },
  updated() {
    // データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    // Vue インスタンスが破壊される前

    // 地図コンテナのリサイズ監視終了
    this.removeResizeObserver();
  },
  unmounted() {
    // Vue インスタンスが破壊された後

    // マップリソース解放
    if (this.mlMap) {
      this.mlMap.off("click", this.onMapClick);
      this.mlMap.off(MLMap.EVENT_SHAPE_DRAW_FINISH, this.onMapShapeDrawFinish);

      this.mlMap.remove();
    }
  },
  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 地図コンテナのリサイズ監視を開始します。
     */
    addResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$refs.mapContainer);
    },
    /**
     * 地図コンテナのリサイズ監視を終了します。
     */
    removeResizeObserver() {
      this.resizeObserver.unobserve(this.$refs.mapContainer);
    },
    /**
     * 地図コンテナリサイズイベント
     */
    onResize() {
      console.log(`onResize`);

      // Leafletの地図領域を更新
      if (this.mlMap) {
        this.mlMap.invalidateSize();
      }
    },
    async init() {
      //console.log("init")
      // 地図生成
      // this.mapCreate();
    },
    async initdata() {
      try {
        const user = await UserInfo.getUserInfo();
        this.tenantStorage = new TenantStorage(user.group);

        let filePath = this.$store.state.overlay.overlayDrawing.path;
        this.ext = path.extname(filePath).toLowerCase();
        this.filePreview = true;
        this.fileUrl = (await this.tenantStorage.get(filePath)).toBase64();
        this.projectId = this.$store.state.plan.projectid;
      } catch (e) {
        throw e;
      }
    },
    /**
     * 地図の作成
     */
    async mapCreate() {
      await this.initdata()
      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // レイヤ追加
      let bounds = [];
      bounds = await this.addLayers();

      // --------------------
      // コントロール
      // --------------------
      this.mlMap.setLayerControlVisible(false);

      this.mlMap.fitBounds(bounds);

      // --------------------
      // イベント
      // --------------------
      this.initMapEvent();
      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();
    },
    /**
     * レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addLayers() {
      // 初期表示領域
      let bounds = [];

      // 背景レイヤの追加
      bounds = await this.addBaseLayers();

      // 作図レイヤ追加
      this.mlMap.addShapeLayer('作図（お絵描き）');
      //this.addPoiLayers();
      return bounds;
    },
    /**
     * 背景レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addBaseLayers() {
      let bounds = [];
      // 国土地理院タイル地図レイヤを追加
      if (this.ext == ".pdf") {
        bounds =this.addPdfLayer();
      } else {
        bounds =this.addImageLayer();
      }

      //this.addPdfLayer();
      return bounds;
    },
    /**
     * 図面画像レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addImageLayer() {
      // ビュータイプを図面表示用に設定してMLMapを生成

      this.mlMap = null;
      this.mlMap = new MLMap('map', MLMap.VIEW_TYPE.NON_GEOGRAPHICAL);

      if (!this.fileUrl) {
        this.debugLog('背景図Base64未設定');
        return;
      }

      // PDFではない場合は分割する必要なし
      const base64 = this.fileUrl;
      this.debugLog(base64);

      // 図面情報
      const imageInfo = await MLImageUtil.getImageInfo(base64);
      this.debugLog(imageInfo);

      // レイヤ追加
      this.mlMap.addImageLayer("ImageName", base64, imageInfo.width, imageInfo.height);
      this.debugLog('addImageLayer');

      // 画像全体を表示
      let bounds = L.latLngBounds([10, 10], [imageInfo.height-50, imageInfo.width-50]);
      this.debugLog(bounds);

      // スクロール制限
      this.mlMap.setMaxBounds(bounds.pad(0.75));

      return bounds;
    },
    /**
     * 図面PDFレイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addPdfLayer() {

      // PDFを表示する場合はPDFにアクセスする前に必ず設定
      MLPdfUtil.setPdfJsWorkerUrl('./maplet-leaflet/external/pdfjs-2.10.377/pdf.worker.js');

      // ビュータイプを図面表示用に設定してMLMapを生成
      this.mlMap = null;
      this.mlMap = new MLMap('map', MLMap.VIEW_TYPE.NON_GEOGRAPHICAL);

      if (!this.fileUrl) {
        this.debugLog('背景図Base64未設定');
        return;
      }

      // '識別文字','PDF実データ' の構成になっているので、実データのみを取得
      const pdfBase64 = this.fileUrl.split(',')[1];

      // PDF情報
      const doc = await MLPdfUtil.getDocumentInfoByBase64(pdfBase64);
      const page = await MLPdfUtil.getPageInfo(doc, 1);

      // レイヤ追加
      this.mlMap.addPdfLayer("pdfname", {
        pdfData: pdfBase64,
        page: 1,
        cMapUrl: './maplet-leaflet/external/pdfjs-2.10.377/cmaps',
      });

      // PDF全体を表示
      let bounds = L.latLngBounds([10, 10], [page.height-103, page.width-103]);

      // スクロール制限
      this.mlMap.setMaxBounds(bounds.pad(0.75));

      return bounds;
    },
    /**
     * 検査箇所レイヤ群を追加します。
     */
    addPoiLayers(layerName) {

      // アイコン種別
      let poiStyle = {
        iconUrl: "./img/concrete/ic_circle_grey.png",
        iconSize: [38, 38],
        iconOffset: [-19, -36],
        interactive: false,
      };

      // 拠点アイコンレイヤ追加
      this.mlMap.addPoiLayer(xss(layerName), poiStyle, this.poiLabelStyle);

    },
    /**
     * 図形情報からBBX領域を取得します。
     *
     * @returns BBX領域
     */
    getPoiBounds() {
      // 基準領域
      const worldBounds = L.latLngBounds([25, 120], [45, 150]);

      if (this.latlngList.length > 0) {
        const polygon = L.polygon(this.latlngList);
        const bounds = polygon.getBounds();
        return bounds;
      } else {
        return worldBounds;
      }
    },
    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {
      // クリック
      this.mlMap.on("click", this.onMapClick);

      // 作図完了
      this.mlMap.on(MLMap.EVENT_SHAPE_DRAW_FINISH, this.onMapShapeDrawFinish);
    },
    /**
     * 地図クリックイベント
     * @param {Object} e イベントデータ
     */
    onMapClick(e) {
      if (this.isMovingCurrentLocation) {
        this.isMovingCurrentLocation = false;
        return;
      }

      if (this.operationMode == 0 || this.operationMode == 1) {
        if (this.isSelected) {
          // 選択時: 移動処理
          //this.$emit("onAreaClickMove", { x: e.latlng.lng, y: e.latlng.lat });
        } else {
          // 未選択時: 選択処理
          const items = this.getPoiItems(e);
          if (items.length < 1) return;
          this.$emit("onAreaClickSelect", items[0]);

          // ポイント選択中の仮図形を作図する
          this.addTempPoi([items[0].y, items[0].x]);
        }
      } else {
        // 削除
        const items = this.getPoiItems(e);
        if (items.length < 1) return;
        this.$emit("onAreaClickRemove", items[0]);

        // ジオフェンス図形削除
        this.mlMap.removeShapeById(items[0].id);
        // アイコン削除
        this.mlMap.removePoiById(xss(items[0].layerName), items[0].id);
      }
    },
    /**
     * ジオフェンスを削除します
     */
    deleteShapeById(id) {
      // ジオフェンス図形削除
      this.mlMap.removeShapeById(id);
    },
    /**
     * アイコン図形を削除します
     */
    deletePoiById(id, layerName) {
      // アイコン削除
      this.mlMap.removePoiById(layerName, id);
    },
    /**
     * 一時POI図形を作図します。
     * ＊検査箇所の新規登録時にコールします。
     *
     * @param {latlng} latlng 座標YX
     */
    addTempPoi(latlng) {
      this.mlMap.clearTempPoi();

      this.mlMap.addTempPoi(latlng, {
        color: "#FF6D00",
        opacity: 0.8,
        weight: 4,
        fillColor: "#FF6D00",
        fillOpacity: 0.4,
        radius: 24,
      });
    },
    /**
     * 一時POI図形を削除します。
     */
    clearTempPoi() {
      this.mlMap.clearTempPoi();
    },
    /**
     * クリック時のイベント引数から打ち重ね範囲POIを取得します。
     *
     * @param {eventArg} e Map上のクリックイベント引数
     * @returns 打ち重ね範囲POI
     */
    getPoiItems(e) {
      let items = [];

      for (let i = 0; i < this.overlayTypeLayerName.length; i++) {
        try {
          items = this.mlMap.getPoiByDomMouseEvent(
            this.overlayTypeLayerName[i],
            e.originalEvent
          );

        } catch (e) {
          if (e.message != "Cannot read properties of null (reading 'mouseEventToLayerPoint')") {
            throw e;
          }
        }

        if (items.length > 0) {
          return items;
        }
      }

      return items;
    },
    /**
     * 打ち重ね範囲描画
    */
    drawoverlay(parameters) {
      try {
        for (let overlay of parameters) {
          const x = overlay.x;
          const y = overlay.y;

          // -------------------------
          // ジオフェンスの作図
          // -------------------------
          let compressCoordsText;

          // 図形タイプがポリゴンの場合は、DB値をそのまま使用
          if (overlay.shapeType == "1") {
            compressCoordsText = overlay.polygon;
          } else {
            this.overlayStyle.radius = overlay.radius;
            // 中心座標、半径から、ポリゴン座標を取得
            const latlngs = CoordinateUtil.getCirclePolygonPoints(
              new L.LatLng(y, x),
              overlay.radius
            );
            console.log('Generated LatLngs:', latlngs);
            // ポリゴン座標をmlMap圧縮文字列にする
            compressCoordsText = MLCompressUtil.latLngArrayToBase64(
              latlngs
            );
            console.log('Generated compressCoordsText:', compressCoordsText);
          }

          // ID
          let shapeItem = { id: overlay.id };

          // addShape
          if (overlay.shapeType == "1") {
            this.mlMap.addShape(
              MLMap.SHAPE_TYPE.POLYGON,
              compressCoordsText,
              shapeItem,
              this.intervaloverlayStyle
            );
          } else {
            this.mlMap.addShape(
              MLMap.SHAPE_TYPE.CIRCLE,
              compressCoordsText,
              shapeItem,
              this.overlayStyle
            );
          }

          // -------------------------
          // アイコンの作図
          this.addPoiLayers(overlay.pointName);
          // アイコン図形をプロットを追加
          this.mlMap.addPoi(overlay.pointName, {
            id: overlay.id,
            x: overlay.x,
            y: overlay.y,
            label: overlay.pointName,
            layerName: overlay.pointName,
          });
          const latlng = [y, x];
          this.latlngList.push(latlng);

          this.overlayTypeLayerName.push(overlay.pointName);
        }
      } catch (e) {
        this.errorLog("drawoverlay", this.parseErrorObject(e));
        // appLog.errLog("DriveoverlayMap.vue", ``, `${e.message}`);
      }

      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();
    },
    /*
     * ジオフェンスポリゴン作図モードを開始します。
     */
    startDrawPolygonMode() {
      this.$nextTick(() => {
        this.mlMap.startDirectDrawShapeMode(MLMap.SHAPE_TYPE.POLYGON);
        console.log("start polygon ################");
      });
    },
    /**
     * ジオフェンス作図モードを終了します。
     */
    stopDrawShapeMode() {
      this.$nextTick(() => {
        this.mlMap.stopDirectDrawShapeMode();
        console.log("Stopping drawing mode...");
      });
    },
    /**
     * ジオフェンスポリゴン作図モードを開始します。
    */
    startDrawCircleMode() {
      this.$nextTick(() => {
        this.mlMap.startDirectDrawShapeMode(MLMap.SHAPE_TYPE.CIRCLE);
        console.log("start circle ################");
      });
    },
    /**
     * 作図完了イベント
     * @param {Object} e イベントデータ
     */
    onMapShapeDrawFinish(e) {
      console.log(`${e.type}`);

      // AWSに登録するジオフェンスポリゴンは時計回りをサポートしていないため、
      // 時計回りポリゴンの場合は座標配列要素を反転させ反時計回りポリゴンにする
      if (e.shapeType === MLMap.SHAPE_TYPE.POLYGON) {
        let isClockWise = CoordinateUtil.isClockWise(e.layer);
        console.log(`isClockWise: ${isClockWise}`);

        if (isClockWise) {
          // 座標配列要素を反転
          e.layer.getLatLngs()[0].reverse();

          let isClockWise = CoordinateUtil.isClockWise(e.layer);
          console.log(`Reverse isClockWise: ${isClockWise}`);
        }
      }

      // 親のBaseArea.vueの処理を呼ぶ
      this.$emit("onDrawPolygonFinish", {
        id: "",
        layer: e.layer,
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e);
      }
    },
  },
};
</script>

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "dd0dfc5cb5bb4ad785c53c59bf26e8ae",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "dd0dfc5cb5bb4ad785c53c59bf26e8ae",
            "region": "us-west-2"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "dd0dfc5cb5bb4ad785c53c59bf26e8ae",
                "region": "us-west-2"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://rgrbdcc11e.execute-api.ap-northeast-1.amazonaws.com/stg",
            "region": "ap-northeast-1"
        },
        {
            "name": "konoikeconcreteapi",
            "endpoint": "https://r43guayxll.execute-api.ap-northeast-1.amazonaws.com/stg",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://7bzliehtojbr5nfysks3v7ajiq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:fa697054-3642-42bd-95a0-775d9aeb50f8",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_OyMQwOrIo",
    "aws_user_pools_web_client_id": "2bopuufn4nrqb5e8hscnh0o0q3",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "kikstorage-userdatastorage103114-stg",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;

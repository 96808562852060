import { createRouter, createWebHashHistory } from 'vue-router'
import AuthLayout from "@/appViews/Layout/AuthLayout";
import MainteLayout from "@/appViews/Layout/MainteLayout";
import DashboardLayout from "@/appViews/Layout/DashboardLayout";
import MobileLayout from "@/appViews/Layout/MobileLayout";

import store from '../store'

// 以下に各機能のvueを追加する
import Projects from "@/appViews/Projects/Projects.vue";
import ProjectMain from "@/appViews/Projects/ProjectMain.vue";
import ProjectEditor from "@/appViews/Projects/ProjectEditor.vue";
import UserEdit from "@/appViews/User/MakeUser.vue";
import UserList from "@/appViews/User/UserList.vue";
import UISample from '@/appViews/Sample/UISample.vue';
import Signin from '@/appViews/Signin/Signin.vue';
import MakeUserModal from '@/appViews/User/MakeUserModal.vue';

// テナント作成管理者画面
import TenantManage from "@/appViews/Mainte/TenantManage.vue";
import TenantEdit from "@/appViews/Mainte/TenantEdit.vue";

// 車両一覧
import VehicleList from "@/appViews/Vehicle/VehicleList.vue";
// 予定数量登録
import PlanList from "@/appViews/Plan/PlanList.vue";
import PlanEdit from "@/appViews/Plan/PlanEdit.vue";
// 地図表示
import TimeLine from "@/appViews/Projects/TimeLine";
import MobileTimeLine from "@/appViews/Projects/MobileTimeLine";
// 走行履歴
import DrivingHistory from "@/appViews/Projects/DrivingHistory";
// 拠点編集
import BaseArea from "@/appViews/BaseArea/BaseArea";
// 実績詳細表示
import SignageInfo from "@/appViews/Signage/SignageInfo";
import MobileSignageInfo from "@/appViews/Signage/MobileSignageInfo";
// 運行スポット登録
import DriveSpot from "@/appViews/Projects/DriveSpot";
// 運行スポットタイプ編集
import DriveSpotTypeEdit from "@/appViews/Projects/DriveSpotTypeEdit";
// 運行スポット設定
import DriveSpotSetting from "@/appViews/Projects/DriveSpotSetting";

// 管理画面サインイン
import MainteSignin from '@/appViews/Mainte/MainteSignin.vue';

// 受入検査一覧
import AcceptanceList from '@/appViews/Acceptance/AcceptanceList.vue';

// システム設定
import System from '@/appViews/System/System.vue'

// 利用端末管理
import DeviceList from "@/appViews/Device/DeviceList.vue";

import UserInfo from "../appUtils/UserInfo"
import TenantModel from "../appModel/Tenant/TenantModel"
import AuthUtil from "../appUtils/AuthUtil"
import { Auth } from "aws-amplify";

//打ち重ね図面登録
import OverlayDrawing from "@/appViews/Overlay/OverlayDrawing";
//打ち重ね経過時間設定
import OverlayTimeSetting from "@/appViews/Overlay/OverlayTimeSetting";
//経過時間設定作成
import OverlayTimeSets from "@/appViews/Overlay/OverlayTimeSets";
//打ち重ね範囲登録
import OverlayPoint from "@/appViews/Overlay/OverlayPoint";
//打ち重ね図面表示
// import Overlay from "@/appViews/Overlay/OverlayPoint";
import OverlayPointVue from "@/appViews/Overlay/OverlayPointVue";

let routes = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "Signin",
        components: {default: Signin}
      }, 
    ]
  },
  {
    path: '/mobiletimeline',
    component: MobileLayout,
    children: [
      {
        path: "/mobiletimeline",
        name: "MobileTimeLine",
        components: {default: MobileTimeLine}
      }, 
      {
        path: "/mobilesignage",
        name: "MobileSignageInfo",
        components: {default: MobileSignageInfo}
      }, 
      {
        path: "/zandomobiletimeline",
        name: "ZandoMobileTimeLine",
        components: {default: MobileTimeLine}
      }, 
      {
        path: "/zandomobilesignage",
        name: "ZandoMobileSignageInfo",
        components: {default: MobileSignageInfo}
      }, 
    ]
  },
  {
    path: "/userlist",
    component: DashboardLayout,
    children: [
      {
        path: "/userlist",
        name: "UserList",
        components: { default: UserList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ユーザー管理",
        },
      },
      {
        path: "/makeuser",
        name: "MakeUser",
        components: { default: UserEdit },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ユーザー管理",
        },
        props: true,
      },
      {
        path: "/edituser",
        name: "EditUser",
        components: { default: UserEdit },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ユーザー管理",

        },
        props: true,
      },
      {
        path: "/editusermodal",
        name: "MakeUserModal",
        components: { default: MakeUserModal },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ユーザー編集",
        },
        props: true,
      }
    ]
  },
  {
    path: "/system",
    component: DashboardLayout,
    children: [
      {
        path: "/system",
        name: "System",
        components: { default: System },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "システム設定",
          hideFooter: true,
        },
      },
    ]
  },
  {
    path: "/devicelist",
    component: DashboardLayout,
    children: [
      {
        path: "/devicelist",
        name: "DeviceList",
        components: { default: DeviceList },
        beforeEnter: (to, from, next) => {
          if (store.state.user.userType == 1 || store.state.user.userType == 2) {
            next()
          } else {
            next(from)
          }
        },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "利用端末管理",
          hideFooter: true,
        },
      }
    ]
  },
  {
    path: "/zandoprojects",
    component: DashboardLayout,
    children: [
      {
        path: "/zandoprojects",
        name: "ZandoProjects",
        components: { default: Projects },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ダンプカー運行管理プロジェクト",
          hideFooter: true,
        },
      },
      {
        path: "/zandonewproject",
        name: "ZandoNewProject",
        components: { default: ProjectEditor },
        meta: {
          navbarType: "light",
          isNewProject: 1,
          requiresAuth: true,
          title: "ダンプカー運行新規プロジェクト作成",
          hideFooter: true,
        },
      },
      {
        path: "/zandoeditproject",
        name: "ZandoEditProject",
        components: { default: ProjectEditor },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "ダンプカー運行プロジェクト編集",
          hideFooter: true,
        },
      },
      {
        path: "/zandovehiclelist",
        name: "ZandoVehicleList",
        components: { default: VehicleList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "車両情報",
          hideFooter: true,
        },
      },
      {
        path: "/zandoplanList",
        name: "ZandoPlanList",
        components: { default: PlanList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量（登録日）",
          hideFooter: true,
        },
      },
      {
        path: "/zandoplanEdit",
        name: "ZandoPlanEdit",
        components: { default: PlanEdit },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量情報",
          hideFooter: true,
        },
      },   
      {
        path: "/zandosignage",
        name: "ZandoSignage",
        components: { default: SignageInfo },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "実績情報",
          hideFooter: true,
        },
      },
      {
        path: "/zandotimeline",
        name: "ZandoTimeLine",
        components: { default: TimeLine },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行情報",
          hideFooter: true,
        },
      },
      {
        path: "/zandodrivingHistory",
        name: "ZandoDrivingHistory",
        components: { default: DrivingHistory },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "走行履歴表示",
          hideFooter: true,
        },
      },  
      {
        path: "/zandobaseArea",
        name: "ZandoBaseArea",
        components: { default: BaseArea },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "拠点編集",
          hideFooter: true,
        },
      },   
      {
        path: "/zandodriveSpot",
        name: "ZandoDriveSpot",
        components: { default: DriveSpot },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行スポット登録",
          hideFooter: true,
        },
      },
      {
        path: "/zandodriveSpotTypeEdit",
        name: "ZandoDriveSpotTypeEdit",
        components: { default: DriveSpotTypeEdit },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行スポットタイプ編集",
          hideFooter: true,
        },
      },
      {
        path: "/zandodriveSpotSetting",
        name: "ZandoDriveSpotSetting",
        components: { default: DriveSpotSetting },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行スポット設定",
          hideFooter: true,
        },
      },
    ]
  },
  {
    path: "/projects",
    component: DashboardLayout,
    children: [
      {
        path: "/projects",
        name: "Projects",
        components: { default: Projects },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "生コン運搬管理プロジェクト",
          hideFooter: true,
        },
      },
      {
        path: "/vehiclelist",
        name: "VehicleList",
        components: { default: VehicleList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "車両情報",
          hideFooter: true,
        },
      },
      {
        path: "/planList",
        name: "PlanList",
        components: { default: PlanList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量（登録日）",
          hideFooter: true,
        },
      },
      {
        path: "/planEdit",
        name: "PlanEdit",
        components: { default: PlanEdit },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量情報",
          hideFooter: true,
        },
      },   
      {
        path: "/overlayDrawing",
        name: "overlayDrawing",
        components: { default: OverlayDrawing },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量情報 - 打ち重ね図面登録",
          hideFooter: true,
        },
      },
      {
        path: "/overlayTimeSetting",
        name: "overlayTimeSetting",
        components: { default: OverlayTimeSetting },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量情報 - 打ち重ね経過時間設定",
          hideFooter: true,
        },
      },
      {
        path: "/overlayTimeSets",
        name: "overlayTimeSets",
        components: { default: OverlayTimeSets },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量情報 - 打ち重ね経過時間設定",
          hideFooter: true,
        },
      },
      {
        path: "/overlayPoint",
        name: "overlayPoint",
        components: { default: OverlayPoint },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "予定数量情報 - 打ち重ね範囲登録",
          hideFooter: true,
        },
      },
      {
        path: "/overlayPointVue",
        name: "overlayPointVue",
        components: { default: OverlayPointVue },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "打ち重ね図面表示",
          hideFooter: true,
        },
      },
      {
        path: "/signage",
        name: "Signage",
        components: { default: SignageInfo },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "打設実績情報",
          hideFooter: true,
        },
      },       
      {
        path: "/projectMain",
        name: "ProjectMain",
        components: { default: ProjectMain },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "プロジェクト",
          hideFooter: true,
        },
      },
      {
        path: "/newproject",
        name: "NewProject",
        components: { default: ProjectEditor },
        meta: {
          navbarType: "light",
          isNewProject: 1,
          requiresAuth: true,
          title: "新規プロジェクト作成",
          hideFooter: true,
        },
      },
      {
        path: "/editproject",
        name: "EditProject",
        components: { default: ProjectEditor },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "プロジェクト編集",
          hideFooter: true,
        },
      },
      {
        path: "/tenantManage",
        name: "TenantManage",
        components: { default: TenantManage },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          ncsManager: true,
          title: "テナント管理",
          hideFooter: true,
        },
      },
      {
        path: "/tenantEdit",
        name: "TenantEdit",
        components: { default: TenantEdit },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          ncsManager: true,
          title: "テナント編集",
          hideFooter: true,
        },
      },
      // {
      //   path: "/dbresistsample",
      //   name: "DBResistSample",
      //   components: { default: DBResistSample },
      //   meta: {
      //     navbarType: "light",
      //     requiresAuth: true,
      //     title: "DB登録サンプル",
      //     hideFooter: true,
      //   },
      // },
      {
        path: "/uisample",
        name: "UISample",
        components: { default: UISample },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "UIサンプル",
          hideFooter: true,
        },
      },
      {
        path: "/timeline",
        name: "TimeLine",
        components: { default: TimeLine },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行情報",
          hideFooter: true,
        },
      },
      {
        path: "/drivingHistory",
        name: "DrivingHistory",
        components: { default: DrivingHistory },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "走行履歴表示",
          hideFooter: true,
        },
      },  
      {
        path: "/baseArea",
        name: "BaseArea",
        components: { default: BaseArea },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "拠点編集",
          hideFooter: true,
        },
      },   
      {
        path: "/driveSpot",
        name: "DriveSpot",
        components: { default: DriveSpot },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行スポット登録",
          hideFooter: true,
        },
      },
      {
        path: "/driveSpotTypeEdit",
        name: "DriveSpotTypeEdit",
        components: { default: DriveSpotTypeEdit },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行スポットタイプ編集",
          hideFooter: true,
        },
      },
      {
        path: "/driveSpotSetting",
        name: "DriveSpotSetting",
        components: { default: DriveSpotSetting },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "運行スポット設定",
          hideFooter: true,
        },
      },            
      {
        path: "/acceptanceList",
        name: "AcceptanceList",
        components: { default: AcceptanceList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "受入検査",
          hideFooter: true,
        },
      },
    ]
  }
]

// nodeのenvがmainteの場合、VUE_APP_APPMODE = 1となる。
// Vueルーターの定義をメンテナンス用に上書きする。
if (process.env.VUE_APP_APPMODE == '1') {
  console.log(`VUE_APP_APPMODE: 1`)
  routes = [
    {
      path: '/',
      component: AuthLayout,
      children: [
        {
          path: "/",
          name: "MainteSignin",
          components: {default: MainteSignin}
        }
      ]
    },
    {
      path: '/',
      component: MainteLayout,
      children: [
        {
          path: "/tenantManage",
          name: "TenantManage",
          components: { default: TenantManage },
          meta: {
            isNewProject: 0,
            navbarType: "light",
            requiresAuth: true,
            title: "テナント管理",
            hideFooter: true,
          },
        },
        {
          path: "/tenantEdit",
          name: "TenantEdit",
          components: { default: TenantEdit },
          meta: {
            isNewProject: 0,
            navbarType: "light",
            requiresAuth: true,
            title: "テナント編集",
            hideFooter: true,
          },
        },
      ]
    }
  ]
}

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes
})

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  console.log(`router.beforeResolve`)
  // 認証を必要とする画面への遷移であるか判定
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // MCZユーザー専用画面の判定
    try {
      // 認証済みであるか
      const data = await Auth.currentAuthenticatedUser();

      if (data && data.signInUserSession) {
        // 認証済みである
        const info = await UserInfo.getUserInfo()

        // 有効期限内か
        console.log("有効期限内か")
        const isValidPeriod = await AuthUtil.isValidPeriod(info.group);
        if (!isValidPeriod) {
          AuthUtil.alert();
          return next({
            path: "/",
          });
        }

        return next();
        
      } else {
        // 未認証であればルートパスへリダイレクト
        console.log("未認証")
        AuthUtil.alert();
        return next({
          path: "/",
        });
      }
    } catch (e) {
      // 例外発生時もルートパスへリダイレクトする
      console.log("例外")
      AuthUtil.alert();
      // throw e
      return next({
        path: "/",
      });
      
    }
  }

  return next();
});

export default router

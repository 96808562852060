<template>
  <application-header
    :title="appTitle"
    :isPlandate="true"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isDriveVue="true"
    :isOverlay="true"
    @driveVue="onClickDriveVue"
  ></application-header>

  <div class="d-flex">
    <div class="row ml-0 mt-2">
      <div class="col-sm-12">
        <a href="#!" @click.prevent="onClickReturn()">{{returnScreen}}</a>
      </div>
    </div>
  </div>

  <!----------------------------------------------------
    打ち重ね図面登録、打設範囲登録、経過時間設定ボタン
  ------------------------------------------------------>
  <div class="d-flex button-area">
    <div class="col-sm-2">
        <base-button type="primary" block @click="onClickOverlayDrawing">図面登録</base-button>
      </div>
      <div class="col-sm-2">
        <base-button type="primary" block @click="onClickOverlayPointRegister">打設範囲登録</base-button>
      </div>
      <div class="col-sm-2">
        <base-button type="primary" :disabled="true" block @click="onClickOverlayTimeSetting">経過時間設定</base-button>
      </div>
  </div>
  <el-form >
    <div class="overlaytimesetting_area">
      <div style="margin-left:15px;margin-top:20px" >
        <div class="row">
          <div class="col-lg-12">
            <h3>経過時間設定<require-tag /></h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7">
            <el-form-item prop="name">
              <base-input name="timeSetId">
                <el-select placeholder="選択してください"
                  v-model="changeTimeSettingId"
                  @change="onChangeTimeSet"
                  readonly="true"
                >
                  <el-option v-for="item in timeSettings"
                            class="select-primary"
                            :value="item.id"
                            :label="item.name"
                            :key="item.id"
                            >
                  </el-option>
                </el-select>
              </base-input>
            </el-form-item>
          </div>
          <div class="col-lg-2">
            <base-button type="primary" block @click="onClickOverlayTimeSets">経過時間設定作成</base-button>
          </div>
        </div>
        <!-- 経過時間設定テーブル -->
        <div class="row">
          <div class="col-lg-8">
            <div class="time-list" v-if="displayTable">
              <el-table
                class="table-responsive align-items-center table-flush"
                header-row-class-name="app-th-row"
                :cell-style="{ textAlign: 'center' }"
                :header-cell-style="{ textAlign: 'center' }"
                :select-on-indeterminate="true"
                :data="timeSets"
              >
                <el-table-column label="経過時間（始）" min-width="80px" width="170px" prop="elapsedFrom">
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="分単位です" placement="right">
                      <span>
                        経過時間（始）
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="設定番号" min-width="50px" width="150px">
                  <template #default="{row}">
                    <div style="text-align: left;">
                      ＜ 設定{{ row.itemNumber }} ≦
                    </div>
                  </template></el-table-column>
                <el-table-column label="経過時間（終）" min-width="80px" width="170px" prop="elapsedTo">
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="分単位です" placement="right">
                      <span>
                        経過時間（終）
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="設定色" min-width="80px" width="150px" prop="colorId">
                  <template #default="{row}">
                    <div class="color-box" :style="{ backgroundColor: row.colorId}"></div>
                  </template>
                </el-table-column>
                <el-table-column label="push通知" min-width="80px" width="100px">
                  <template #header>
                    <el-tooltip class="box-item" effect="dark" content="経過時間（始）を経過したタイミングでPUSH通知を送信します" placement="right">
                      <span>
                        push通知
                        <i class="far fa-question-circle" style="margin-bottom:8px; margin-left:5px;"></i>
                      </span>
                    </el-tooltip>
                  </template>
                  <template #default="{row}">
                    <div style="text-align: center;">
                      <el-checkbox v-model="row.elapsedAlert" :true-label="1" :false-label="0" disabled />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="my-3">
            <div style="width: 480px; margin:0 auto;margin-left: 400px;">
              <div class="row mt-2 mb-2 ml-2 mr-2">
                <div class="col-sm-6">
                  <base-button
                    block
                    type="primary"
                    @click="onClickSave"
                    :disabled="isPassedDay || usePouring"
                    >保存</base-button
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- テナント削除確認モーダル -->
          <modal v-model:show="modals.confirmDlg">
            <template v-slot:header>
              <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
            </template>
            <div class="mb-5">
              <p class="message">打ち重ね図面が登録されておりません。</p>
              <p class="message">下のボタンを押すと図面登録へ移動します。</p>
            </div>
            <div class="row mt-2 mb-2 ml-2 mr-2">
              <div class="col-sm-3">
              </div>
              <div class="col-sm-6">
                <base-button type="danger" block @click="onClickOverlayDrawing">{{confirmOK}}</base-button>
              </div>
              <div class="col-sm-3">
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </el-form>
  <!-- </el-tab-pane> -->
</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */

.time-list {
  width: 100%;
  height: 100%;
  margin-left: 20px;
}

.td-center {
  text-align: center;
}

.button-area {
  margin-top: 10px;
  width: 100%;
  height: 10px;
}

.overlaytimesetting_area {
  /* position:absolute; */
  margin-top: 50px;
  width: 100%;
  height: 100%;
  background-color: white;
}

.color-box {
  width: 70px;
  height: 30px;
  border: 1px solid #ccc;
}
</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import { useToast } from "vue-toastification";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import OverlayDrawingModel from "@/appModel/Overlay/OverlayDrawingModel";
import OverlayTimeSettingModel from "@/appModel/Overlay/OverlayTimeSettingModel";
import UserInfo from "@/appUtils/UserInfo";
import Modal from "@/components/Modal";
import DateUtil from "@/appUtils/DateUtil"
import OverlayModel from "@/appModel/Overlay/OverlayModel";

export default {
  components: {
    ApplicationHeader,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      appTitle: "打ち重ね経過時間設定",
      tenantId: "",
      planid: "",
      projectId: "",
      projectName: "",
      datestring: "",
      overlayDrawingId: "",
      overlayDrawingData: "",
      timeSettings: [],
      overlayTimeSettingList: [],
      changeTimeSettingId: "",
      displayTable: false,
      timeSets: [],
      isBaseTemplate: false,
      tempOverlayTimeSettingList: "",
      selectedIndex: "",
      modals: {
        editDateDlg: false,
        confirmDlg: false,
      },
      confirmTitle: `お知らせ`,
      confirmOK: `図面登録へ遷移`,
      overlayData: null,
      isPassedDay: false,
      usePouring: false,
      returnScreen: '',
    };
  },
  // コンピュートプロパティ
  computed: {
    currentProject() {
      return this.$store.state.project;
    },
    vuename() {
      return "OverlayTimeSetting.vue";
    }
  },
  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },
  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "BaseArea.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.drivespot.projectid})、projectname(${this.$store.state.drivespot.projectname})、datestring(${this.$store.state.drivespot.datestring})`
    );

    this.init();
    const _this = this;
    this.tenantId = this.getTenantId().then(id => {
      _this.tenantId = id;
    })
  },
  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
    this.init().then(function(){
      console.log(``);
    })
  },
  mounted() {
    // インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },
  updated() {
    // データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },
  unmounted() {
    // Vue インスタンスが破壊された後
  },
  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    appLog.infoLog("BaseArea.vue", this.$store.state.user.userId, `End`);
    next();
  },
  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        await this.initialData(); // 初期データ

        const tenantId = await this.getTenantId();
        this.tenantId = tenantId;

        // const overlayDrawingData = await this.fetchOverlayDrawingData(tenantId);
        await this.fetchOverlayDrawingData(tenantId); // 打ち重ね図面データを取得
        if (!this.overlayDrawingData || this.overlayDrawingData.length === 0) {
          this.showOverlayDrawingModal(); // モーダルを表示
          return;
        }

        await this.fetchOverlayTimeSettingList(); // 時間設定リストをフェッチ
        await this.fetchTimeSettings(); // タイム設定をフェッチ

        const overlayTimeSettingId = this.overlayDrawingData.overlayDrawing.overlayTimeSettingId;
        if (this.isValidOverlayTimeSettingId(overlayTimeSettingId)) {
          this.changeTimeSettingId = overlayTimeSettingId;
          await this.initTimeSet(overlayTimeSettingId);
          this.displayTable = true;
        }

        let createDate = this.$store.state.plan.createdate
        let nowDate = DateUtil.getFormatString(Date.now(), 'YYYYMMDD')
        let chkCreateDate = DateUtil.getFormatString(createDate, 'YYYYMMDD')
        if (nowDate > chkCreateDate){
          this.isPassedDay = true
        }
        //打設が開始されているかるかをチェックする
        this.usePouring = await this.checkPouring();
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e));
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * プロジェクトID修得
     */
    getProjectId() {
      return this.currentProject.sk.split("#")[1];
    },
    /**
     * テナントID修得
     */
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }
      return loginInfo.group;
    },
    /**
     * 打設が開始されているかるかをチェックする
    */
    async checkPouring(){
      let usePouring = false;
      let overlayPouringList = await OverlayModel.getOverlayListByDrawing(this.tenantId, this.projectId, this.overlayDrawingId);
      if (overlayPouringList.length >0){
        usePouring=true;
      }
      return usePouring;
    },
    /**
     * 初期データをフェッチ
     */
    async initialData() {
      // store(vuex)から値取得
      this.planid = this.$store.state.plan.planid;
      this.projectId = this.$store.state.overlay.projectid;
      this.datestring = this.$store.state.overlay.datestring;
      this.projectName = this.$store.state.overlay.projectname;
      this.overlayDrawingId = this.$store.state.overlay.overlayrawingid;
      this.returnScreen = '＜ ' + DateUtil.dateStringBase(this.$store.state.plan.createdate, 'YYYY/MM/DD') + ' 予定数量';

      // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
      this.userAuthority = this.$store.state.user.userType;
      if (this.userAuthority == 0) {
        this.editableFlag = false;
      }

      // タイトルをセット
      let navInfo = this.$store.state.navInfo;
      navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
      navInfo.description = "";
      this.$store.commit("setNavInfo", navInfo);
    },
    /**
     * 打ち重ね図面データをフェッチ
     */
    async fetchOverlayDrawingData(tenantId) {
      const overlayDrawingData = await this.getOverlayDrawingData(tenantId);
      if (overlayDrawingData.length === 0) {
        this.showOverlayDrawingModal(); // モーダル表示
      } else {
        this.overlayDrawingData = overlayDrawingData;
      }
    },
    /**
     * 経過時間設定をフェッチ
     */
    async fetchTimeSettings() {
      const timeSettings = await this.getTimeSettings();
      this.timeSettings = timeSettings;
    },
    /**
     * 経過時間設定リストをフェッチ
     */
    async fetchOverlayTimeSettingList() {
      const overlayTimeSettingList = await this.getOverlayTimeSettingList();
      this.overlayTimeSettingList = overlayTimeSettingList;
    },
    /**
     * 経過時間設定ID確認
     * @param {int} overlayTimeSettingId
     */
    isValidOverlayTimeSettingId(overlayTimeSettingId) {
      return overlayTimeSettingId && overlayTimeSettingId.trim() !== "" && overlayTimeSettingId.length > 0;
    },
    /**
     * モーダルを表示
     */
    showOverlayDrawingModal() {
      this.modals.confirmDlg = true;
    },
    /**
     * 打ち重ね図面情報取得
     */
    async getOverlayDrawingData(tenantId) {
      let drawingData = await OverlayDrawingModel.getOverlayDrawing(
        tenantId,
        this.projectId,
        this.overlayDrawingId
      );
      return drawingData;
    },
    /**
     * 経過時間設定選択ボックス表示用
     */
    async getTimeSettings() {
      let overlayTimeSettingList = await this.getOverlayTimeSettingList();
      let returnList = []
      for (let i = 0; i < overlayTimeSettingList.length; i++) {
        let el = {};
        el["sk"] = overlayTimeSettingList[i].sk;
        el["id"] = overlayTimeSettingList[i].overlayTimeSetting.id;
        el["name"] = overlayTimeSettingList[i].overlayTimeSetting.name;
        returnList.push(el);
      }
      return returnList;
    },
    /**
     * テナントに属してる経過時間設定テンプレートリスト取得
     */
    async getOverlayTimeSettingList() {
      const tenantId = await this.getTenantId();
      this.overlayTimeSettingList = await OverlayTimeSettingModel.getOverlayTimeSettingList(tenantId);

      // 修得したテンプレートリストがデフォルト設定かを確認
      if (this.overlayTimeSettingList.length == 1 && this.overlayTimeSettingList[0].overlayTimeSetting.isBaseTemplate) {
        delete this.overlayTimeSettingList[0].overlayTimeSetting["isBaseTemplate"];
        this.tempOverlayTimeSettingList = this.overlayTimeSettingList;
        this.isBaseTemplate = true; // デフォルト設定
      }
      return this.overlayTimeSettingList;
    },
    /**
     * 経過時間設定Display用
     */
    async getTimeSets(index) {
      let timeList = this.overlayTimeSettingList;
      this.timeSets = timeList[index].overlayTimeSetting.overlayTimeSets;
    },
    /**
     * 初期経過時間表示
     */
    async initTimeSet(id) {
      const data = await OverlayTimeSettingModel.getOverlayTimeSetting(this.tenantId, id);
      this.timeSets = data.overlayTimeSetting.overlayTimeSets;
    },
    /**
     * 選択ボックスのonChangeイベント
     * 経過時間テンプレート選択
     */
    async onChangeTimeSet() {
      const selectedId = this.changeTimeSettingId;
      const timeList = this.overlayTimeSettingList;
      for (let i = 0; i < timeList.length; i++) {
        if (selectedId == timeList[i].overlayTimeSetting.id) {
          await this.getTimeSets(i);
          this.displayTable = true;
          this.selectedIndex = i;
          break;
        }
      }
    },
    /**
     * 保存ボタンクリックイベント
     */
    async onClickSave() {
      let loader = this.showLoader();

      try {
        let timeDatakey = null;
        if (this.isBaseTemplate) {
          // デフォルト設定を保存
          let timeData = this.tempOverlayTimeSettingList[0];
          timeDatakey = timeData.overlayTimeSetting.id;
          timeData.overlayTimeSetting.name = timeData.overlayTimeSetting.name + " - 1";
          await OverlayTimeSettingModel.addOverlayTimeSetting(timeData);
          this.isBaseTemplate = false;
        } else {
          timeDatakey = this.overlayTimeSettingList[this.selectedIndex].overlayTimeSetting.id;
        }
        let data = this.overlayDrawingData;
        data.overlayDrawing.overlayTimeSettingId = timeDatakey;
        await OverlayDrawingModel.updateOverlayDrawing(data);

        // 完了メッセージ
        this.showBottomToast(`経過時間設定保存処理が完了しました。`, 'info');
        // 親ビューに通知
        this.$emit("onSave");
      } catch (e) {
        this.errorLog("onClickSave", this.parseErrorObject(e));
        this.showBottomToast(`経過時間設定を保存時にエラーが発生しました。`, 'error');
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue(){
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },

    /**
     * 予定登録日一覧画面に遷移
    */
    onClickReturn(){
      this.$router.push({
        path: this.getRoutePath(`planEdit`),
      });
    },

    /**
     * 図面登録ボタンクリック時の処理
     */
    async onClickOverlayDrawing() {
      // 打ち重ね図面登録画面起動
      this.$router.push({
        path: "/overlayDrawing",
      });
    },
    /**
     * 打設範囲登録ボタンクリック時の処理
     */
    async onClickOverlayPointRegister() {
      // 打設範囲登録画面起動
      this.$router.push({
        path: "/overlayPoint",
      });
    },
    /**
     * 経過時間設定ボタンクリック時の処理
     */
    async onClickOverlayTimeSetting() {
      // 経過時間設定画面起動
      this.$router.push({
        path: "/overlayTimeSetting",
      });
    },
    /**
     * 経過時間設定作成ボタンクリック時の処理
     */
    async onClickOverlayTimeSets() {
      // 経過時間設定作成画面起動
      this.$router.push({
        path: "/overlayTimeSets",
      });
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e);
      }
    },
  },
};
</script>

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createKonoikeConcreteLog = /* GraphQL */ `
  mutation CreateKonoikeConcreteLog(
    $input: CreateKonoikeConcreteLogInput!
    $condition: ModelKonoikeConcreteLogConditionInput
  ) {
    createKonoikeConcreteLog(input: $input, condition: $condition) {
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      id
      geofenceJudgeCount
      lastLoginDate
      loginUser
      loginUserSub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKonoikeConcreteLog = /* GraphQL */ `
  mutation UpdateKonoikeConcreteLog(
    $input: UpdateKonoikeConcreteLogInput!
    $condition: ModelKonoikeConcreteLogConditionInput
  ) {
    updateKonoikeConcreteLog(input: $input, condition: $condition) {
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      id
      geofenceJudgeCount
      lastLoginDate
      loginUser
      loginUserSub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKonoikeConcreteLog = /* GraphQL */ `
  mutation DeleteKonoikeConcreteLog(
    $input: DeleteKonoikeConcreteLogInput!
    $condition: ModelKonoikeConcreteLogConditionInput
  ) {
    deleteKonoikeConcreteLog(input: $input, condition: $condition) {
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      id
      geofenceJudgeCount
      lastLoginDate
      loginUser
      loginUserSub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKonoikeConcreteSetting = /* GraphQL */ `
  mutation CreateKonoikeConcreteSetting(
    $input: CreateKonoikeConcreteSettingInput!
    $condition: ModelKonoikeConcreteSettingConditionInput
  ) {
    createKonoikeConcreteSetting(input: $input, condition: $condition) {
      pk
      sk
      id
      name
      value
      voicetext
      iconpath
      order
      intValue
      month
      temperature
      lowTemperature
      HighTemperature
      transitTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKonoikeConcreteSetting = /* GraphQL */ `
  mutation UpdateKonoikeConcreteSetting(
    $input: UpdateKonoikeConcreteSettingInput!
    $condition: ModelKonoikeConcreteSettingConditionInput
  ) {
    updateKonoikeConcreteSetting(input: $input, condition: $condition) {
      pk
      sk
      id
      name
      value
      voicetext
      iconpath
      order
      intValue
      month
      temperature
      lowTemperature
      HighTemperature
      transitTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKonoikeConcreteSetting = /* GraphQL */ `
  mutation DeleteKonoikeConcreteSetting(
    $input: DeleteKonoikeConcreteSettingInput!
    $condition: ModelKonoikeConcreteSettingConditionInput
  ) {
    deleteKonoikeConcreteSetting(input: $input, condition: $condition) {
      pk
      sk
      id
      name
      value
      voicetext
      iconpath
      order
      intValue
      month
      temperature
      lowTemperature
      HighTemperature
      transitTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKonoikeConcreteData = /* GraphQL */ `
  mutation CreateKonoikeConcreteData(
    $input: CreateKonoikeConcreteDataInput!
    $condition: ModelKonoikeConcreteDataConditionInput
  ) {
    createKonoikeConcreteData(input: $input, condition: $condition) {
      id
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      groups
      createdUser
      updatedUser
      invalid
      tenant {
        tenantId
        name
        contractStart
        contractEnd
        status
        availableOptions
        email
        initUserId
        geofenceCollectionId
        memo
        useApps
        deviceNum
        enableDemo
        __typename
      }
      user {
        name
        userId
        usertype
        status
        period
        email
        deleted
        organizationId
        __typename
      }
      project {
        id
        name
        intervalMinutes
        users
        options {
          id
          label
          value
          __typename
        }
        numberAlert
        remainingTime
        projectType
        __typename
      }
      baseArea {
        id
        areaName
        areaType
        x
        y
        radius
        geofence
        shapeType
        __typename
      }
      location {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
        roadType
        __typename
      }
      lastLocation {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
        __typename
      }
      drive {
        id
        vehicleId
        driveNumber
        factoryStartTime
        siteEndTime
        pouringStartTime
        pouringEndTime
        siteStartTime
        factoryEndTime
        roadType
        endAreaId
        pouringPosition
        carryType
        maxCapacity
        pouringStartUser
        pouringEndUser
        congestionFlag
        congestionLog
        totalAmount
        startAreaId
        volumeId
        deliveryFlag
        unloadingTime
        unloadingUser
        rockTypeName
        loadCapacity
        __typename
      }
      vehicle {
        id
        name
        maxCapacity
        memo
        order
        selected
        numberPlate
        __typename
      }
      plan {
        id
        createDate
        temperature
        __typename
      }
      volume {
        id
        startAreaId
        endAreaId
        pouringPosition
        totalVolume
        grade
        settingAlert
        numberAlert
        rockTypeId
        loadCapacity
        overlayDrawingId
        __typename
      }
      ttl
      driveStatus
      spot {
        id
        spottypeid
        name
        value
        voicetext
        iconpath
        range
        spotType
        x
        y
        geofence
        shapeType
        congestionFlag
        createdNumber
        __typename
      }
      geofenceLog {
        EventType
        GeofenceId
        DeviceId
        SampleTime
        longitude
        latitude
        __typename
      }
      drivesetting {
        driveSettingId
        projectId
        planId
        volumeId
        pouringPosition
        projectType
        __typename
      }
      notice {
        id
        noticeType
        title
        content
        argment
        completedPush
        __typename
      }
      noticeSegment {
        id
        __typename
      }
      delivery {
        deliveryId
        vehicleId
        startTime
        endTime
        capacity
        deliveryPhoto
        __typename
      }
      acceptance {
        acceptanceId
        inspectionDateTime
        slump
        chlorideQuantity
        airVolume
        ct
        at
        photos {
          photoType
          photoName
          __typename
        }
        memo
        updateEditor
        updateUserID
        updateDate
        weather
        __typename
      }
      route {
        routeId
        routeGeometry
        routeDistance
        roadType
        __typename
      }
      spottype {
        spottypeId
        name
        voicetext
        iconpath
        spotType
        order
        __typename
      }
      intervalsetting {
        delayTime
        dwellTime
        __typename
      }
      judgetraffic {
        judgetrafficeId
        vehicleId
        roadType
        passageTime
        __typename
      }
      mileage {
        driveId
        vehicleId
        maxCapacity
        totalMileage
        suddenStartCount
        suddenStopCount
        speedViolationCount
        dayAt00h
        dayAt01h
        dayAt02h
        dayAt03h
        dayAt04h
        dayAt05h
        dayAt06h
        dayAt07h
        dayAt08h
        dayAt09h
        dayAt10h
        dayAt11h
        dayAt12h
        dayAt13h
        dayAt14h
        dayAt15h
        dayAt16h
        dayAt17h
        dayAt18h
        dayAt19h
        dayAt20h
        dayAt21h
        dayAt22h
        dayAt23h
        __typename
      }
      co2setting {
        speedThreshold
        suddenStartValue
        suddenStopValue
        ecoDriveParam
        AClassCount
        BClassCount
        CClassCount
        AClassFuelParam
        BClassFuelParam
        CClassFuelParam
        fuelMileage1
        fuelMileage2
        fuelMileage3
        fuelMileage4
        fuelMileage5
        ArankFuelMemo
        BrankFuelMemo
        CrankFuelMemo
        fuelMileage1Memo
        fuelMileage2Memo
        fuelMileage3Memo
        fuelMileage4Memo
        fuelMileage5Memo
        __typename
      }
      rocktype {
        id
        name
        loadCapacity
        order
        __typename
      }
      deviceId {
        deviceId
        modelName
        lastUsedDate
        createDate
        deviceType
        userId
        __typename
      }
      overlayDrawing {
        id
        name
        path
        overlayTimeSettingId
        __typename
      }
      overlayTimeSetting {
        id
        name
        overlayTimeSets {
          itemNumber
          elapsedFrom
          elapsedTo
          colorId
          elapsedAlert
          __typename
        }
        __typename
      }
      overlayPoint {
        id
        pointName
        x
        y
        radius
        polygon
        shapeType
        __typename
      }
      overlay {
        id
        puringNumber
        puringStatus
        startTime
        endTime
        elapsedTime
        completeTime
        __typename
      }
      spotPassLog {
        id
        spotId
        passDate
        driveNumber
        roadType
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKonoikeConcreteData = /* GraphQL */ `
  mutation UpdateKonoikeConcreteData(
    $input: UpdateKonoikeConcreteDataInput!
    $condition: ModelKonoikeConcreteDataConditionInput
  ) {
    updateKonoikeConcreteData(input: $input, condition: $condition) {
      id
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      groups
      createdUser
      updatedUser
      invalid
      tenant {
        tenantId
        name
        contractStart
        contractEnd
        status
        availableOptions
        email
        initUserId
        geofenceCollectionId
        memo
        useApps
        deviceNum
        enableDemo
        __typename
      }
      user {
        name
        userId
        usertype
        status
        period
        email
        deleted
        organizationId
        __typename
      }
      project {
        id
        name
        intervalMinutes
        users
        options {
          id
          label
          value
          __typename
        }
        numberAlert
        remainingTime
        projectType
        __typename
      }
      baseArea {
        id
        areaName
        areaType
        x
        y
        radius
        geofence
        shapeType
        __typename
      }
      location {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
        roadType
        __typename
      }
      lastLocation {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
        __typename
      }
      drive {
        id
        vehicleId
        driveNumber
        factoryStartTime
        siteEndTime
        pouringStartTime
        pouringEndTime
        siteStartTime
        factoryEndTime
        roadType
        endAreaId
        pouringPosition
        carryType
        maxCapacity
        pouringStartUser
        pouringEndUser
        congestionFlag
        congestionLog
        totalAmount
        startAreaId
        volumeId
        deliveryFlag
        unloadingTime
        unloadingUser
        rockTypeName
        loadCapacity
        __typename
      }
      vehicle {
        id
        name
        maxCapacity
        memo
        order
        selected
        numberPlate
        __typename
      }
      plan {
        id
        createDate
        temperature
        __typename
      }
      volume {
        id
        startAreaId
        endAreaId
        pouringPosition
        totalVolume
        grade
        settingAlert
        numberAlert
        rockTypeId
        loadCapacity
        overlayDrawingId
        __typename
      }
      ttl
      driveStatus
      spot {
        id
        spottypeid
        name
        value
        voicetext
        iconpath
        range
        spotType
        x
        y
        geofence
        shapeType
        congestionFlag
        createdNumber
        __typename
      }
      geofenceLog {
        EventType
        GeofenceId
        DeviceId
        SampleTime
        longitude
        latitude
        __typename
      }
      drivesetting {
        driveSettingId
        projectId
        planId
        volumeId
        pouringPosition
        projectType
        __typename
      }
      notice {
        id
        noticeType
        title
        content
        argment
        completedPush
        __typename
      }
      noticeSegment {
        id
        __typename
      }
      delivery {
        deliveryId
        vehicleId
        startTime
        endTime
        capacity
        deliveryPhoto
        __typename
      }
      acceptance {
        acceptanceId
        inspectionDateTime
        slump
        chlorideQuantity
        airVolume
        ct
        at
        photos {
          photoType
          photoName
          __typename
        }
        memo
        updateEditor
        updateUserID
        updateDate
        weather
        __typename
      }
      route {
        routeId
        routeGeometry
        routeDistance
        roadType
        __typename
      }
      spottype {
        spottypeId
        name
        voicetext
        iconpath
        spotType
        order
        __typename
      }
      intervalsetting {
        delayTime
        dwellTime
        __typename
      }
      judgetraffic {
        judgetrafficeId
        vehicleId
        roadType
        passageTime
        __typename
      }
      mileage {
        driveId
        vehicleId
        maxCapacity
        totalMileage
        suddenStartCount
        suddenStopCount
        speedViolationCount
        dayAt00h
        dayAt01h
        dayAt02h
        dayAt03h
        dayAt04h
        dayAt05h
        dayAt06h
        dayAt07h
        dayAt08h
        dayAt09h
        dayAt10h
        dayAt11h
        dayAt12h
        dayAt13h
        dayAt14h
        dayAt15h
        dayAt16h
        dayAt17h
        dayAt18h
        dayAt19h
        dayAt20h
        dayAt21h
        dayAt22h
        dayAt23h
        __typename
      }
      co2setting {
        speedThreshold
        suddenStartValue
        suddenStopValue
        ecoDriveParam
        AClassCount
        BClassCount
        CClassCount
        AClassFuelParam
        BClassFuelParam
        CClassFuelParam
        fuelMileage1
        fuelMileage2
        fuelMileage3
        fuelMileage4
        fuelMileage5
        ArankFuelMemo
        BrankFuelMemo
        CrankFuelMemo
        fuelMileage1Memo
        fuelMileage2Memo
        fuelMileage3Memo
        fuelMileage4Memo
        fuelMileage5Memo
        __typename
      }
      rocktype {
        id
        name
        loadCapacity
        order
        __typename
      }
      deviceId {
        deviceId
        modelName
        lastUsedDate
        createDate
        deviceType
        userId
        __typename
      }
      overlayDrawing {
        id
        name
        path
        overlayTimeSettingId
        __typename
      }
      overlayTimeSetting {
        id
        name
        overlayTimeSets {
          itemNumber
          elapsedFrom
          elapsedTo
          colorId
          elapsedAlert
          __typename
        }
        __typename
      }
      overlayPoint {
        id
        pointName
        x
        y
        radius
        polygon
        shapeType
        __typename
      }
      overlay {
        id
        puringNumber
        puringStatus
        startTime
        endTime
        elapsedTime
        completeTime
        __typename
      }
      spotPassLog {
        id
        spotId
        passDate
        driveNumber
        roadType
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKonoikeConcreteData = /* GraphQL */ `
  mutation DeleteKonoikeConcreteData(
    $input: DeleteKonoikeConcreteDataInput!
    $condition: ModelKonoikeConcreteDataConditionInput
  ) {
    deleteKonoikeConcreteData(input: $input, condition: $condition) {
      id
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      groups
      createdUser
      updatedUser
      invalid
      tenant {
        tenantId
        name
        contractStart
        contractEnd
        status
        availableOptions
        email
        initUserId
        geofenceCollectionId
        memo
        useApps
        deviceNum
        enableDemo
        __typename
      }
      user {
        name
        userId
        usertype
        status
        period
        email
        deleted
        organizationId
        __typename
      }
      project {
        id
        name
        intervalMinutes
        users
        options {
          id
          label
          value
          __typename
        }
        numberAlert
        remainingTime
        projectType
        __typename
      }
      baseArea {
        id
        areaName
        areaType
        x
        y
        radius
        geofence
        shapeType
        __typename
      }
      location {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
        roadType
        __typename
      }
      lastLocation {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
        __typename
      }
      drive {
        id
        vehicleId
        driveNumber
        factoryStartTime
        siteEndTime
        pouringStartTime
        pouringEndTime
        siteStartTime
        factoryEndTime
        roadType
        endAreaId
        pouringPosition
        carryType
        maxCapacity
        pouringStartUser
        pouringEndUser
        congestionFlag
        congestionLog
        totalAmount
        startAreaId
        volumeId
        deliveryFlag
        unloadingTime
        unloadingUser
        rockTypeName
        loadCapacity
        __typename
      }
      vehicle {
        id
        name
        maxCapacity
        memo
        order
        selected
        numberPlate
        __typename
      }
      plan {
        id
        createDate
        temperature
        __typename
      }
      volume {
        id
        startAreaId
        endAreaId
        pouringPosition
        totalVolume
        grade
        settingAlert
        numberAlert
        rockTypeId
        loadCapacity
        overlayDrawingId
        __typename
      }
      ttl
      driveStatus
      spot {
        id
        spottypeid
        name
        value
        voicetext
        iconpath
        range
        spotType
        x
        y
        geofence
        shapeType
        congestionFlag
        createdNumber
        __typename
      }
      geofenceLog {
        EventType
        GeofenceId
        DeviceId
        SampleTime
        longitude
        latitude
        __typename
      }
      drivesetting {
        driveSettingId
        projectId
        planId
        volumeId
        pouringPosition
        projectType
        __typename
      }
      notice {
        id
        noticeType
        title
        content
        argment
        completedPush
        __typename
      }
      noticeSegment {
        id
        __typename
      }
      delivery {
        deliveryId
        vehicleId
        startTime
        endTime
        capacity
        deliveryPhoto
        __typename
      }
      acceptance {
        acceptanceId
        inspectionDateTime
        slump
        chlorideQuantity
        airVolume
        ct
        at
        photos {
          photoType
          photoName
          __typename
        }
        memo
        updateEditor
        updateUserID
        updateDate
        weather
        __typename
      }
      route {
        routeId
        routeGeometry
        routeDistance
        roadType
        __typename
      }
      spottype {
        spottypeId
        name
        voicetext
        iconpath
        spotType
        order
        __typename
      }
      intervalsetting {
        delayTime
        dwellTime
        __typename
      }
      judgetraffic {
        judgetrafficeId
        vehicleId
        roadType
        passageTime
        __typename
      }
      mileage {
        driveId
        vehicleId
        maxCapacity
        totalMileage
        suddenStartCount
        suddenStopCount
        speedViolationCount
        dayAt00h
        dayAt01h
        dayAt02h
        dayAt03h
        dayAt04h
        dayAt05h
        dayAt06h
        dayAt07h
        dayAt08h
        dayAt09h
        dayAt10h
        dayAt11h
        dayAt12h
        dayAt13h
        dayAt14h
        dayAt15h
        dayAt16h
        dayAt17h
        dayAt18h
        dayAt19h
        dayAt20h
        dayAt21h
        dayAt22h
        dayAt23h
        __typename
      }
      co2setting {
        speedThreshold
        suddenStartValue
        suddenStopValue
        ecoDriveParam
        AClassCount
        BClassCount
        CClassCount
        AClassFuelParam
        BClassFuelParam
        CClassFuelParam
        fuelMileage1
        fuelMileage2
        fuelMileage3
        fuelMileage4
        fuelMileage5
        ArankFuelMemo
        BrankFuelMemo
        CrankFuelMemo
        fuelMileage1Memo
        fuelMileage2Memo
        fuelMileage3Memo
        fuelMileage4Memo
        fuelMileage5Memo
        __typename
      }
      rocktype {
        id
        name
        loadCapacity
        order
        __typename
      }
      deviceId {
        deviceId
        modelName
        lastUsedDate
        createDate
        deviceType
        userId
        __typename
      }
      overlayDrawing {
        id
        name
        path
        overlayTimeSettingId
        __typename
      }
      overlayTimeSetting {
        id
        name
        overlayTimeSets {
          itemNumber
          elapsedFrom
          elapsedTo
          colorId
          elapsedAlert
          __typename
        }
        __typename
      }
      overlayPoint {
        id
        pointName
        x
        y
        radius
        polygon
        shapeType
        __typename
      }
      overlay {
        id
        puringNumber
        puringStatus
        startTime
        endTime
        elapsedTime
        completeTime
        __typename
      }
      spotPassLog {
        id
        spotId
        passDate
        driveNumber
        roadType
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKonoikeServiceSetting = /* GraphQL */ `
  mutation CreateKonoikeServiceSetting(
    $input: CreateKonoikeServiceSettingInput!
    $condition: ModelKonoikeServiceSettingConditionInput
  ) {
    createKonoikeServiceSetting(input: $input, condition: $condition) {
      id
      iosMinVersion
      iosStoreUrl
      isMaintenance
      maintenanceText
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKonoikeServiceSetting = /* GraphQL */ `
  mutation UpdateKonoikeServiceSetting(
    $input: UpdateKonoikeServiceSettingInput!
    $condition: ModelKonoikeServiceSettingConditionInput
  ) {
    updateKonoikeServiceSetting(input: $input, condition: $condition) {
      id
      iosMinVersion
      iosStoreUrl
      isMaintenance
      maintenanceText
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKonoikeServiceSetting = /* GraphQL */ `
  mutation DeleteKonoikeServiceSetting(
    $input: DeleteKonoikeServiceSettingInput!
    $condition: ModelKonoikeServiceSettingConditionInput
  ) {
    deleteKonoikeServiceSetting(input: $input, condition: $condition) {
      id
      iosMinVersion
      iosStoreUrl
      isMaintenance
      maintenanceText
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKonoikeSetting = /* GraphQL */ `
  mutation CreateKonoikeSetting(
    $input: CreateKonoikeSettingInput!
    $condition: ModelKonoikeSettingConditionInput
  ) {
    createKonoikeSetting(input: $input, condition: $condition) {
      id
      tenantArn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKonoikeSetting = /* GraphQL */ `
  mutation UpdateKonoikeSetting(
    $input: UpdateKonoikeSettingInput!
    $condition: ModelKonoikeSettingConditionInput
  ) {
    updateKonoikeSetting(input: $input, condition: $condition) {
      id
      tenantArn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKonoikeSetting = /* GraphQL */ `
  mutation DeleteKonoikeSetting(
    $input: DeleteKonoikeSettingInput!
    $condition: ModelKonoikeSettingConditionInput
  ) {
    deleteKonoikeSetting(input: $input, condition: $condition) {
      id
      tenantArn
      createdAt
      updatedAt
      __typename
    }
  }
`;

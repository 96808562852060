<template>
  <div ref="mapContainer" class="map-container" stlye="position:relative;">
    <div id="map" class="map" :style="mapStyle">
    </div>
  </div>
</template>

<style scoped>
/** このVueだけのスタイル */
.map-container {
  width: 100%;
  height: 100%;
  /* border:1px solid green; */
}

.map {
  /* width: 500px;
    height: 500px; */

  width: 100%;
  height: 100%;

  /* border:1px solid blue; */
}

.leaflet-grab {
  cursor: hand;
}

.leaflet-dragging .leaflet-grab {
  cursor: grabbing;
}

.overmap-bottom-right {
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 32px;
  height: 32px;
  padding: 0px 0px 0px 0px;
  z-index: 1001;
}

/* :deep .leaflet-interactive {
} */
</style>
<style>
/** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import DateUtil from "@/appUtils/DateUtil";
import xss from 'xss';
import TenantStorage from "@/appUtils/TenantStorage"
import UserInfo from '../../appUtils/UserInfo.js';
import OverlayModel from "../../appModel/Overlay/OverlayModel.js";
import OverlayTimeSettingModel from "../../appModel/Overlay/OverlayTimeSettingModel.js";

const path = require("path")
// // data()内に保持するとLeafletの挙動がおかしくなります。
// let mlMap = null;

export default {
  components: {},
  data() {
    return {
      tenantId: null,
      projectId: null,
      imgFileInfo: {
        ext: "",
        url: "",
      },
      overlayDrawingId: null,
      overlayPointList: [], // 打ち重ねポイント情報 by DB
      overlayTimeSets: [], // 経過時間Set by DB
      latlngList: [],
      overlayTypeLayerName: [], // LayerName
      overlayListbyDrawing: [], // DrawingIDにあるうち重ね情報
      overlayInfo: [], // 初期重ね情報重ね情報
      overlayAreaList: [],
      blinkingStates: {},
      isblinking: {},
      intervalIds: {},
      intervalTime: 500,
      currentTime: '',
      showPopup: true,
      blinkingTimer: null,
      elapsedTimer: null,
      popupObj: null,
      popupEndTime: null,
      statusMap: {
        0: '打設未実施',
        1: '打設中',
        2: '打設完了',
        3: '全打設完了',
        9: '打設中止'
      },
      maxTime: "2999-12-31 23:59:59:999",
      defaultColor: "#585858",
      completeColor: "#FFFFFF",
      filePath: '',
      workingLayerId: null,
      workingData: null,
      isClicked: false,
    };

  },
  mixins: [ValidSession, UseApps, Logger],
  computed: {
    vuename() {
      return "OverlayPointVueMap.vue";
    },
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  async created() {
    // インスタンスが生成され､且つデータが初期化された後
    // MLMap
    // data()内に保持するとLeafletの挙動がおかしくなります。
    this.mlMap = null;

    // 地図コンテナリサイズ監視オブザーバー
    this.resizeObserver = null;

    // ジオフェンス描画スタイル
    this.geofenceStyle = {
      // color: "#B71C1C",
      color: "#E65100",
      weight: 2.5,
      fill: true,
      // fillColor: "#B71C1C",
      fillColor: "#E65100",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    // 打ち重ねポイント描画スタイル
    this.overlayStyle = {
      color: "#585858",
      weight: 2.5,
      fill: true,
      fillColor: "#585858",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
      radius: 5,
    };

    // 打ち重ねポイント間隔ポイントポリゴンスタイル
    this.intervaloverlayStyle = {
      color: "#585858",
      weight: 2.5,
      fill: true,
      fillColor: "#585858",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    this.poiStyle = {
      iconUrl: "./img/concrete/ic_circle_grey.png",
      iconSize: [38, 38],
      iconOffset: [-19, -38],
      interactive: false,
    };

    this.poiLabelStyle = {
      color: '#FFFFFF',
      weight: 4,
      fillColor: '#263238',
      fontSize: 12,
      textBaseline: "middle",
    };
  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  async mounted() {
    // インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`);

    // 地図コンテナのリサイズ監視開始
    this.addResizeObserver();

    // 初回表示時に現在時刻を設定
    this.updateTime();
    this.checkOverlayInfo();
    // 1秒ごとに時刻を更新
    this.blinkingTimer = setInterval(this.updateTime, 1000);
    // 1分ごとにチェック
    this.elapsedTimer = setInterval(this.checkOverlayInfo, 1000);
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前

    // 地図コンテナのリサイズ監視終了
    this.removeResizeObserver();

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // コンポーネントが破棄される前にタイマーをクリア
    clearInterval(this.blinkingTimer);
    clearInterval(this.elapsedTimer);
  },

  unmounted() {
    // Vue インスタンスが破壊された後

    // マップリソース解放
    if (this.mlMap) {
      this.mlMap.off("click", this.onMapClick);
      this.mlMap.remove();
    }
  },
  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 地図コンテナのリサイズ監視を開始します。
     */
    addResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナのリサイズ監視を終了します。
     */
    removeResizeObserver() {
      this.resizeObserver.unobserve(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナリサイズイベント
     */
    onResize() {
      // console.log(`onResize`);

      // Leafletの地図領域を更新
      if (this.mlMap) {
        this.mlMap.invalidateSize();
      }
    },

    /**
     * 地図の作成
     * 
     * @param {string} tenantId 
     * @param {string} overlayDrawingId 
     * @param {string} filePath 
     */
    async mapCreate(tenantId, overlayDrawingId, filePath) {
      this.tenantId = tenantId;
      this.overlayDrawingId = overlayDrawingId;
      this.filePath = filePath;

      await this.initdata()

      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // レイヤ追加
      let bounds = [];
      bounds = await this.addLayers();
      // コントロール
      this.mlMap.setMapInfoControlVisible(true);

      this.mlMap.fitBounds(bounds);

      // イベント
      this.initMapEvent();
      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();
      
      // ローカルストレージから地図の状態を取得
      this.restoreMapZoom();
    },

    /**
     * 基本情報初期化
     */
    async initdata() {
      try {
        const user = await UserInfo.getUserInfo();
        const tenantStorage = new TenantStorage(user.group);

        this.projectId = this.$store.state.plan.projectid;
        this.imgFileInfo.ext = path.extname(this.filePath).toLowerCase();
        this.imgFileInfo.url = (await tenantStorage.get(this.filePath)).toBase64();
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e));
        throw e;
      }
    },

    /**
     * レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addLayers() {
      // 初期表示領域
      let bounds = [];
      // 背景レイヤの追加
      bounds = await this.addBaseLayers();
      // 作図レイヤ追加
      this.mlMap.addShapeLayer('作図（お絵描き）');

      return bounds;
    },

    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {
      // クリック
      this.mlMap.on("click", this.onMapClick);
      this.mlMap.on('moveend', this.setMapZoom);
    },

    /**
     * ローカルストレージから地図の状態を格納
     */
    setMapZoom() {
      const mapState = {
        center: this.mlMap.getCenter(),
        zoom: this.mlMap.getZoom(),
      };
      localStorage.setItem('mapState', JSON.stringify(mapState));
    },

    /**
     * ローカルストレージから地図の状態を取得
     */
    restoreMapZoom() {
      let storedMapState = localStorage.getItem('mapState');
      // console.log("storedMapState; ", storedMapState);
      if (storedMapState) {
        storedMapState = JSON.parse(storedMapState);
        this.mlMap.setView(storedMapState.center, storedMapState.zoom); // 中心とズームレベルを復元
      } 
    },

    /**
     * 背景レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addBaseLayers() {
      let bounds = [];
      if (this.imgFileInfo.ext == ".pdf") {
        bounds =this.addPdfLayer();
      } else {
        bounds =this.addImageLayer();
      }

      return bounds;
    },

    /**
     * ビュータイプを図面表示用に設定してMLMapを生成
     */
    initMlmap() {
      if (this.mlMap) {
        this.mlMap = null;
        const mapContainer = document.getElementById('map');
        const newMapContainer = document.createElement('div');

        mapContainer.parentNode.removeChild(mapContainer);        
        newMapContainer.id = 'map';
        newMapContainer.style.width= '100%';
        newMapContainer.style.height= '100%';

        this.$refs.mapContainer.appendChild(newMapContainer);
      }
      this.mlMap = new MLMap('map', MLMap.VIEW_TYPE.NON_GEOGRAPHICAL);
    },

    /**
     * 図面画像レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addImageLayer() {
      // ビュータイプを図面表示用に設定してMLMapを生成
      this.initMlmap();

      if (!this.imgFileInfo.url) {
        this.debugLog('背景図Base64未設定');
        return;
      }

      // PDFではない場合は分割する必要なし
      const base64 = this.imgFileInfo.url;
      // 図面情報
      const imageInfo = await MLImageUtil.getImageInfo(base64);
      this.debugLog(imageInfo);

      // レイヤ追加
      this.mlMap.addImageLayer("ImageName", base64, imageInfo.width, imageInfo.height);

      // 画像全体を表示
      let bounds = L.latLngBounds([0, 0], [imageInfo.height, imageInfo.width]);

      // スクロール制限
      this.mlMap.setMaxBounds(bounds.pad(0.75));

      return bounds;
    },

    /**
     * 図面PDFレイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addPdfLayer() {
      // PDFを表示する場合はPDFにアクセスする前に必ず設定
      MLPdfUtil.setPdfJsWorkerUrl('./maplet-leaflet/external/pdfjs-2.10.377/pdf.worker.js');

      // ビュータイプを図面表示用に設定してMLMapを生成
      this.initMlmap();

      if (!this.imgFileInfo.url) {
        this.debugLog('指定されたURLにPDFファイルが存在しません。');
        return;
      }

      // '識別文字','PDF実データ' の構成になっているので、実データのみを取得
      const pdfBase64 = this.imgFileInfo.url.split(',')[1];
      // PDF情報
      const doc = await MLPdfUtil.getDocumentInfoByBase64(pdfBase64);
      const page = await MLPdfUtil.getPageInfo(doc, 1);

      // レイヤ追加
      this.mlMap.addPdfLayer("pdfname", {
        pdfData: pdfBase64,
        page: 1,
        cMapUrl: './maplet-leaflet/external/pdfjs-2.10.377/cmaps',
      });

      // PDF全体を表示
      let bounds = L.latLngBounds([0, 0], [page.height, page.width]);

      // スクロール制限
      this.mlMap.setMaxBounds(bounds.pad(0.75));

      return bounds;
    },

    /**
     * 打ち重ねポイント描画
     * 
     * @param {object} parameters 
     * @param {string} overlayTimeSettingId 
     */
    async drawoverlay(parameters, overlayTimeSettingId) {
      try {
        this.overlayPointList = parameters;
        this.overlayTimeSets = await this.getOverlayTimeSets(overlayTimeSettingId);

        for (let overlay of parameters) {
          const { x, y, id, shapeType, polygon, radius, pointName } = overlay;

          // ジオフェンスの作図
          let compressCoordsText;
          // 図形タイプがポリゴンの場合は、DB値をそのまま使用
          if (shapeType == "1") {
            compressCoordsText = polygon;
          } else {
            this.overlayStyle.radius = radius;
            // 中心座標、半径から、ポリゴン座標を取得
            const latlngs = CoordinateUtil.getCirclePolygonPoints(
              new L.LatLng(y, x),
              radius
            );
            // ポリゴン座標をmlMap圧縮文字列にする
            compressCoordsText = MLCompressUtil.latLngArrayToBase64(latlngs);
          }
          // addShape
          this.addShape(overlay, compressCoordsText, { id: id });

          // -------------------------
          // アイコンの作図
          this.addPoiLayers(pointName);
          // アイコン図形をプロットを追加
          this.mlMap.addPoi(pointName, {
            id: id,
            x: x,
            y: y,
            label: pointName,
            layerName: pointName,
          });

          this.latlngList.push([y, x]);
          this.overlayTypeLayerName.push(pointName);
        }

        // オーバーレイ情報を更新
        await this.getOverlayInfo();
        this.getOverlayInfoWork();
        // マップのZOrderを更新
        this.mlMap.refreshZOrder();
      } catch (e) {
        this.errorLog("drawoverlay", this.parseErrorObject(e));
      }
    },

    /**
     * Shape追加
     * 
     * @param {Object} overlay - オーバーレイ情報
     * @param {string} pCoords - 圧縮座標
     * @param {Object} pItem - shapeItem
     * @param {string} [pColor=this.defaultColor] - 図形の塗りつぶし色
     */
    addShape(overlay, pCoords, pItem, pColor=this.defaultColor) {
      const { shapeType, pointName, id } = overlay;
      let type, style;
      if (shapeType === "1") {
        type = MLMap.SHAPE_TYPE.POLYGON;
        style = JSON.parse(JSON.stringify(this.intervaloverlayStyle));
      } else {
        type = MLMap.SHAPE_TYPE.CIRCLE;
        style = JSON.parse(JSON.stringify(this.overlayStyle));
      }
      style.fillColor = pColor;
      if (pColor == this.completeColor) {
        style.fillOpacity = 0.75;
      }

      this.overlayAreaList.push({
        type: shapeType,
        id: id,
        Coords: pCoords,
        style: style,
        layerName: pointName,
      });

      this.mlMap.addShape(type, pCoords, pItem, style);
    },

    /**
     * 検査箇所レイヤ群を追加します。
     * @param {string} layerName
     */
    addPoiLayers(layerName) {
      // 拠点アイコンレイヤ追加
      this.mlMap.addPoiLayer(xss(layerName), this.poiStyle, this.poiLabelStyle);
    },

    /**
     * 打ち重ね情報を取得し格納
     * Layer別打ち重ね情報
     */
    async getOverlayInfo(){
      try {
        await this.getOverlayList();

        this.overlayInfo = this.overlayPointList.map(point => {
          const layerId = point.id;

          // 該当するoverlayを抽出
          const relatedOverlays = this.overlayListbyDrawing
            .filter(overlay => overlay.sk.split('#')[3] === layerId)
            .map(overlay => overlay.overlay);

          const sortedInfo = relatedOverlays.length > 0 ? this.sortData(relatedOverlays) : [];

          return {
            LayerName: point.pointName,
            shapeType: point.shapeType,
            id: layerId,
            info: sortedInfo,
            status: sortedInfo.length > 0 ? sortedInfo[sortedInfo.length - 1].puringStatus : 0,
            endTime: sortedInfo.length > 0 ? sortedInfo[sortedInfo.length - 1].endTime : null,
            x: point.x,
            y: point.y,
          };
        });
      } catch (e) {
        this.errorLog("getOverlayInfo", this.parseErrorObject(e));
      }
    },

    /**
     * 格納された打ち重ね情報を元に現在までの作業内容を描く
     */
    async getOverlayInfoWork(){
      try {
        for (let i = 0; i < this.overlayInfo.length; i++) {
          let layer = this.overlayInfo[i];

          // 作業中のもの表示
          if (layer.status === 1) {
            const poiInfo = [{
              id: layer.id,
              x: layer.x,
              y: layer.y,
              label: layer.LayerName,
              layerName: layer.LayerName
            }]
            // blinking作動開始
            this.onBlinkingPoi(poiInfo);
          } else if (layer.status === 2) {
            // 経過時間による背景色変更
            this.setLayerFillColor(layer);
          } else if (layer.status === 3) {
            this.layerFillColor(layer.id, this.completeColor);
          }
        }
      } catch (e) {
        this.errorLog("getOverlayInfoWork", this.parseErrorObject(e));
      }
    },

    /**
     * 経過時間による背景色変更
     * 
     * @param {string} layer レイヤー情報
     * @param {null} [color=null] 背景色
     */
    async initLayerFillColor(layer, color=null) {
      const searchId = layer.sk.split("#")[4];
      const data = this.overlayInfo;
      const targetLayer = data.find(layer => layer.info.some(info => info.id === searchId));
      // 背景色変更
      await this.layerFillColor(targetLayer.id, color);
    },

    /**
     * 経過時間による背景色変更
     * 
     * @param {string} layer レイヤー情報
     */
    async setLayerFillColor(layer) {
      const { endTime, id } = layer;
      const elapsedTime = this.calcElapsedTime(endTime);
      const color = this.getColorIdForTime(elapsedTime[1]);
      // 経過時間更新
      await this.updateRecodeElapsedTime(layer, elapsedTime);
      // 背景色変更
      await this.layerFillColor(id, color);
    },

    /**
     * 経過時間による背景色変更
     * 
     * @param {string} layer レイヤーID
     * @param {null} [color=null] 背景色
     */
    async layerFillColor(layerId, color=null) {
      // console.log(`layerFillColor - layerId: ${layerId} - color: ${color}`);
      this.changeLayerFillColor(layerId, color);
      this.mlMap.removeShapeById(layerId);
    },

    /**
     * 指定されたレイヤーの塗りつぶし色を変更
     * 
     * @param {string} layerId
     * @param {string} fillColor
     */
    changeLayerFillColor(layerId, fillColor) {
      const layerIndex = this.overlayAreaList.findIndex((layer) => layer.id === layerId);
      fillColor = layerIndex === -1 ? this.defaultColor : fillColor;

      const { type, id, pointName, Coords } = this.overlayAreaList[layerIndex];
      const changeData = { shapeType: type, id, pointName };

      this.overlayAreaList.splice(layerIndex, 1);
      this.addShape(changeData, Coords, { id }, fillColor);
      this.mlMap.refreshZOrder();
    },

    /**
     * 経過時間計算
     * 
     * @param {string} endTime
     * @returns {[string, number]} [hh:mm形式の時間, 分単位の経過時間]
     */
    calcElapsedTime(endTime) {
      const hhmm = DateUtil.getDifferentialTime(endTime, this.getDateString());
      const [hours, minutes] = hhmm.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes;

      return [hhmm, totalMinutes];
    },

    /**
     * 経過時間に基づく色IDの取得
     * 
     * @param {number} minutes
     * @returns {string|null} 該当するcolorIdまたはnull
     */
    getColorIdForTime(minutes) {
      minutes += 0.1;
      // 経過時間が設定された範囲に該当するcolorIdを探す
      const settings = this.overlayTimeSets.overlayTimeSetting.overlayTimeSets;
      const matchedSetting = settings.find(({ elapsedFrom, elapsedTo }) =>
        elapsedTo === null && minutes > elapsedFrom ||
        elapsedTo !== null && minutes > elapsedFrom && minutes <= elapsedTo
      );

      // 該当する設定があれば colorId を返す
      if (matchedSetting) {
        return matchedSetting.colorId;
      }

      return null;
    },

    /**
     * 図面IDに関係ある情報を取得
     */
    async getOverlayList() {
      this.overlayListbyDrawing = await OverlayModel.getOverlayListByDrawing(
        this.tenantId, this.projectId, this.overlayDrawingId
      );
    },

    /**
     * 図面IDに関係ある経過時間情報を取得
     * 
     * @param {string} overlayTimeSettingId 経過時間設定Id
     */
    async getOverlayTimeSets(overlayTimeSettingId) {
      return await OverlayTimeSettingModel.getOverlayTimeSetting(
        this.tenantId, overlayTimeSettingId
      );
    },

    /**
     * 打ち重ね情報を時間順にソート
     * @param {object} data 打ち重ね情報
     * @returns {data}  
     */
    sortData(data) {
      data.sort((a, b) => {
        const endTimeA = a.endTime === "" ? this.maxTime : a.endTime;
        const endTimeB = b.endTime === "" ? this.maxTime : b.endTime;
        // Dateオブジェクトに変換して比較
        return new Date(endTimeA) - new Date(endTimeB);
      });

      return data;
    },

    /**
     * 経過時間を確認、時間経過に伴い自動更新
     * overlayInfoを確認し、必要ならsetLayerFillColorを呼び出す
     */
    checkOverlayInfo() {
      this.overlayInfo.forEach((layer) => {
        if (this.hasOverlayChanged(layer)) {
          this.setLayerFillColor(layer, true)
        }
      });
    },

    /**
     * * overlayInfoの変化を検知するロジック
     * 
     * @param {object} layer overlayInfoのLayer情報
     * @returns {bool}
     */
    hasOverlayChanged(layer) {
      if (layer.status === 2 && layer.endTime) {
        return true;
      }
      return false;
    },

    /**
     * 地図クリックイベント
     * 
     * @param {Object} e イベントデータ
     */
    onMapClick(e) {
      if (this.isClicked) {
        console.log("2回目Click!!")
        return; 
      }
      this.isClicked = true;

      // どこをクリックしたか判別
      const clickLayer = this.mlMap.getShapeByLatLng(e.latlng);
      const poiInfo = this.getPoiItems(e);

      if (clickLayer.length === 0) {
        // 座標にObjectが存在しない
        this.closePopup();
        this.isClicked = false;
        return; 
      }

      if (poiInfo.length > 0) {
        this.onClickPoi(clickLayer[0].id, poiInfo, e.latlng);
      } else {
        this.onClickLayer(clickLayer[0].id, e.latlng);
      }
      this.isClicked = false;
    },

    /**
     * Poiクリックイベント
     * 
     * @param {string} layerId LayerID
     * @param {Object} poiInfo Poi情報
     * @param {Object} latlng クリック座標
     */
    async onClickPoi(layerId, poiInfo, latlng){
      const selectedLayerInfo = await this.overlayDataBranch(layerId);

      // ステータスが全打設完了の場合
      if (selectedLayerInfo.overlay.puringStatus == 3) {
        this.showLayerInfoPopup(latlng, layerId, selectedLayerInfo);
        return;
      }
      // ステータス変更
      await this.changePoiStatus(selectedLayerInfo);
      await this.getOverlayInfo();
      // 点滅制御
      this.onBlinkingPoi(poiInfo);
    },

    /**
     * Layerクリックイベント
     * 
     * @param {str} layerId Layer ID
     * @param {object} latlng クリック座標
     */
    async onClickLayer(layerId, latlng){
      // 状態表示
      const selectedLayerInfo = await this.overlayDataBranch(layerId);
      // 吹き出し表示
      this.showLayerInfoPopup(latlng, layerId, selectedLayerInfo);
    },

    /**
     * クリックLayerに関連データの存在を確認し、処理を分岐
     * 
     * @param overlayPointId
     * @returns {data} クリックLayerに関連データ
     */
    async overlayDataBranch(overlayPointId) {
      try {
        const data = await this.fetchDataFromDB(overlayPointId);

        if (data.length > 0) {
          const maxPuringNumberData = data.reduce((max, current) => {
            return current.overlay.puringNumber > max.overlay.puringNumber ? current : max;
          });
          return maxPuringNumberData;
        } else {
          const newData = await this.onInsertOverlay(overlayPointId);
          return newData;
        }
      } catch (e) {
        this.errorLog("overlayDataBranch", this.parseErrorObject(e));
      }
    },

    /**
     * 日付データ修得
     * 
     * @returns {string} 'YYYY-MM-DD HH:mm:ss:SSS'
     */
    getDateString() {
      return DateUtil.getDateStringDateTimeMilli();
    },

    /**
     * Poi状態をDB更新
     * 
     * @param selectedLayerInfo
     * @param isContinue
     */
    async changePoiStatus(selectedLayerInfo, isContinue=true) {
      const overlay = selectedLayerInfo.overlay;
      const nowDateString = this.getDateString();
      let color = this.defaultColor;
      let isNewRecord = false;
      // 0 -> 1 -> 2 -> 3
      //      ↑ <- ↓
      switch (overlay.puringStatus) {
        case 0:
          // console.log("打設未実施 -> 打設中");
          overlay.puringStatus += 1;
          overlay.puringNumber += 1;
          overlay.startTime = nowDateString;
          break;
        case 1:
          // console.log("打設中 -> 打設完了");
          overlay.puringStatus += 1;
          overlay.endTime = nowDateString;
          break;
        case 2:
          if (isContinue) {
            // console.log("打設完了 -> 打設中");
            overlay.puringStatus -= 1;
            overlay.puringNumber += 1;
            isNewRecord = true;
          } else {
            // console.log("打設完了 -> 全打設完了");
            overlay.puringStatus += 1;
            overlay.completeTime = nowDateString;
            color = this.completeColor;
          }
          break;
      }
      this.initLayerFillColor(selectedLayerInfo, color);

      // DynamoDB反映parameters
      if (isNewRecord) {
        await this.onInsertOverlay(selectedLayerInfo.sk.split("#")[3], overlay.puringNumber, overlay.puringStatus, overlay.startTime);
      } else {
        await this.updateRecode(selectedLayerInfo);
      }
    },

    /**
     * 経過時間更新
     * 
     * @param {object} layer
     * @param {int} elapsedTime
     */
    async updateRecodeElapsedTime(layer, elapsedTime=null) {
      const record = layer.info.find(item => item.endTime === layer.endTime);
      if (!record) {
        this.errorLog("updateRecodeElapsedTime", "該当レコードを見つけられなかった。");
        return;
      }

      const data = await this.fetchRecodeFromDB(layer.id, record.id);
      const calculatedElapsedTime = elapsedTime || this.calcElapsedTime(layer.endTime);
      data.overlay.elapsedTime = calculatedElapsedTime[1];
      await this.updateRecode(data);
    },

    /**
     * 経過時間更新
     * 
     * @param {object} data
     */
    async updateRecode(data) {
      await OverlayModel.updateOverlay(data);
    },

    /**
     * 打ち重ね情報レコード
     * 
     * @param {string} overlayPointId 打ち重ねポイントID
     * @param {string} overlayId 打ち重ね情報Id
     * @returns {data} 打ち重ね情報レコード
     */
    async fetchRecodeFromDB(overlayPointId, overlayId) {
      try {
        const data = await OverlayModel.getOverlay(
          this.tenantId,
          this.projectId,
          this.overlayDrawingId,
          overlayPointId,
          overlayId
        );

        return data;
      } catch (e) {
        this.errorLog("fetchDataFromDB", this.parseErrorObject(e));
      }
    },

    /**
     * 打ち重ね情報リストを取得する（ポイント単位）
     * 
     * @param {string} overlayPointId 打ち重ねポイントID
     * @returns {data} 打ち重ね情報レコードリスト
     */
    async fetchDataFromDB(overlayPointId) {
      try {
        const data = await OverlayModel.getOverlayListByPoint(
          this.tenantId,
          this.projectId,
          this.overlayDrawingId,
          overlayPointId
        );

        return data;
      } catch (e) {
        this.errorLog("fetchDataFromDB", this.parseErrorObject(e));
      }
    },

    /**
     * 打ち重ね情報レコードを追加する
     * 
     * @param overlayPointId
     * @param num
     * @param status
     * @param startTime
     * @returns {data} 打ち重ね情報レコード
     */
    async onInsertOverlay(overlayPointId, num=0, status=0, startTime="") {
      const newData = await OverlayModel.newOverlay(
        this.projectId, this.overlayDrawingId, overlayPointId, num, status, startTime
      );

      return newData;
    },

    /**
     * ステータス名取得
     * 
     * @param code
     * @returns {string} ステータス名
     */
    getStatusName(code) {
      return this.statusMap[code] || '-';
    },

    /**
     * Layer 情報POPUP
     * 
     * @param latlng
     * @param layerId
     * @param data
     */
    showLayerInfoPopup(latlng, layerId, data) {
      let popupContent = this.makePopupContent(layerId, data.overlay, this.currentTime);
      let popup = L.popup()
        .setLatLng(latlng)
        .setContent(popupContent)
        .openOn(this.mlMap.map);

      this.popupObj = popup;
      this.popupEndTime = data.overlay.endTime === "" ? "-" : data.overlay.endTime;
      const popupElement = popup.getElement();
      const closeButton = popupElement.querySelector('a.leaflet-popup-close-button');
      this.setPopupStyle(popupElement.querySelector('.leaflet-popup-content-wrapper'));
      this.setPopupStyle(popupElement.querySelector('.leaflet-popup-tip'));
      this.setPopupStyle(closeButton);
      this.workingLayerId = layerId;
      this.workingData = data;

      setTimeout(() => {
        const btn = document.getElementById(`btn-${layerId}`);
        if (btn) {
          btn.addEventListener("click", this.onCompleteWork.bind(this));
        }
      }, 0);

      if (closeButton) {
        closeButton.addEventListener('click', (event) => {
          event.preventDefault();
          this.closePopup();
        });
      }
    },

    /**
     * Layer 情報POPUPのstyle
     * 
     * @param element
     * @param backgroundColor
     * @param color
     */
    setPopupStyle(element, backgroundColor='black', color='white') {
      element.style.backgroundColor = backgroundColor;
      element.style.color = color;
    },

    /**
     * Layer情報POPUPのhtml
     * 
     * @param layerId
     * @param data
     * @param pNow
     * @returns {object} POPUP用html
     */
    makePopupContent(layerId, data, pNow) {
      const num = data.puringNumber || 0;
      const status = data.puringStatus || 0;
      const statusName = this.getStatusName(status);
      const startTime = data.startTime === "" ? "-" : DateUtil.getFormatString(data.startTime, "HH:mm");
      const endTime = data.endTime === "" ? "-" : DateUtil.getFormatString(data.endTime, "HH:mm");

      // HTML生成部分
      let container = document.createElement("div");
      container.innerHTML += `打設回数：${num}<br/>`;
      container.innerHTML += `ステータス：${statusName}<br/>`;
      container.innerHTML += `打設開始：${startTime}<br/>`;
      container.innerHTML += `打設完了：${endTime}<br/>`;
      if (status === 2) {
        container.innerHTML += `経過時間：<span id="lapsedHM">計算中…</span><br/>`;
      } else {
        container.innerHTML += `経過時間：-<br/>`;
      }
      if (status === 3) {
        const completeTime = data.completeTime === "" ? "-" : DateUtil.getFormatString(data.completeTime, "HH:mm");
        container.innerHTML += `全打設完了時刻：${completeTime}<br/>`;
      }
      container.innerHTML += `現在時間：<span id="nowHMS">${pNow}</span><br/>`;
      if (status === 2) {
        let button = document.createElement("button");
        button.type = "button";
        button.id = `btn-${layerId}`;
        button.style = "display: block; margin: 0 auto;";
        button.textContent = "打ち重ね終了";
        container.appendChild(button);
      }

      return container.innerHTML;
    },

    /**
     * 全打設完了処理
     */
    async onCompleteWork() {
      const selectedLayerInfo = await this.overlayDataBranch(this.workingLayerId);
      await this.changePoiStatus(selectedLayerInfo, false);
      await this.getOverlayInfo();
      this.closePopup();
    },

    /**
     * Layer 情報POPUPの経過時間と現在時刻を更新
     */
    updateTimeInPopup() {
      const nowHMS = this.popupObj.getElement().querySelector('#nowHMS');
      const lapsedHM = this.popupObj.getElement().querySelector('#lapsedHM');
      const elapsedTime = this.calcElapsedTime(this.popupEndTime)[0];

      if (nowHMS) {
        nowHMS.innerText = this.currentTime;
      }
      if (lapsedHM) {
        lapsedHM.innerText = elapsedTime;
      }
    },

    /**
     * 現在の時刻を取得
     */
    updateTime() {
      this.currentTime = new Date().toLocaleTimeString();
      if (this.popupObj) {
        this.updateTimeInPopup(); // ポップアップ内のinputを更新
      }
    },

    /**
     * ポップアップを閉じるメソッド
     */
    closePopup() {
      if (this.popupObj) {
        this.popupObj.remove();
        this.popupObj = null;
        this.popupEndTime = null;
        this.workingLayerId = null;
        this.workingData = null;
      }
    },

    /**
     * 点滅の開始処理
     * 
     * @param layerName
     * @param poiItems
     */
    startBlinking(layerName, poiItems) {
      // layerNameの点滅状態を開始に設定
      this.blinkingStates[layerName] = true;
      this.isblinking[layerName] = true;
      this.intervalIds[layerName] = setInterval(() => {
        this.switchPoi(layerName, poiItems);
      }, this.intervalTime);
    },

    /**
     * 点滅の停止処理
     * 
     * @param layerName
     */
    stopBlinking(layerName) {
      if (!this.intervalIds[layerName]) {
        return;
      }
      this.blinkingStates[layerName] = false;
      this.isblinking[layerName] = false;
      clearInterval(this.intervalIds[layerName]);
      delete this.intervalIds[layerName];
      this.mlMap.clearSelectedPoi(layerName);
    },

    /**
     * ポイントの切り替え
     * 
     * @param layerName
     * @param poiItems
     */
    switchPoi(layerName, poiItems) {
      if (this.blinkingStates[layerName]) {
        this.mlMap.setSelectedPoi(layerName, poiItems);
      } else {
        this.mlMap.clearSelectedPoi(layerName);
      }

      // 点滅状態を反転
      this.blinkingStates[layerName] = !this.blinkingStates[layerName];
    },

    /**
     * layerNameで点滅処理をトグル
     * 
     * @param layerName
     * @param poiItems
     */
    onBlinkingPoi(poiItems) {
      const layerName = poiItems[0].layerName
      if (this.isblinking[layerName]) {
        this.stopBlinking(layerName); // 点滅停止処理を別メソッドに分ける
      } else {
        this.startBlinking(layerName, poiItems); // 点滅開始処理を別メソッドに分ける
      }
    },

    /**
     * クリック時のイベント引数から運行スポットPOIを取得します。
     *
     * @param {eventArg} e Map上のクリックイベント引数
     * @returns 運行スポットPOI
     */
    getPoiItems(e) {
      let items = [];

      for (let i = 0; i < this.overlayTypeLayerName.length; i++) {
        try {
          items = this.mlMap.getPoiByDomMouseEvent(
            this.overlayTypeLayerName[i],
            e.originalEvent
          );
        } catch (e) {
          if (e.message != "Cannot read properties of null (reading 'mouseEventToLayerPoint')") {
            this.errorLog("getPoiItems", this.parseErrorObject(e));
            throw e;
          }
        }

        if (items.length > 0) return items;
      }

      return items;
    },

    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },

    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },

    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e);
      }
    },
  },
};
</script>

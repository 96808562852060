const short = require("short-uuid");
const { API, graphqlOperation } = require("aws-amplify");
const {
  listKonoikeConcreteData,
  getKonoikeConcreteData,
} = require("@/graphql/queries");
const baseModel = require("../baseModel")

/**
 * 打ち重ね図面用のデータ取得モデルクラス
 */
class OverlayDrawingModel extends baseModel {
  /**
   * 打ち重ね図面リストを取得する
   * @returns
   */
  static async getOverlayDrawingList(tenantId, projectId) {
    const result = await API.graphql(
      graphqlOperation(listKonoikeConcreteData, {
        pk: `${tenantId}`,
        sk: { beginsWith: `overlayDrawing#${projectId}#` }
      })
    );

    if (result) {
      if (result.data) {
        return result.data.listKonoikeConcreteData.items;
      }
    }

    return [];
  }

  static async isExistOverlayTimeSettingId(tenantId, overlayTimeSettingID) {
    const result = await API.graphql(
      graphqlOperation(listKonoikeConcreteData, {
        pk: `${tenantId}`,
        sk: { beginsWith: `overlayDrawing#` }
      })
    );

    if (result) {
      if (result.data) {
        const items = result.data.listKonoikeConcreteData.items;
        const existIndex = items.findIndex(item => item.overlayDrawing.overlayTimeSettingId === overlayTimeSettingID);
        if (existIndex < 0) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * 打ち重ね図面レコードを追加する
   * @param {*} data
   */
  static async addOverlayDrawing(data) {
    await super.addData(data);
  }

  /**
   * 打ち重ね図面レコードを更新する
   * @param {*} data
   */
  static async updateOverlayDrawing(data) {
    await super.updateData(data);
  }

  /**
   * 打ち重ね図面レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteOverlayDrawing(data) {
    await super.deleteData(data);
  }

  /**
   * 打ち重ね図面レコードを取得する
   * @param {*} data
   */
  static async getOverlayDrawing(tenantId, projectId, overlayDrawingId) {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteData, {
        pk: `${tenantId}`,
        sk: `overlayDrawing#${projectId}#${overlayDrawingId}`
      })
    );

    return result.data.getKonoikeConcreteData;
  }

  /**
   * skの文字列を生成
   * overlayDrawing#プロジェクトID#打ち重ね図面ID
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    return `overlayDrawing#${projectId}#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData();
    const id = short.generate();

    newRowData.sk = this.createSk(projectId, id);
    newRowData.overlayDrawing = {
      id: id,
      name: "",
      path: "",
      overlayTimeSettingId: ""
    };

    return newRowData;
  }
}

module.exports = OverlayDrawingModel;

<template>
  <div>
    <div class="card">
      <!-- ヘッダー -->
      <div class="row ml-0">
        <application-header
          :title="appTitle"
          :isDatePicker="false"
          :isNowTime="false"
          :isEditBase="false"
          :isEditVehicle="false"
          :isCreatePlan="false"
          :isDriveLog="false"
          :isOutputCsv="false"
          :isDriveVue="true"
          @driveVue="onClickDriveVue"
        >
        </application-header>
      </div>
      <div class="card-header border-0">
        <!-- メニュー 削除・編集・上へ・下へを有効 -->
        <div class="row ml-0">
          <div class="col-sm-2">
            <a href="#!" @click.prevent="onClickReturn()">{{titleVolume}}</a>
          </div>
          <div class="col-sm-10">
            <table-tool-menu
                class="volumeMenu"
                :title="title"
                :addTitle="addTitle"
                :editTitle="editTitle"
                :deleteTitle="deleteTitle"
                :copyTitle="copyTitle"                
                :isAdd="isAdd"
                :isDelete="true"                    
                :isEdit="isEdit"
                :isCopy="isCopy"
                :isSingleSelect="selectRow"
                :isMultiSelect="false"
                :processing="false"
                :isOrderMove="false" 
                :disableMoveDown="true"
                :disableMoveUp="true"
                @addNew="onAddVolum()"
                @edit="onEditVolum(selectedData)"
                @delete="onDeleteVolum(selectedData)"
                @copy="onCopyDay(selectedData)"
            ></table-tool-menu>
          </div>
        </div>
      </div>

      <!-- 予定数量登録日一覧テーブル -->
      <div class="volume-list">
        <el-table
          ref="VolumTable"
          class="table-responsive align-items-center table-flush"
          header-row-class-name="app-th-row"
          select-on-indeterminate="true"
          :default-sort="{ prop: 'volume.endAreaName', order: 'ascending' }"
          @sort-change="runSort"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
          :data="volumList"
        >
            <el-table-column v-if="isAdd" type="selection" align="left" min-width="120px"></el-table-column>
            <!-- 生コン用一覧 -->
            <el-table-column v-if="projectType==0" label="工区" min-width="200px" prop="volume.endAreaName" sortable="'custom'">
              <template v-slot="{ row }">
                {{ row.volume.endAreaName }}
              </template>
            </el-table-column>
            <el-table-column v-if="projectType==0" label="打設箇所" min-width="200px" prop="volume.pouringPosition" sortable="'custom'"></el-table-column>
            <el-table-column v-if="projectType==0" label="予定数量（㎥）" min-width="200px" prop="volume.totalVolume" sortable="'custom'"></el-table-column>
            <el-table-column v-if="projectType==0" label="規格" min-width="200px" prop="volume.grade" sortable="'custom'"></el-table-column>

            <!-- 残土用一覧 -->
            <el-table-column v-if="projectType==1" label="運搬先" min-width="180px" prop="volume.endAreaName" sortable="'custom'">
              <template v-slot="{ row }">
                {{ row.volume.endAreaName }}
              </template>
            </el-table-column>
            <el-table-column v-if="projectType==1" label="予定数量（㎥）" min-width="150px" prop="volume.totalVolume" sortable="'custom'"></el-table-column>
            <el-table-column v-if="projectType==1" label="岩種" min-width="180px" prop="volume.rockTypeName" sortable="'custom'"></el-table-column>
            <el-table-column v-if="projectType==1" label="積載量（㎥/台）" min-width="150px" prop="volume.loadCapacity" sortable="'custom'"></el-table-column>
            <el-table-column v-if="projectType==0" label="打ち重ね図面有無" min-width="170px" prop="volume.loadCapacity" >
              <template v-slot="{ row }" >
                <span v-if="row.volume.overlayDrawingId === null || row.volume.overlayDrawingId === ''">なし</span>
                <span v-else>あり</span>
              </template>
            </el-table-column>
            <el-table-column v-if="projectType==0" label="打ち重ね図面" min-width="150px" prop="volume.endAreaName">
              <template v-slot="{ row }">
                <base-button type="primary"  block @click="onClickOverlay(row)">
                  <span v-if="row.volume.overlayDrawingId === null || row.volume.overlayDrawingId === ''">登録</span>
                  <span v-else>
                    <span v-if="isPassedDay">閲覧</span>
                    <span v-else>編集</span>
                  </span>
                </base-button>
              </template>
            </el-table-column>
        </el-table>
      </div>

      <!-- 予定数量登録・編集（モーダル） -->
      <modal v-model:show="modals.editVolumDlg" size="sm" body-classes="p-0">
        <template v-slot:header>
          <h6 class="modal-title">{{editMode}}</h6>
        </template>
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-2 py-lg-2"
          class="border-0 mb-0">

          <!-- 生コン用モーダル -->
          <div v-if="projectType==0">

            <div class="col-md-12">
              <base-input name="endAreaId" label="工区">
                <el-select placeholder=""
                  v-model="editValueItem.endAreaId" readonly="true">
                  <el-option v-for="item in listArea"
                            class="select-primary"
                            :value="item.id"
                            :label="item.name"
                            :key="item.name">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-md-12">
              <base-input name="pouringPosition" label="打設箇所" >
                <el-autocomplete
                  placeholder="入力・選択してください"
                  v-model="editValueItem.pouringPosition"
                  :fetch-suggestions="querySearch"
                ></el-autocomplete>
              </base-input>
            </div>

            <div class="col-md-12">
              <base-input type="text" label="予定数量（㎥）">
                <template></template>
                <el-input
                  placeholder="入力してください"
                  v-model="editValueItem.totalVolume"
                ></el-input>
              </base-input>
            </div>

            <div class="col-md-12">
              <base-input type="text" label="規格">
                <template></template>
                <el-input
                  placeholder="例:18-8-25BB"
                  v-model="editValueItem.grade"
                ></el-input>
              </base-input>
            </div>

            <div class="col-md-12">
              <base-input type="text" label="発注アラート設定">
                <el-checkbox
                  v-model="editValueItem.settingAlert"
                  label="残台数アラートを設定する"
                  size="medium"
                ></el-checkbox>            
                <template></template>
                <div class="d-flex">
                  <el-input
                    class="col-md-6"
                    placeholder="入力してください"
                    v-model="editValueItem.numberAlert"
                  ></el-input>
                  <h4 style="margin-top:10px">台</h4>
                </div>
              </base-input>
            </div>
          </div>

          <!-- 残土用モーダル -->
          <div v-if="projectType==1">

            <div class="col-md-12">
              <base-input name="endAreaId" label="運搬先">
                <el-select placeholder=""
                  v-model="editValueItem.endAreaId" readonly="true">
                  <el-option v-for="item in listArea"
                            class="select-primary"
                            :value="item.id"
                            :label="item.name"
                            :key="item.name">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="d-flex col-md-12">
              <base-input type="text" label="予定数量（㎥）">
                <template></template>
                <el-input
                  style="width:150px"
                  placeholder="入力してください"
                  v-model="editValueItem.totalVolume"
                ></el-input>
              </base-input>
              <span style="margin-top:40px; margin-left:10px">㎥</span>
            </div>

            <div class="col-md-12">
              <base-input name="rockTypeId" label="岩種">
                <el-select placeholder=""
                  v-model="editValueItem.rockTypeId" readonly="true"
                  @change="onChangeRockType(editValueItem.rockTypeId)">
                  <el-option v-for="item in listRockType"
                            class="select-primary"
                            :value="item.id"
                            :label="item.name"
                            :key="item.name">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="d-flex col-md-12">
              <base-input type="text" label="最大積載量">
                <template></template>
                <el-input
                  style="width:150px"
                  placeholder="入力してください"
                  v-model="editValueItem.loadCapacity"
                ></el-input>
              </base-input>
              <span style="margin-top:40px; margin-left:10px">㎥ / 台</span>
            </div>

          </div>

        </card>

        <!-- キャンセル、更新ボタン -->
        <div class="row mt-2 mb-2 ml-2 mr-2">
          <div class="col-sm-5">
            <base-button type="secondary" block @click="modals.editVolumDlg = false" >キャンセル</base-button>
          </div>
          <div class="col-sm-2">
          </div>
          <div class="col-sm-5">
            <base-button type="primary" block @click="onClickSubmit">{{editButtonKind}}</base-button>
          </div>
        </div>

      </modal>

        <!-- 予定数量処理確認モーダル -->
        <modal v-model:show="modals.confirmDlg">
          <template v-slot:header>
              <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
          </template>
          <div class="mb-5">
            <span v-if="actionMode == 4" class="mt-0 text-danger">{{confirmMessage}}</span>
            <span v-if="actionMode != 4">{{confirmMessage}}</span>
            <ul class="data-list" v-if="actionMode == 1 && projectType==0">
              <li>打ち重ね図面データ</li>
              <li>打設範囲データ</li>
              <li>経過時間設定データ</li>
            </ul>
          </div>
          <div class="row mt-2 mb-2 ml-2 mr-2">
            <div class="col-sm-5">
              <base-button v-if="actionMode != 4" type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="col-sm-5">
              <base-button v-if="actionMode != 1" type="primary" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
              <base-button v-if="actionMode == 1" type="danger" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
            </div>
          </div>
        </modal>

    </div>
  </div>
</template>

<style scoped>
  /** このVueだけのスタイル */
  .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }  
  .volume-list {
    width: 100%;
    height: 100%;
  }

  .col-md-12 >>> .el-autocomplete {
    width: 100%;
  }
</style>

<script>
import ApplicationHeader from '../components/Menu/ApplicationHeader.vue';
import TableToolMenu from "../components/Menu/TableToolMenu";
import Modal from "@/components/Modal";
import appLog from "@/appUtils/AppLog"
import DateUtil from "@/appUtils/DateUtil"
import volumeModel from "@/appModel/Volume/VolumeModel";
import baseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import DriveModel from "@/appModel/Drive/DriveModel"
import SortUtil from '@/appUtils/SortUtil';
import { useToast } from "vue-toastification";
import PlanModel from "@/appModel/Plan/PlanModel";
import dayjs from 'dayjs';
import UserInfo from "@/appUtils/UserInfo.js";
import PorjectModel from "@/appModel/project/ProjectModel";
import ValidSession from "../common/ValidSession.js";
import rockTypeModel from "@/appModel/Rock/RockModel";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import OverlayDrawingModel from "@/appModel/Overlay/OverlayDrawingModel";
import TenantStorage from "@/appUtils/TenantStorage"
import OverlayPointModel from "@/appModel/Overlay/OverlayPointModel";
import OverlayModel from "@/appModel/Overlay/OverlayModel";

const path = require("path")
export default {
  components: {
    ApplicationHeader,
    TableToolMenu,
    Modal
  },
  data() {
    return {
      projectType: "0", // 0:生コン、1：残土
      appTitle: "予定数量登録",
      title: "",
      addTitle: "予定数量を追加",
      editTitle: "予定数量を編集",
      deleteTitle: "予定数量を削除",
      copyTitle: "予定数量をコピー",      
      volumList: [],
      projectid: null,
      projectname: null,
      datestring: null,
      planid: null,
      selectedData: null,
      selectedDatas: null,
      selectedDataCnt: 0,
      editValueItem: null, 
      CopyFlag: false,
      modals: {
        editVolumDlg: false,
        confirmDlg: false,
      },
      listArea: [],
      listPouringPosition: [],
      listRockType: [],
      isAdd: true,
      isEdit: true,
      isCopy: true,
      userAuthority: 1,
      isPassedDay: false,
      pouringPositionLenght: 20,
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）   
      confirmTitle: '',
      confirmMessage: '',
      confirmOK: '',
      actionMode: 0, // 0: 追加, 1: 削除, 2: 編集, 3: コピー , 4:図面利用している 
      titleVolume: '',  
      editMode: '',
      editButtonKind: '',
      initNumberAlert: 0,
      rockTypeList: [], // 岩種マスター値
      tenantId: '',
      overlayDrawing: null, 
      originalOverlayDrawing: null, 
      tenantStorage: null,
      usePouring: false,
    };
  },
  // コンピュートプロパティ
  computed: {
    selectRow() {
      if (this.userAuthority == 0 || this.isPassedDay == true) {
        return false;
      }      
      if (this.selectedDataCnt > 0) {
        return true;
      } else {
        return false;
      }
    },
    vuename() {
      return "PlanEdit.vue"
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.infoLog("created", `Start Vuex：projectid(${this.$store.state.plan.projectid})、projectname(${this.$store.state.plan.projectname})、datestring(${this.$store.state.plan.datestring})、planid(${this.$store.state.plan.planid})、createdate(${this.$store.state.plan.createdate})`)
    this.init()
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },  
  beforeRouteLeave() {
    // モーダルを非表示
    this.modals.editVolumDlg = false;
    this.modals.confirmDlg = false;
    
    this.infoLog("beforeRouteLeave", `End`)
  },
  mixins: [ValidSession, UseApps, Logger],
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 初期化処理
     */    
    async init() {

      let loader = this.showLoader();
      try {

        // プロジェクトタイプ取得
        if (this.isZando) {
          this.projectType = "1"; // 残土
        } else {
          this.projectType = "0"; // 生コン
        }        

        // プルジェクトID、予定IDに値が入っているかチェック
        this.projectid = this.$store.state.plan.projectid
        this.datestring = this.$store.state.plan.datestring
        this.projectname = this.$store.state.plan.projectname
        this.planid = this.$store.state.plan.planid
        if (this.projectid == null || this.projectid == "" || this.planid == null || this.planid == ""){
          this.showBottomToast(`予定数量登録一覧画面表示処理に失敗しました。（プロジェクトIDが不正）`, 'error').then(() => {
          })
          this.$router.push({
            path: "/projects",
          });        
        }

        // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        this.userAuthority = this.$store.state.user.userType
        if (this.userAuthority == 0) {
          this.isAdd = false;
        }

        // タイトルをセット
        let navInfo = this.$store.state.navInfo
        navInfo.title = `${this.projectname} - ${this.$route.meta.title}`
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)
        
        // 前のページに戻るリンク文字列作成（< yyyy/mm/dd 予定数量）
        this.title=""
        let createDate = this.$store.state.plan.createdate
        this.titleVolume = '＜ ' + DateUtil.dateStringBase(createDate, 'YYYY/MM/DD') + ' 予定数量'

        // 過去の年月日の場合は編集不可
        let nowDate = DateUtil.getFormatString(Date.now(), 'YYYYMMDD')
        let chkCreateDate = DateUtil.getFormatString(createDate, 'YYYYMMDD')
        if (nowDate > chkCreateDate){
          this.isPassedDay = true
          this.isAdd = false
        }

        // 登録項目変数初期化
        this.initEditListItem();

        // 工区の一覧取得（セレクトボックスにセット）
        await this.getAreaList();

        // 岩種一覧取得
        await this.getRockTypeList();
  
        // 予定数量一覧取得
        await this.getVolumeList();

        // 残台数初期値取得
        this.initNumberAlert = await this.getProjectNumberAlert();

        // 連打防止
        this.repeatedHitsFlg = false

        const user = await UserInfo.getUserInfo();
        this.tenantStorage = new TenantStorage(user.group);
        const _this = this;
        this.tenantId = this.getTenantId().then(id => {
          _this.tenantId = id;
        })
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e))
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * テナントID修得
    */
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }
      return loginInfo.group;
    },
    /**
     * 予定数量項目初期化
     */       
    initEditListItem() {
      
      // 予定数量項目の初期化
      let item = {
        rowId: "",
        endAreaId: "",
        pouringPosition: "",
        beforeEndAreaId: "",
        beforePouringPosition: "",
        totalVolume: 0,
        grade: "",
        settingAlert: false,
        numberAlert: 0,
        rockTypeId: "",
        beforerockTypeId: "",
        loadCapacity: 0,
      }
      this.editValueItem = item
    },    
    /**
     * 予定数量一覧取得
     */
    async getVolumeList(){

      // テーブル関連編集初期化
      this.selectedData = null;      
      this.selectedDatas = null;      
      this.selectedDataCnt = 0; 
      
      // プロジェクトID、予定IDを指定して取得   
      const searchKey = this.projectid + '#' + this.planid
      let listVolum = await volumeModel.getVolumeList(searchKey); 

      // 到着拠点IDを名称に変更、予定数量小数表示桁数(2桁)設定
      listVolum.forEach(Item => {
        for (let i=0; i<this.listArea.length; i++)
        {
          if (this.listArea[i].id == Item.volume.endAreaId)
          {
            Item.volume.endAreaName = this.listArea[i].name
            break 
          }
        }
        // 岩種名セット
        for (let i=0; i<this.listRockType.length; i++)
        {
          if (this.listRockType[i].id == Item.volume.rockTypeId)
          {
            Item.volume.rockTypeName = this.listRockType[i].name
          }
        }

        if (this.projectType == "0"){
          Item.volume.totalVolume = Item.volume.totalVolume.toFixed(2);
        } else if (this.projectType == "1"){
          if (Item.volume.totalVolume !== null && Item.volume.totalVolume !== ""){
            Item.volume.totalVolume = Item.volume.totalVolume.toFixed(2);
          }
        }
       
      });
      
      // ソートして一覧に表示（工区名称でソート）
      this.volumList = listVolum.sort(function(a, b){
        return (a.volume.endAreaName < b.volume.endAreaName) ? -1 : 1;
      }) 

      // 打設箇所をautocompleteにセット
      if (this.projectType == "0") {
        this.getListPouringPosition();
      }
    },
    /**
     * 打設箇所の一覧取得し、autocompleteに値セット
     */  
    async getListPouringPosition() { 

      // 予定情報データを全件取得
      let planList = await PlanModel.getPlanList(this.projectid)

      // 予定数量登録データを全件取得
      const searchKey = this.projectid
      let listVolum = await volumeModel.getVolumeList(searchKey);

      // 予定数量登録データをupdatedAtで並び替える
      let wPouringPosition = listVolum.sort(function(a, b){
        return (a.updatedAt < b.updatedAt) ? -1: 1;
      }) 

      // 6か月前のオブジェクトを作成
      const now = dayjs();
      let fromDate = now.subtract(6, 'M')
      let fromDateString = fromDate.format('YYYY-MM-DD')

      // 予定数量登録データごとに処理を行う
      this.listPouringPosition = []
      wPouringPosition.forEach((list) => {
        let item = {
          id: list.volume.pouringPosition, 
          name: list.volume.pouringPosition
        }

        // 予定数量登録データの予定IDを取得する
        let volumeSkList = list.sk.split('#')
        let volumePlanId = volumeSkList[2]
        
        planList.forEach(plan => {
          // 予定情報データの日付を取得する
          let lsiStr1List = plan.lsiStr1.split('#')
          let planDate = dayjs(lsiStr1List[2])
          let planDateString = planDate.format('YYYY-MM-DD')

          // 予定情報データの予定IDを取得する
          let planSkList = plan.sk.split('#')
          let planPlanId = planSkList[2]

          // 予定情報が現在日時から6か月以内、かつ予定IDが一致する場合、打設箇所リストに加える
          if (DateUtil.isAfter(planDateString, fromDateString) && (volumePlanId == planPlanId)) {
            let isExist = false
            for (let i=0; i<this.listPouringPosition.length; i++)
            {
              if (this.listPouringPosition[i].name == item.name)
              {
                isExist = true
                break
              }  
            }
            if (isExist == false)
            {
              this.listPouringPosition.push(item)
            }
          }
        });
      });  
    },
    /**
     * 工区の一覧取得し、セレクトボックスに値セット
     */     
    async getAreaList(){
      // 拠点取得
      const projectID = this.$store.state.plan.projectid
      let lists = await baseAreaModel.getBaseAreaList(projectID)

      // 拠点の種別到着地のみ取得（IDでソート）
      lists = lists.filter((list) => list.baseArea.areaType === 2)
        .slice()
        .sort(function(a, b) {
          if (a.baseArea.id < b.baseArea.id) return -1;
          if (a.baseArea.id > b.baseArea.id) return 1;
        });

      // セレクトボックスの項目に追加
      lists.forEach((list) => {
        let item = {
          id: list.baseArea.id, 
          name: list.baseArea.areaName
        }
        this.listArea.push(item)
      });
    },
    /**
     * 予定数量の追加（モーダル画面表示）
     */       
    onAddVolum(){

      // モーダル画面のタイトルとボタンのキャプション設定
      this.editMode = this.addTitle
      this.editButtonKind = "登録"

      // 新規登録のため、IDはNULLを設定（登録モード）
      this.editValueItem.rowId = null;
      this.editValueItem.endAreaId = null;
      this.editValueItem.pouringPosition = null;
      this.editValueItem.totalVolume = null;
      this.editValueItem.beforeEndAreaId = null;
      this.editValueItem.beforePouringPosition = null;
      this.editValueItem.grade = null;
      this.editValueItem.settingAlert = false;
      this.editValueItem.numberAlert = this.initNumberAlert;
      this.editValueItem.rockTypeId = null;
      this.editValueItem.beforerockTypeId = null;
      this.editValueItem.loadCapacity = 0;
      this.CopyFlag = false

      // モーダル画面表示
      this.modals.editVolumDlg = true;
    },
    /**
     * 予定数量の編集（モーダル画面表示）
     */       
    async onEditVolum(row){

      // 運行情報に紐づく予定数量を削除しようとしていないかチェック
      let ret = await this.checkDriveInfo(row)
      if (ret == true){
        this.showBottomToast(`既に運行情報が登録されているため、予定数量は編集できません。`, 'warning')
        return 
      }

      // モーダル画面のタイトルとボタンのキャプション設定
      this.editMode = this.editTitle
      this.editButtonKind = "更新"

      // 選択行の値をセット
      this.editValueItem.rowId = row.id
      this.editValueItem.endAreaId = row.volume.endAreaId
      this.editValueItem.pouringPosition = row.volume.pouringPosition
      this.editValueItem.totalVolume = row.volume.totalVolume
      this.editValueItem.beforeEndAreaId = row.volume.endAreaId
      this.editValueItem.beforePouringPosition = row.volume.pouringPosition
      this.editValueItem.grade = row.volume.grade
      if (row.volume.settingAlert == 1) {
        this.editValueItem.settingAlert = true;
      } else {
        this.editValueItem.settingAlert = false;
      }
      this.editValueItem.numberAlert = row.volume.numberAlert
      this.editValueItem.rockTypeId = row.volume.rockTypeId
      this.editValueItem.beforerockTypeId = row.volume.rockTypeId
      this.editValueItem.loadCapacity = row.volume.loadCapacity
      this.CopyFlag = false

      // モーダル画面表示
      this.modals.editVolumDlg = true
    },
    /**
     * 予定数量のコピー（モーダル画面表示）
     */       
    onCopyDay(row){
      // モーダル画面のタイトルとボタンのキャプション設定
      this.editMode = this.copyTitle
      this.editButtonKind = "コピー"

      // 選択行の値をセット
      this.editValueItem.rowId = null
      this.editValueItem.endAreaId = row.volume.endAreaId
      this.editValueItem.pouringPosition = row.volume.pouringPosition
      this.editValueItem.totalVolume = row.volume.totalVolume
      this.editValueItem.beforeEndAreaId = null
      this.editValueItem.beforePouringPosition = null
      this.editValueItem.grade = row.volume.grade
      this.editValueItem.settingAlert = row.volume.settingAlert
      this.editValueItem.numberAlert = row.volume.numberAlert
      this.editValueItem.rockTypeId = row.volume.rockTypeId
      this.editValueItem.beforerockTypeId = null
      this.editValueItem.loadCapacity = row.volume.loadCapacity
      this.editValueItem.overlayDrawingId = row.volume.overlayDrawingId
      this.CopyFlag = true

      // モーダル画面表示
      this.modals.editVolumDlg = true
    },
    /**
     * 打設箇所候補の表示
     */
    async querySearch(queryString, callback) {
      //打設箇所リスト作成
      let listPouringPosition = this.listPouringPosition;
      let lists = []
      listPouringPosition.forEach(list => {
        lists.push({value: list.id})
      });
      
      let results = queryString ? lists.filter(this.createFilter(queryString)) : lists;

      callback(results)

    },
    /**
     * 抽出条件作成
     */
    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().includes(queryString.toLowerCase());
      };
    },

    /**
     * 予定数量追加・編集・コピー（モーダル画面の実行ボタン押下時）
     */
    async onClickSubmit()
    {
      // 登録確認メッセージ
      let operation = null;
      let buttonLabel = '実行';
      if (this.editValueItem.rowId == null && this.CopyFlag == false) {
        operation = this.addTitle;
        this.actionMode = 0;
        buttonLabel = '追加';
      } else if (this.editValueItem.rowId != null) {
        operation = this.editTitle;
        this.actionMode = 2;
        buttonLabel = '編集';
      } else {
        operation = this.copyTitle;
        this.actionMode = 3;
        buttonLabel = 'コピー';
      }

      // 予定数量処理確認モーダル表示
      this.confirmTitle = operation;
      this.confirmMessage = `${operation}します。よろしいですか？`;
      this.confirmOK = buttonLabel;
      this.modals.confirmDlg = true;
    },
    
    /**
     * 予定数量処理確認モーダル キャンセルボタンクリックイベント
     */
    onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },

    /**
     * 予定数量処理確認モーダル 実行ボタンクリックイベント
     */       
    async onClickConfirmOK() {
      // 予定数量処理確認モーダル非表示
      this.modals.confirmDlg = false;

      if (this.actionMode == 4) {
        return;
      }

      // 削除モードの場合は削除処理を呼び出す
      if (this.actionMode == 1) {
        this.deleteDay();
        return;
      }

      // 登録確認メッセージ
      let operation = null
      if (this.editValueItem.rowId == null && this.CopyFlag == false)
        operation = this.addTitle
      else if (this.editValueItem.rowId != null)
        operation = this.editTitle
      else
        operation = this.copyTitle

      try {        
        // 工区の入力チェック
        if (this.editValueItem.endAreaId == null){
          if (this.projectType == "1"){
            this.showBottomToast(`運搬先を指定してください。`, 'warning') 
          } else {
            this.showBottomToast(`工区を指定してください。`, 'warning') 
          }
          return
        }

        // プロジェクトが生コンの場合は打設箇所入力チェック
        if (this.projectType == "0"){
          // 打設箇所の入力チェック
          if (this.editValueItem.pouringPosition == null || this.editValueItem.pouringPosition == ""){
            this.showBottomToast(`打設箇所を入力してください。`, 'warning') 
            return
          }
          // 打設箇所の文字長さチェック
          if (String(this.editValueItem.pouringPosition).length > this.pouringPositionLenght){
            this.showBottomToast(`打設箇所の文字数は ${this.pouringPositionLenght} 文字以内で入力してください。`, 'warning')
            return 
          }
          // 打設箇所の文禁則文字チェック
          let kinsokuflg = false
          const kinsoku_str = process.env.VUE_APP_KINSOKU_CHARACTERS
          for (let i=0; i<String(kinsoku_str).length;i++) {
            let check_str = String(kinsoku_str).substr(i+1, 1)
            let ret = String('dummy' + this.editValueItem.pouringPosition).indexOf(check_str)
            if (ret>0) {
              kinsokuflg = true
              break
            }
          }
          if (kinsokuflg){
            this.showBottomToast(`打設箇所に禁則文字が指定されてます。\n禁則文字（${kinsoku_str.substr(1, kinsoku_str.length - 1)}）`, 'warning')
            return 
          }
        }

        // 予定数量（㎥）の入力チェック
        if (this.projectType == "0"){
          if (this.editValueItem.totalVolume == null || this.editValueItem.totalVolume == ""){
            this.showBottomToast(`予定数量（㎥）を入力してください。`, 'warning')
            return
          }
        }

        // 予定数量（㎥）の入力チェック
        if (isNaN(this.editValueItem.totalVolume)){
          this.showBottomToast(`予定数量（㎥）には数値を入力してください。`, 'warning')
          return
        }
        // 小数点以下のチェック（2桁までOK）
        let chkValue = String(this.editValueItem.totalVolume).split('.')
        if (chkValue.length == 2){
          if (chkValue[1].length > 2){
            this.showBottomToast(`予定数量（㎥）の小数点以下は2桁までにしてください。`, 'warning')
            return
          }
        }
        if (this.projectType == "0"){
          // 工区・打設箇所の組合せで既に登録されている場合は登録不可 (変更がない場合はチェックしない)
          if (this.editValueItem.beforeEndAreaId != this.editValueItem.endAreaId || this.editValueItem.beforePouringPosition != this.editValueItem.pouringPosition){
            let ret = await this.validationCheck(this.editValueItem.endAreaId, this.editValueItem.pouringPosition)
            if (ret){
              this.showBottomToast(`既に登録されている工区・打設箇所の組合せです。`, 'warning')
              return
            }
          }
        } else if (this.projectType == "1"){
          // 工区・岩種の組合せで既に登録されている場合は登録不可 (変更がない場合はチェックしない)
          if (this.editValueItem.beforeEndAreaId != this.editValueItem.endAreaId || this.editValueItem.beforerockTypeId != this.editValueItem.rockTypeId){
            let ret = await this.rockValidationCheck(this.editValueItem.endAreaId, this.editValueItem.rockTypeId)
            if (ret){
              this.showBottomToast(`既に登録されている運搬先・岩種の組み合わせです`, `warning`)
              return
            }
          }
        }
        


        // プロジェクトが生コンの場合は打設箇所入力チェック
        if (this.projectType == "0"){
          // 発注アラート設定（チェックボックスONの時）
          if (this.editValueItem.settingAlert) {
            let pattern = /^([1-9]\d*|1)$/;
            if (!pattern.test(this.editValueItem.numberAlert)){
              this.showBottomToast(`残台数アラートには1以上の数値を入力してください。`, 'warning')
              return;
            }
          } else {
            this.editValueItem.numberAlert = null;
          }
        }

        // プロジェクトが残土の場合は岩種名入力、積載量の入力チェック
        if (this.projectType == "1"){
          // 岩種の入力チェック
          if (this.editValueItem.rockTypeId == null || this.editValueItem.rockTypeId == ""){
            this.showBottomToast(`岩種を指定してください。`, 'warning') 
            return
          }
          // 積載量の入力チェック
          if (this.editValueItem.loadCapacity == null || this.editValueItem.loadCapacity == ""){
            this.showBottomToast(`積載量には数値を入力してください。`, 'warning')
            return
          }
          // 積載量の入力チェック
          if (isNaN(this.editValueItem.loadCapacity)){
            this.showBottomToast(`積載量には数値を入力してください。`, 'warning')
            return
          }
        }        

        // 新規登録、コピー時
        if (this.editValueItem.rowId == null)
        {
          // 登録用のレコード作成
          let regData = await volumeModel.getNewData(this.projectid, this.planid, this.editValueItem.endAreaId)
          
          // lsiStr2(キー作成：volume#yyyyMMDD)
          regData.lsiStr2 = `volume#${String(this.$store.state.plan.createdate).replace('/', '').replace('/', '')}`

          // 値の登録（到着拠点Id、打設箇所、予定数量（㎥））
          regData.volume.endAreaId = this.editValueItem.endAreaId
          regData.volume.pouringPosition = this.editValueItem.pouringPosition
          regData.volume.totalVolume = this.editValueItem.totalVolume
          //空白のときはnullをセットする
          if (this.editValueItem.totalVolume == ""){
            regData.volume.totalVolume = null
          }
          regData.volume.grade = this.editValueItem.grade
          regData.volume.settingAlert = !this.editValueItem.settingAlert? 0 : 1;
          regData.volume.numberAlert = this.editValueItem.numberAlert;
          regData.volume.rockTypeId = this.editValueItem.rockTypeId;
          regData.volume.loadCapacity = this.editValueItem.loadCapacity;
          
          // 残土の場合は、打設箇所に岩種名をセット
          if (this.projectType == "1") {
            regData.volume.pouringPosition = this.getRockName(this.editValueItem.rockTypeId);
          }

          if (this.CopyFlag ==true){
            if (this.editValueItem.overlayDrawingId!=null && this.editValueItem.overlayDrawingId!=""){
              //打ち重ね図面データ取得
              this.originalOverlayDrawing = await OverlayDrawingModel.getOverlayDrawing(this.tenantId, this.projectid, this.editValueItem.overlayDrawingId); 
              //登録用の打ち重ね図面データ作成
              this.overlayDrawing = await OverlayDrawingModel.getNewData(this.projectid);

              let isUploaded = false;
              // S3に図面をコピー
              isUploaded = await this.copyFile();

              if (isUploaded) {
                this.overlayDrawing.overlayDrawing.name = this.originalOverlayDrawing.overlayDrawing.name; 
                this.overlayDrawing.overlayDrawing.overlayTimeSettingId=this.originalOverlayDrawing.overlayDrawing.overlayTimeSettingId; 
                //打ち重ね図面データ登録
                await OverlayDrawingModel.addOverlayDrawing(this.overlayDrawing)
              }

              //打設範囲データ取得
              let overlayPointList = await OverlayPointModel.getOverlayPointList(this.tenantId, this.projectid, this.editValueItem.overlayDrawingId);
              for await (let copydata of overlayPointList) {
                let data = await OverlayPointModel.getNewData(this.projectid, this.overlayDrawing.overlayDrawing.id);
                data.overlayPoint.pointName = copydata.overlayPoint.pointName;
                data.overlayPoint.x = copydata.overlayPoint.x;
                data.overlayPoint.y = copydata.overlayPoint.y;
                data.overlayPoint.radius = copydata.overlayPoint.radius;
                data.overlayPoint.polygon = copydata.overlayPoint.polygon;
                data.overlayPoint.shapeType = copydata.overlayPoint.shapeType;
                console.log(`追加 ${JSON.stringify(data, null, "\t")}`)
                //打設範囲登録
                await OverlayPointModel.addOverlayPoint(data);
              }
              regData.volume.overlayDrawingId = this.overlayDrawing.overlayDrawing.id;
            }
          }
          
          // DB更新
          await volumeModel.addVolume(regData)

          // ログ出力
          if (this.CopyFlag == false){
            this.infoLog("onClickCOnfirmOK", `【Insert】 Volume: pk[${regData.pk}]、sk[${regData.sk}]、到着拠点ID [${regData.volume.endAreaId}]、打設箇所 [${regData.volume.pouringPosition}]、予定数量（㎥） [${regData.volume.totalVolume}]`)
          } else {
            this.infoLog("onClickConfirmOK", `【Copy】 Volume: pk[${regData.pk}]、sk[${regData.sk}]、到着拠点ID [${regData.volume.endAreaId}]、打設箇所 [${regData.volume.pouringPosition}]、予定数量（㎥） [${regData.volume.totalVolume}]`)
          }

        // 編集時
        } else {

          // 更新対象の行情報をセット
          let editData = this.selectedData

          editData.volume.endAreaId = this.editValueItem.endAreaId
          editData.volume.pouringPosition = this.editValueItem.pouringPosition
          editData.volume.totalVolume = this.editValueItem.totalVolume
          //空白のときはnullをセットする
          if (this.editValueItem.totalVolume == ""){
            editData.volume.totalVolume = null
          }
          editData.volume.grade = this.editValueItem.grade
          editData.volume.settingAlert = !this.editValueItem.settingAlert? 0 : 1;
          editData.volume.numberAlert = this.editValueItem.numberAlert;
          editData.volume.rockTypeId = this.editValueItem.rockTypeId;
          editData.volume.loadCapacity = this.editValueItem.loadCapacity;

          // 残土の場合は、打設箇所に岩種名をセット
          if (this.projectType == "1") {
            editData.volume.pouringPosition = this.getRockName(this.editValueItem.rockTypeId);
          }

          // 拠点名カラム削除
          delete editData.volume["endAreaName"]
          delete editData.volume["rockTypeName"]

          // DB更新
          await volumeModel.updateVolume(editData)

          // ログ出力
          this.infoLog("onClickConfirmOK", `【Update】 Volume： pk[${editData.pk}]、sk[${editData.sk}]、到着拠点ID [${editData.volume.endAreaId}]、打設箇所 [${editData.volume.pouringPosition}]、予定数量（㎥） [${editData.volume.totalVolume}]`)

        }

        // モーダル画面を閉じる
        this.modals.editVolumDlg = false
        // 一覧情報再取得
        this.getVolumeList();
        // 完了メッセージ
        this.showBottomToast(`${operation}処理が完了しました。`, 'info')

      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`${operation}処理に失敗しました。`, 'error')
        this.errorLog("onClickConfirmOK", this.parseErrorObject(e))
      } 

    },

    /**
     * 到着地点ID、打設箇所重複チェック
     * @param areaId 到着地点ID
     * @param pouringPosition 打設箇所
     * @return true:重複チェックエラー、false：重複なし
     */
    async validationCheck(areaID, pouringPosition){

      // 戻り値初期化
      let retValue = true

      // プロジェクトID、予定IDを指定して取得   
      const searchKey = this.projectid + '#' + this.planid
      let listVolum = await volumeModel.getVolumeList(searchKey); 

      // 到着地点ID、打設箇所がデータじ分がないかチェック
      let retList = await listVolum.filter((list) => list.volume.endAreaId === areaID && list.volume.pouringPosition === pouringPosition);
      if (retList.length == 0){
        retValue = false
      } 

      return retValue
    },
    /**
     * 到着拠点ID、岩種ID重複チェック
     * @param areaId 到着拠点ID
     * @param rockTypeId 岩種ID
     * @return true:重複チェックエラー、false:重複なし
     */
    async rockValidationCheck(areaID, rockTypeId){
      // 戻り値初期化
      let retValue = true

      // プロジェクトID、予定IDを指定して取得   
      const searchKey = this.projectid + '#' + this.planid
      let listVolum = await volumeModel.getVolumeList(searchKey); 

      //到着拠点ID、岩種IDがデータ重複が無いかチェック
      let retList = await listVolum.filter((list) => list.volume.endAreaId === areaID && list.volume.rockTypeId === rockTypeId);
      if (retList.length == 0){
        retValue = false
      }
      return retValue
    },

    /**
     * 削除対象の予定数量に紐づく運行情報の有無をチェック
     * @paramas row 削除対象の予定数量情報
     * @return True：紐づく運行情報あり、False：紐づく運行情報なし
     */     
    async checkDriveInfo(row){

      let retValue = false 

      // 問合せキー作成
      let createDate = String(this.$store.state.plan.createdate).replace('/', '').replace('/', '')
      let sk = `${this.projectid}#${createDate}#${row.volume.endAreaId}`
      
      // 運行情報取得
      let driveList = await DriveModel.getDriveList(sk)

      // 該当レコードの有無をチェック
      for (let i=0; i<driveList.length; i++){
        if (row.volume.pouringPosition == driveList[i].drive.pouringPosition) {
          retValue = true
          break
        }
      }
      
      // 結果を返却
      return retValue
    },

    /**
     * 削除ボタンクリックイベント
     */           
    async onDeleteVolum() {
      try {

        if (this.projectType==0){
          let ret = await this.checkPouring()
          if (ret == true){
            // 予定数量処理確認モーダル表示
            let operation = this.deleteTitle;
            this.confirmTitle = operation;
            this.confirmMessage = "打設が開始されている打ち重ね図面が存在するため、削除できません。";
            this.confirmOK = 'OK';
            this.actionMode = 4;
            this.modals.confirmDlg = true;
            return;
          }
        }
        
        // 運行情報に紐づく予定数量を削除しようとしていないかチェック
        let judgeFlg = false
        for (let i=0; i<this.selectedDatas.length; i++){
          // チェック処理
          let ret = await this.checkDriveInfo(this.selectedDatas[i])
          if (ret == true){
            judgeFlg = true
            break
          }
        }

        // 確認メッセージ設定
        let msgStr = null
        if (this.projectType==0){
          if (judgeFlg == true){
            msgStr = 'データが存在します。選択した予定数量および以下のデータを削除します。よろしいですか？'
          } else {
            msgStr = '選択した予定数量および以下のデータを削除します。よろしいですか？'
          }
        } else {
          if (judgeFlg == true){
            msgStr = 'データが存在します。選択した予定数量を削除します。よろしいですか？'
          } else {
            msgStr = '選択した予定数量を削除します。よろしいですか？'
          }
        }

        // 予定数量処理確認モーダル表示
        let operation = this.deleteTitle;
        this.confirmTitle = operation;
        this.confirmMessage = msgStr;
        this.confirmOK = '削除';
        this.actionMode = 1;
        this.modals.confirmDlg = true;
        
      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`予定数量の削除処理に失敗しました。`, 'error');
        this.errorLog("onDeleteVolum", this.parseErrorObject(e))
      }
    },

    /**
     * 削除処理
     */     
    async deleteDay(){
      try {
        for (let i=0; i<this.selectedDatas.length; i++){
          // 削除処理
          await volumeModel.deleteVolume(this.selectedDatas[i]);

          //打ち重ね図面データ取得
          if (this.selectedDatas[i].volume.overlayDrawingId!=null && this.selectedDatas[i].volume.overlayDrawingId!=""){
            //打ち重ね図面データ取得
            let overlayDrawing = await OverlayDrawingModel.getOverlayDrawing(this.tenantId, this.projectid, this.selectedDatas[i].volume.overlayDrawingId); 
            //打ち重ね図面データ削除
            await OverlayDrawingModel.deleteOverlayDrawing({ pk: overlayDrawing.pk, sk: overlayDrawing.sk });
            //打ち重ね図面ファイル削除
            await this.deleteFile(overlayDrawing.overlayDrawing.path);
            //打設範囲データ取得
            let overlayPointList = await OverlayPointModel.getOverlayPointList(this.tenantId, this.projectid, this.selectedDatas[i].volume.overlayDrawingId);
            for await (let data of overlayPointList) {
              //打設範囲データ削除
              await OverlayPointModel.deleteOverlayPoint({ pk: data.pk, sk: data.sk });
            }
          }

          // ログ出力
          this.infoLog("deleteDay", `【Delete】 Volume： pk[${this.selectedDatas[i].pk}]、sk[${this.selectedDatas[i].sk}]、到着拠点ID [${this.selectedDatas[i].volume.endAreaId}]、打設箇所 [${this.selectedDatas[i].volume.pouringPosition}]、予定数量（㎥） [${this.selectedDatas[i].volume.totalVolume}]`)
        }          
        // 一覧情報再取得
        this.getVolumeList();
        // 完了メッセージ
        this.showBottomToast(`予定数量の削除処理が完了しました。`, 'info')
        
      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`予定数量の削除処理に失敗しました。`, 'error')
        this.errorLog("deleteDay", this.parseErrorObject(e))
      }         
    },
    /**
     * 予定登録日一覧画面に遷移
     */     
    onClickReturn(){
      this.$router.push({
        path: this.getRoutePath(`planList`),
      }); 
      
    },
    /**
     * テーブルのチェックボックス列選択時（全て）
     */               
    async handleSelectAllRow(selection) {

      // TODO 権限が閲覧ユーザーの場合はチェックＯＮさせない
      if (this.userAuthority == 0){
        this.$refs.VolumTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; this.isCopy = true
      } else {
        this.isEdit = false; this.isCopy = false
      }
      
      // 選択行をセット
      this.selectedDatas = selection
    },     
    /**
     * テーブルの行選択時の処理
     */       
    async handleSelectRow(selection, row) {

      // TODO 権限が閲覧ユーザーの場合はチェックＯＮさせない
      if (this.userAuthority == 0){
        this.$refs.VolumTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length  
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; this.isCopy = true
      } else {
        this.isEdit = false; this.isCopy = false
      }
      
      // チェックＯＮ、ＯＦＦ
      if (this.selectedData == row) {
        // 同一業の場合はチェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

      // 1件の時は、選択行にセット（チェックＯＦＦした結果、別の行の1件がカレント行になるように）
      if (selection.length == 1){
        this.selectedData = selection[0]
      }

      // 選択行保持
      this.selectedDatas = selection

    },    
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },
    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      if (!fieldName) {
        return;
      }
      
      // 日本語ソート
      let isKana = false;
      if (fieldName == "volume.endAreaName" || fieldName == "volume.pouringPosition") {
        isKana = true;
      }

      // ソート実行
      this.volumList = SortUtil.sort(this.volumList, fieldName, sortingType, isKana);

    },                 
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    clickCancel() {
      this.$router.push({
        path: "/Projects",
        name: "Projects",        
      });  
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue(){

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectid;
      store.projectname = this.projectname;
      store.datestring = this.datestring

      await this.$store.commit("setTimeline", store);      

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      }); 
    },           

    /**
    * 残台数アラート（初期値）取得（プロジェック設定）
    */
    async getProjectNumberAlert(){

      const user = await UserInfo.getUserInfo();
      let data = {
        pk: user.group,
        sk: `project#${this.projectid}`
      }
      const project = await PorjectModel.getProject(data);

      return project.project.numberAlert;

    },
    
    /**
     * 岩種の一覧取得し、セレクトボックスに値セット
     */
    async getRockTypeList(){
      // 岩種取得
      let lists = await rockTypeModel.getRockList()

      // 岩種名でソート
      this.rockTypeList = lists.sort(function(a, b){
        if (a.rocktype.name > b.rocktype.name) return 1;
        if (a.rocktype.name < b.rocktype.name) return -1;
        return 0
      })

      // セレクトボックスの項目に追加
      this.rockTypeList.forEach((list) => {
        let item = {
          id: list.rocktype.id, 
          name: list.rocktype.name
        }
        this.listRockType.push(item)
      });
    },

    /**
     * 岩種セレクトボックスチェンジイベント
     */
    onChangeRockType(rockTypeId) {
      // 岩種IDが一致するレコード取得
      let rockInfo = this.rockTypeList.filter(
        (item) => item.rocktype.id === rockTypeId);

      // 一致したレコードの積載量を取得しセット
      if (rockInfo.length > 0) {
        this.editValueItem.loadCapacity = rockInfo[0].rocktype.loadCapacity;
      } else {
        this.editValueItem.loadCapacity = 0;
      }

    },

    /**
     * 岩種名取得
     */
    getRockName(rockTypeId) {
      // 岩種IDが一致するレコード取得
      let rockInfo = this.rockTypeList.filter(
        (item) => item.rocktype.id === rockTypeId);

      // 一致したレコードの名称を取得
      let rockName = null;
      if (rockInfo.length > 0) {
        rockName = rockInfo[0].rocktype.name;
      } 
      return rockName;
    },
    /**
     *図面ボタンクリック時の処理
     */
    async onClickOverlay(row) {
      // storeに登録（プロジェクトID、予定ID、予定登録日）
      let plan = this.$store.state.plan
      plan.projectid = this.$store.state.plan.projectid 
      plan.projectname = this.$store.state.plan.projectname
      plan.datestring = this.$store.state.plan.datestring
      plan.planid = this.$store.state.plan.planid
      plan.createdate = this.$store.state.plan.createdate
      plan.plandata = row

      await this.$store.commit("setPlan", plan)
      let overlay = this.$store.state.overlay
      overlay.overlayrawingid=row.volume.overlayDrawingId;
      await this.$store.commit("setOverlay", overlay)
      // 打ち重ね図面登録画面起動
      this.$router.push({
        path: "/overlayDrawing",
      });
    },
    /**
     * 図面をコピー
     */
    async copyFile() {
      try {
        if (!this.tenantId || !this.projectid) {
          throw `パラメータ不足`  
        }
        //ァイル名を取得
        const fileName = path.basename(this.originalOverlayDrawing.overlayDrawing.path)

        //S3keyを構成
        const newfilePath = this.tenantStorage.createOverlaydrawingKey(this.tenantId, this.projectid, this.overlayDrawing.overlayDrawing.id, fileName);

        try {
          // S3バケットにファイルをコピー
          await this.tenantStorage.copy(this.originalOverlayDrawing.overlayDrawing.path, newfilePath)
          console.log("old file path ############## "+this.originalOverlayDrawing.overlayDrawing.path);
          console.log("new file path ############## "+newfilePath);
        } catch (e) {
          console.log(`S3アップロード失敗 ${e.stack}`)
          throw e
        }
        // コピーデータにファイル名とファイルパスをセット
        this.overlayDrawing.overlayDrawing.path = newfilePath

        return true

      } catch (e) {
        alert(e.message)
        throw e
      }
    },
    /**
     * 図面を削除
     */
    async deleteFile(s3key) {
      try {
        // もしなければ終了
        if (!s3key) {
          return;
        }
        console.log("delete file path ############## "+s3key);
        await this.tenantStorage.delete(s3key);

      } catch (e) {
        console.log(`削除失敗 ${JSON.stringify(e)}`);
      }
    },
    /**
     * 図面の使用をチェックする
     */
    async checkPouring(){
      let usePouring = false;
  
      //図面の使用をチェックする
      for (let i=0; i<this.selectedDatas.length; i++){
        let overlayPouringList = await OverlayModel.getOverlayListByDrawing(this.tenantId, this.projectid, this.selectedDatas[i].volume.overlayDrawingId);
        if (overlayPouringList.length >0){
          usePouring=true;
          break;
        }
      }
        
      return usePouring;
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },  
};
</script>
<template>
  <application-header
    :title="appTitle"
    :isPlandate="true"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isOverlay="true"
    :isDriveVue="true"
    @driveVue="onClickDriveVue"
  >
  </application-header>
  <div class="d-flex">
    <div class="row ml-0 mt-2">
      <div class="col-sm-12">
        <a href="#!" @click.prevent="onClickReturn()">{{returnScreen}}</a>
      </div>
    </div>
  </div>
  <!----------------------------------------------------
    打ち重ね図面登録、打設範囲登録、経過時間設定ボタン
  ------------------------------------------------------>
  <div class="d-flex button-area">
    <div class="col-sm-2">
      <base-button type="primary"  block @click="onClickOverlayDrawing">図面登録</base-button>
    </div>
    <div class="col-sm-2">
      <base-button type="primary" :disabled="true" block @click="onClickOverlayPointRegister">打設範囲登録</base-button>
    </div>
    <div class="col-sm-2">
      <base-button type="primary" :disabled="noOverlayDrawing" block @click="onClickOverlayTimeSetting">経過時間設定</base-button>
    </div>
  </div>

  <div class="d-flex overlaydrawing_area">
    <div>
      <div class="mx-1 p-2 rounded overlaypoint-list bgcolor-light" >
        <h4>打設範囲作成</h4>
        <el-radio-group
          class="mb-2"
          v-model="operationShape"
          size="large"
          :fill="'#154CA3'"
          @change="changeShape"
        >
          <el-radio-button label="2">図面操作</el-radio-button>
          <el-radio-button label="0">円</el-radio-button>
          <el-radio-button label="1">ポリゴン</el-radio-button>
        </el-radio-group>

        <h4>操作モード</h4>
        <el-radio-group
          class="mb-2"
          v-model="operationMode"
          size="large"
          :fill="operationMode == '2' ? '#FF0000' : '#154CA3'"
          @change="changeMode"
        >
          <el-radio-button label="0">作成</el-radio-button>
          <el-radio-button label="1">編集</el-radio-button>
          <el-radio-button label="2">削除</el-radio-button>
        </el-radio-group>
        <!--
        <div v-if="operationMode == 1 && operationShape==0">
          <h4 class="">ポイントサイズ</h4>
          <div class="row">
            <div class="col-lg-7 ">

              <base-input name="pointSize">
                <el-form-item prop="pointSize">
                  <el-input
                    placeholder="ポイントサイズ"
                    v-model="pointSize"
                  ></el-input>
                </el-form-item>
              </base-input>
            </div>
            <div class="row col-lg-5">
              <div class=" col-sm-1"><base-button
                block
                type="primary"
                @click="onClickPlusReflect"
                ><span style="margin-left: -7px">＋</span></base-button
              ></div>
              <div class="button-plusminas"></div>
              <div class="col-sm-1"><base-button
                block
                type="primary"
                @click="onClickMinusReflect"
                ><span style="margin-left: -7px">－</span></base-button
              ></div>

            </div>
          </div>
        </div>
        -->
        <h4 class="">打設範囲名</h4>
        <el-form :model="formData" :rules="rules" ref="form" >
          <el-form-item prop="pointName">
              <el-input
                placeholder="打設範囲名"
                v-model="formData.pointName"
                @blur = "handlePointNameBlur"
              ></el-input>
            </el-form-item>
        </el-form>
        
        <div v-if="operationMode == 1">
          <div class="row mt-2 mb-2 ml-1 mr-1">
            <div class="col-sm-6">
              <base-button
                type="secondary"
                block
                @click="onClickDeselection"
                style="padding: 10px 0px;"
                >選択解除
              </base-button>
            </div>

            <div class="col-sm-6">
              <base-button
                type="primary"
                block
                @click="onClickReflect"
                style="padding: 10px 0px;"
                >反映
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-fill" >
      <overlay-point-drawing class="overlaypoint-drawing"
        ref="map"
        @onAreaClickSelect="onAreaClickSelect"
        @onAreaClickMove="onAreaClickMove"
        @onAreaClickRemove="onAreaClickRemove"
        @onDrawPolygonFinish="onDrawPolygonFinish"
        >
      </overlay-point-drawing>
      <div class="my-3">
        <div style="width: 480px; margin:0 auto;margin-left: 400px;">
          <div class="row mt-2 mb-2 ml-2 mr-2">

            <div class="col-sm-6" >
              <base-button type="primary" block @click="onClickSave" :disabled="isPassedDay  || usePouring"
                >保存</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    v-model:show="noOverlayDrawingmodal"
    size="medium"
    modal-classes=""
    modal-content-classes=""
    body-classes="p-0 secondary"
    >
    <template v-slot:header>
      <h6 class="modal-title">図面未登録</h6>
    </template>
    <card
      type="secondary"
      header-classes="pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="col-md-12">
        <h3 class="mt-0 text-danger">図面情報が登録されていません。</h3>
        <p class="ml-2 my-2">
          図面登録画面より図面を登録してください。
        </p>
      </div>
    </card>
    <div class="row mt-2 mb-2 ml-2 mr-2 d-flex justify-content-between">
      <div class="ml-2">
        <el-button type="text text-warning" @click="noOverlayDrawingmodal = false"
          >後で登録する</el-button
        >
      </div>
      <div class="mr-2">
        <el-button
          type="primary"
          icon="ni ni-square-pin"
          @click="onClickOverlayDrawing"
          >図面登録へ →</el-button
        >
      </div>
    </div>
  </modal>
</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */
.button-area {
  margin-top: 10px;
  width: 100%;
  height: 10px;
}

.overlaydrawing_area {
  /* position:absolute; */
  margin-top: 50px;
  width: 100%;
  height: calc(100% - 180px);
  background-color: white;
  overflow-x: hidden;
  border-radius: 0;
}

.overlaypoint-list {
  width: 270px;
  height: 100%;
}
.button-plusminas {
  margin: 10px;
}
.overlaypoint-drawing {
  height: calc(100% - 80px);
}
</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import OverlayPointModel from "@/appModel/Overlay/OverlayPointModel";
import UserInfo from "@/appUtils/UserInfo";
import TenantStorage from "@/appUtils/TenantStorage";
import OverlayPointDrawing from "@/appViews/Overlay/OverlayPointDrawing.vue";
import Modal from "@/components/Modal";
import { useToast } from "vue-toastification";
import DateUtil from "@/appUtils/DateUtil";
import ValidateUtil from "@/appUtils/ValidateUtil";
import OverlayModel from "@/appModel/Overlay/OverlayModel";
const MAX_POLYGON_VERTEX_COUNT = 100;

// 図形種別: 円
const ID_SHAPE_TYPE_CIRCLE = "0";
// 図形種別: ポリゴン
const ID_SHAPE_TYPE_POLYGON = "1";
// 図形種別: なし（図面操作）
const ID_SHAPE_TYPE_NON = "2";
export default {
  components: {
    ApplicationHeader,
    Modal,
    OverlayPointDrawing
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      defaultPointName: "打設範囲",
      pointName: "",
      pointCount: 1,
      projectId: null,
      projectName: null,
      datestring: null,
      appTitle: "打ち重ね範囲登録",
      iconUrl: null,
      baseAreaList: [],
      removalList: [],
      confirmResultOK: true,
      uploadFiles: [],
      filePath: "",
      tenantStorage: null,
      overlaydrawingId: "",
      imageUrl: null,
      pdfUrl: null,
      filePreview: false,
      operationShape: 2, // 操作描画形状 - 0: 円, 1: ポリゴン, 2: なし
      operationMode: 0, // 操作モード - 0: 作成, 1: 編集, 2: 削除
      pointSize: 10,
      overlayPointList: [],
      additionalList: [],
      modificationList: [],
      selectedPoint: [],
      noOverlayDrawingmodal: false,
      noOverlayDrawing: false,
      isPassedDay: false,
      usePouring: false,
      returnScreen: '',
      formData: {
        pointName: "",
      },
      rules: {
        pointName: [
          { required: true, validator: this.checkName, trigger: "blur" },
        ],
      },
    };
  },
  // コンピュートプロパティ
  computed: {
    currentProject() {
      return this.$store.state.project
    },
    vuename() {
      return "OverlayPoint.vue"
    }
  },
  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },
  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "BaseArea.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.plan.projectid})、projectname(${this.$store.state.plan.projectname})、datestring(${this.$store.state.plan.datestring})`
    );

    this.init();
    const _this = this;
    this.tenantId = this.getTenantId().then(id => {
      _this.tenantId = id
    })
  },
  async beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
    this.init().then(function(){
      console.log(``);
    })
  },
  mounted() {
    // インスタンスが DOM 要素にマウントされた後
    this.addResizeObserver();
  },
  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },
  updated() {
    // データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },
  unmounted() {
    // Vue インスタンスが破壊された後
  },
  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    appLog.infoLog("BaseArea.vue", this.$store.state.user.userId, `End`);
    next();
  },
  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    formatNumber(number) {
      // padStartを使用して数字が3桁であることを確認します
      return String(number).padStart(3, '0');
    },
    /**
     * 図面コンテナのリサイズ監視を開始します。
    */
    addResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$refs.mapContainer);
    },
    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        // store(vuex)から値取得
        this.projectId = this.$store.state.plan.projectid;
        this.datestring = this.$store.state.plan.datestring;
        this.projectName = this.$store.state.plan.projectname;

        this.returnScreen = '＜ ' + DateUtil.dateStringBase(this.$store.state.plan.createdate, 'YYYY/MM/DD') + ' 予定数量';

        const user = await UserInfo.getUserInfo();
        this.tenantStorage = new TenantStorage(user.group);
        this.tenantId = await this.getTenantId();

        // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        this.userAuthority = this.$store.state.user.userType;
        if (this.userAuthority == 0) {
          this.editableFlag = false;
        }

        // 操作描画形状
        this.operationShape = ID_SHAPE_TYPE_NON;
        // 操作モードを設定
        this.operationMode = "0";

        let createDate = this.$store.state.plan.createdate
        let nowDate = DateUtil.getFormatString(Date.now(), 'YYYYMMDD')
        let chkCreateDate = DateUtil.getFormatString(createDate, 'YYYYMMDD')
        if (nowDate > chkCreateDate){
          this.isPassedDay = true
        }

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        this.overlayDrawingId= this.$store.state.overlay.overlayrawingid;

        if (this.overlayDrawingId==null || this.overlayDrawingId==""){
          this.noOverlayDrawingmodal = true;
          this.noOverlayDrawing = true;
        } else {
          await this.$refs.map.mapCreate();
          // ポイント取得
          this.overlayPointList = await OverlayPointModel.getOverlayPointList(this.tenantId, this.projectId, this.overlayDrawingId);

          this.pointCount = this.overlayPointList.length;
          this.setPointName();
          this.drawOverlayPoint(this.overlayPointList);
          this.changeShape();
        }

        //打設が開始されているかるかをチェックする
        this.usePouring = await this.checkPouring();

      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e));
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * ポイント名修得
    */
    setPointName(){
      let greatestPoint = this.greatestPoint();
      let point = greatestPoint.split(this.defaultPointName);
      const pointCount = Number(point[1]);
      if (Number.isInteger(pointCount)) {
        this.pointCount = pointCount+1;
      } else {
        this.pointCount +=1;
      }
      this.formData.pointName = this.defaultPointName + this.formatNumber(this.pointCount);
    },
    /**
     * 最大のポイントを得る
    */
    greatestPoint() {
      let tmpoverlaylist = [
        ...this.overlayPointList,
        ...this.removalList,
        ...this.modificationList,
        ...this.additionalList
      ];
      return tmpoverlaylist.reduce((max, item) => {
        if (!max || item.overlayPoint.pointName > max) {
          return item.overlayPoint.pointName;
        }
        return max;
      }, '');
    },
    /**
     * プロジェクトID修得
    */
    getProjectId() {
      return this.currentProject.sk.split("#")[1];
    },
    /**
     * テナントID修得
    */
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }
      return loginInfo.group;
    },
    /**
     * 打設が開始されているかるかをチェックする
    */
    async checkPouring(){
      let usePouring = false;
      let overlayPouringList = await OverlayModel.getOverlayListByDrawing(this.tenantId, this.projectId, this.overlayDrawingId);
      if (overlayPouringList.length >0){
        usePouring=true;
      }
      return usePouring;
    },
    /**
     * PointName Blur の処理
     */
    handlePointNameBlur() {
      if (this.operationShape == ID_SHAPE_TYPE_POLYGON) {
        this.$refs.map.startDrawPolygonMode();
      } else if (this.operationShape == ID_SHAPE_TYPE_CIRCLE) {
        this.$refs.map.startDrawCircleMode();
      }
    },
    /**
     * 一意のポイント名を確認する
     */
    checkUniquePointName(){
      let hasOriginal = false;
      let hasAdditional = false;
      let hasModification = false;
      let hasremoval = false;
      if (this.operationMode==1){
        hasOriginal =
          this.overlayPointList.filter((list) => list.overlayPoint.pointName == this.formData.pointName && list.overlayPoint.id != this.selectedPoint.overlayPoint.id).length > 0;
        hasAdditional =
          this.additionalList.filter((list) =>list.overlayPoint.pointName == this.formData.pointName && list.overlayPoint.id != this.selectedPoint.overlayPoint.id).length > 0;
        hasModification =
          this.modificationList.filter((list) => list.overlayPoint.pointName == this.formData.pointName && list.overlayPoint.id != this.selectedPoint.overlayPoint.id).length > 0;
        hasremoval =
          this.removalList.filter((list) => list.overlayPoint.pointName == this.formData.pointName && list.overlayPoint.id != this.selectedPoint.overlayPoint.id).length > 0;
      } else {
        hasOriginal =
          this.overlayPointList.filter((list) => list.overlayPoint.pointName == this.formData.pointName).length > 0;
        hasAdditional =
          this.additionalList.filter((list) => list.overlayPoint.pointName == this.formData.pointName).length > 0;
        hasModification =
          this.modificationList.filter((list) => list.overlayPoint.pointName == this.formData.pointName).length > 0;
        hasremoval =
          this.removalList.filter((list) => list.overlayPoint.pointName == this.formData.pointName).length > 0;
      }

      if (hasOriginal || hasAdditional || hasModification || hasremoval) {
        this.$refs.map.stopDrawShapeMode();
        this.showBottomToast(
          `すでに登録済みの打設範囲名です。
別の打設範囲名を指定してください。`,
          "warning"
        );
        return false;
      }
      return true;
    },
    /**
     * 打ち重ね範囲を描画
    */
    async drawOverlayPoint(list) {
      let parameters = [];
      let applicableList = [];
      let item = null;

      for (let index = 0; index < list.length; index++) {
        item = list[index];

        // 削除済みのポイントは表示しない
        if (this.removalList.length > 0) {
          applicableList = this.removalList.filter(
            (ret) => ret.overlayPoint.id == item.overlayPoint.id
          );
          if (applicableList.length > 0) continue;
        }

        parameters.push({
          id: item.overlayPoint.id,
          radius: item.overlayPoint.radius,
          x: item.overlayPoint.x,
          y: item.overlayPoint.y,
          shapeType: item.overlayPoint.shapeType,
          polygon: item.overlayPoint.polygon,
          pointName: item.overlayPoint.pointName,
        });
      }

      if (parameters.length > 0) {
        this.$refs.map.drawoverlay(parameters);
      }
    },
    /**
     * 操作描画形状変更イベント
    */
    changeShape() {
      // 図面側に操作図形種別をセット
      this.$refs.map.operationShape = this.operationShape;

      // 編集モードで、編集対象図形を未選択の場合は、処理しない
      if (this.operationMode == 1) {
        if (this.selectedPoint.length == 0){
          return;
        }
      }

      // 操作図形がポリゴンの時は、ポリゴン描画モードにする
      if (this.operationShape == ID_SHAPE_TYPE_POLYGON) {
        this.$refs.map.startDrawPolygonMode();
      } else if (this.operationShape == ID_SHAPE_TYPE_CIRCLE) {
        this.$refs.map.startDrawCircleMode();
      } else {
        this.$refs.map.stopDrawShapeMode();
      }
    },
    /**
     * 操作モード変更イベント
    */
    changeMode() {
      this.$refs.map.operationMode = this.operationMode;
      this.$refs.map.isSelected = false;
      this.$refs.map.clearTempPoi();
      this.selectedPoint = [];
      this.$refs.map.stopDrawShapeMode();

      if (this.operationMode == 0 && this.operationShape == ID_SHAPE_TYPE_POLYGON){
        this.$refs.map.startDrawPolygonMode();
      } else if (this.operationMode == 0 && this.operationShape == ID_SHAPE_TYPE_CIRCLE){
        this.$refs.map.startDrawCircleMode();
      }
    },
    /**
     * OverlayPointDrawing.vueの図面クリックイベント(編集モード・選択)
    */
    onAreaClickSelect(item) {
      try {

        let overlayPoint = this.overlayPointList.filter((list) => list.overlayPoint.id == item.id);
        if (overlayPoint != null && overlayPoint.length > 0) {
          this.selectedPoint = overlayPoint[0];
          this.formData.pointName = this.selectedPoint.overlayPoint.pointName;
          this.pointSize = this.selectedPoint.overlayPoint.radius;
        } else {
          this.selectedPoint = this.additionalList.filter(
            (list) => list.overlayPoint.id == item.id
          )[0];
          this.formData.pointName = this.selectedPoint.overlayPoint.pointName;
          this.pointSize = this.selectedPoint.overlayPoint.radius;

        }
        if (this.selectedPoint != null && this.selectedPoint.length > 0) {
          this.formData.pointName = this.selectedPoint.overlayPoint.pointName;
          this.pointSize = this.selectedPoint.overlayPoint.radius;
        }

        // 図形の種別をセット
        this.operationShape = this.selectedPoint.overlayPoint.shapeType;
        this.$refs.map.operationShape = this.operationShape;
        if (this.operationShape == ID_SHAPE_TYPE_POLYGON){
          this.$refs.map.startDrawPolygonMode();
        } else if (this.operationShape == ID_SHAPE_TYPE_CIRCLE){
          this.$refs.map.startDrawCircleMode();
        } else {
          this.$refs.map.stopDrawShapeMode();
        }
        // OverlayPointDrawingの選択フラグを変更
        this.$refs.map.isSelected = true;
      } catch (e) {
        this.errorLog("onAreaClickSelect", this.parseErrorObject(e));
      }
    },
    /**
    * OverlayPointDrawing.vueの図面クリックイベント(作成モード)
    */
    async onAreaClickAdd(params) {
      try {

        // 操作描画形状が円以外は処理を抜ける
        if (this.operationShape != ID_SHAPE_TYPE_CIRCLE) return;
        console.log("pointSize  " + this.pointSize)

        // 追加リストに追加
        let data = await OverlayPointModel.getNewData(this.projectId, this.overlayDrawingId);
        data.overlayPoint.radius = this.pointSize;
        data.overlayPoint.x = params.x;
        data.overlayPoint.y = params.y;
        data.overlayPoint.shapeType = ID_SHAPE_TYPE_CIRCLE;
        data.overlayPoint.pointName = this.formData.pointName;
        this.additionalList.push(data);
        // 図面再読み込み
        let parameters = [
          {
            id: data.overlayPoint.id,
            shapeType: data.overlayPoint.shapeType,
            radius: data.overlayPoint.radius,
            x: data.overlayPoint.x,
            y: data.overlayPoint.y,
            pointName: data.overlayPoint.pointName,
          },
        ];

        this.$refs.map.drawoverlay(parameters);
        this.setPointName();
      } catch (e) {
        this.errorLog("onAreaClickAdd", this.parseErrorObject(e));
      }
    },
    /**
     * OverlayPointDrawing.vueの図面クリックイベント(編集モード・移動)
    */
    onAreaClickMove(params) {
      if (this.operationShape == ID_SHAPE_TYPE_CIRCLE) {
        this.reflectChanges(params);
      }
    },
    /**
     * 編集中の内容を反映
     */
    async reflectChanges(params) {
      try {
        // 編集前アイコン・図形削除
        this.$refs.map.deleteShapeById(this.selectedPoint.overlayPoint.id);
        this.$refs.map.deletePoiById(
          this.selectedPoint.overlayPoint.id, this.selectedPoint.overlayPoint.pointName
        );
        this.$refs.map.clearTempPoi();

        // 選択中ポイントの値を変更
        this.selectedPoint.overlayPoint.radius = this.pointSize;
        if (params) {
          this.selectedPoint.overlayPoint.x = params.x;
          this.selectedPoint.overlayPoint.y = params.y;
        }

        // IDが同じデータを変更リストから削除
        this.modificationList = this.modificationList.filter(
          (list) => list.overlayPoint.id != this.selectedPoint.overlayPoint.id
        );

        // リストに追加
        if (
          this.additionalList.filter(
            (list) => list.overlayPoint.id == this.selectedPoint.overlayPoint.id
          ).length > 0
        ) {
          // 追加リストに存在する場合
          // 既存データを削除
          this.additionalList = this.additionalList.filter(
            (list) => list.overlayPoint.id != this.selectedPoint.overlayPoint.id
          );

          // 変更データを追加リストに再度追加
          this.additionalList.push(this.selectedPoint);
        } else {
          // 追加リストに存在しない場合
          // 変更リストに追加
          this.modificationList.push(this.selectedPoint);
        }

        // 変更後アイコン・図形追加
        let parameters = [
          {
            id: this.selectedPoint.overlayPoint.id,
            pointName: this.selectedPoint.overlayPoint.pointName,
            radius: this.selectedPoint.overlayPoint.radius,
            x: this.selectedPoint.overlayPoint.x,
            y: this.selectedPoint.overlayPoint.y,
          },
        ];
        this.$refs.map.drawoverlay(parameters);

        // OverlayPointDrawingの選択フラグを変更
        this.$refs.map.isSelected = false;

        // 選択中ポイントを初期化
        this.selectedPoint = [];
        this.setPointName();
        this.$refs.map.stopDrawShapeMode();
      } catch (e) {
        this.errorLog("reflectChanges", this.parseErrorObject(e));
      }
    },
    /**
     * OverlayPointDrawing.vueの図面クリックイベント(削除モード)
    */
    async onAreaClickRemove(params) {
      try {
        // オリジナル・追加リスト確認
        const hasOriginal =
          this.overlayPointList.filter((list) => list.overlayPoint.id == params.id).length > 0;
        const hasAdditional =
          this.additionalList.filter((list) => list.overlayPoint.id == params.id)
            .length > 0;

        // 追加リスト・変更リストから削除
        this.additionalList = this.additionalList.filter(
          (list) => list.overlayPoint.id != params.id
        );
        this.modificationList = this.modificationList.filter(
          (list) => list.overlayPoint.id != params.id
        );

        // オリジナルリストに存在する、もしくは追加リストに存在しない場合
        // 除去リストに追加
        if (hasOriginal || !hasAdditional) {
          let overlayPoint = this.overlayPointList.filter((list) => list.overlayPoint.id == params.id);
          if (overlayPoint.length > 0) {
            let data = await OverlayPointModel.getNewData(this.projectId, this.overlayDrawingId);
            data.sk = OverlayPointModel.createSk(this.projectId, this.overlayDrawingId, params.id);
            data.overlayPoint.x = params.x;
            data.overlayPoint.y = params.y;
            data.overlayPoint.id = params.id;
            this.removalList.push(data);
          }
        }

      } catch (e) {
        this.errorLog("onAreaClickRemove", this.parseErrorObject(e));
      }
    },
    /**
     * OverlayPointDrawing.vueの図面イベント(終了)
    */
    async onDrawPolygonFinish(params) {
      this.$refs.form.validate(
        async function(result) {
          // フォーム入力基本チェック
          if (!result) {
            console.log(`error`);
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          if (!this.checkUniquePointName()) return;
          if (this.operationShape == ID_SHAPE_TYPE_POLYGON) {
            // 頂点数チェック
            let vertexCount = params.layer.getLatLngs()[0].length;

            if (MAX_POLYGON_VERTEX_COUNT < vertexCount) {
              this.showBottomToast(
                `ポリゴンの頂点数は ${MAX_POLYGON_VERTEX_COUNT} までです。`,
                "warning"
              );
              return;
            }
          }

          if (this.operationShape == ID_SHAPE_TYPE_CIRCLE) {
            let p= { x: params.layer._latlng.lng, y: params.layer._latlng.lat };
            this.pointSize =parseFloat(params.layer._mRadius.toFixed(3));
            if (this.operationMode == 0){
              this.onAreaClickAdd(p);
            } else if (this.operationMode == 1){
              this.reflectChanges(p);
            }
          }
          else if (this.operationShape == ID_SHAPE_TYPE_POLYGON) {
            if (this.operationMode == 0){
              this.addPointPolygon(params);
            } else if (this.operationMode == 1){
              this.editPointPolygon(params);
            }
          }
        }.bind(this));
      
    },
    /**
      * ポイント図形（ポリゴン）追加処理
    */
    async addPointPolygon(params){
      try {
        // 面上にある点を取得
        let p = CoordinateUtil.pointOnSurface(params.layer);
        let data = await OverlayPointModel.getNewData(this.projectId, this.overlayDrawingId);
        data.overlayPoint.x = p[0];
        data.overlayPoint.y = p[1];
        data.overlayPoint.radius = 0;
        data.overlayPoint.shapeType = ID_SHAPE_TYPE_POLYGON;
        data.overlayPoint.polygon = MLCompressUtil.latLngArrayToBase64(params.layer.getLatLngs()[0]);
        data.overlayPoint.pointName = this.formData.pointName;
        this.additionalList.push(data);
        // 図面再読み込み
        let parameters = [
          {
            shapeType: data.overlayPoint.shapeType,
            polygon: data.overlayPoint.polygon,
            id: data.overlayPoint.id,
            radius: data.overlayPoint.radius,
            x: data.overlayPoint.x,
            y: data.overlayPoint.y,
            pointName: data.overlayPoint.pointName,
          },
        ];
        this.$refs.map.drawoverlay(parameters);
        this.setPointName();
      } catch (e) {
        this.errorLog("addPointPolygon", this.parseErrorObject(e));
      }
    },
    /**
     * ポイント図形（ポリゴン）編集処理
    */
    async editPointPolygon(params){
      try {

        // 編集前アイコン・図形削除
        console.log("############################## test : ", this.selectedPoint.overlayPoint)
        this.$refs.map.deleteShapeById(this.selectedPoint.overlayPoint.id);
        this.$refs.map.deletePoiById(this.selectedPoint.overlayPoint.id, this.selectedPoint.overlayPoint.pointName);
        this.$refs.map.clearTempPoi();

        // 選択中ポイントの値を変更
        this.selectedPoint.overlayPoint.shapeType = ID_SHAPE_TYPE_POLYGON;
        this.selectedPoint.overlayPoint.radius = 0;
        if (params != null){
          // 面上にある点を取得
          let p = CoordinateUtil.pointOnSurface(params.layer);
          this.selectedPoint.overlayPoint.x = p[0];
          this.selectedPoint.overlayPoint.y = p[1];
          this.selectedPoint.overlayPoint.polygon = MLCompressUtil.latLngArrayToBase64(params.layer.getLatLngs()[0]);
        }
        // IDが同じデータを変更リストから削除
        this.modificationList = this.modificationList.filter(
          (list) => list.overlayPoint.id != this.selectedPoint.overlayPoint.id
        );

        // リストに追加
        if (
          this.additionalList.filter(
            (list) => list.overlayPoint.id == this.selectedPoint.overlayPoint.id
          ).length > 0
        ) {
          // 追加リストに存在する場合
          // 既存データを削除
          this.additionalList = this.additionalList.filter(
            (list) => list.overlayPoint.id != this.selectedPoint.overlayPoint.id
          );

          // 変更データを追加リストに再度追加
          this.additionalList.push(this.selectedPoint);
        } else {
          // 追加リストに存在しない場合
          // 変更リストに追加
          this.modificationList.push(this.selectedPoint);
        }

        // 変更後アイコン・図形追加
        let parameters = [
          {
            shapeType: this.selectedPoint.overlayPoint.shapeType,
            polygon: this.selectedPoint.overlayPoint.polygon,
            id: this.selectedPoint.overlayPoint.id,
            pointName: this.selectedPoint.overlayPoint.pointName,
            radius: this.selectedPoint.overlayPoint.radius,
            x: this.selectedPoint.overlayPoint.x,
            y: this.selectedPoint.overlayPoint.y,
          },
        ];
        this.$refs.map.drawoverlay(parameters);

        // OverlayPointDrawingの選択フラグを変更
        this.$refs.map.isSelected = false;

        // 選択中ポイントを初期化
        this.selectedPoint = [];
        this.setPointName();
        // ポリゴン作図モード終了
        this.$refs.map.stopDrawShapeMode();

      } catch (e) {
        this.errorLog("editPointPolygon", this.parseErrorObject(e));
      }
    },
    /**
     * 保存ボタンクリックイベント
     */
    async onClickSave() {
      let loader = this.showLoader();
      try {

        this.infoLog("onClickSave", `追加${this.additionalList.length} 更新${this.modificationList.length} 削除${this.removalList.length}`);
        // 追加リストのデータをDBに登録
        for await (let data of this.additionalList) {
          console.log(`追加 ${JSON.stringify(data, null, "\t")}`);
          await OverlayPointModel.addOverlayPoint(data);
        }
        this.additionalList = [];

        // 変更リストのデータでDBを更新
        for await (let data of this.modificationList) {
          await OverlayPointModel.updateOverlayPoint(data);
        }
        this.modificationList = [];

        // 除去リストのデータをDBから削除
        for await (let data of this.removalList) {
          await OverlayPointModel.deleteOverlayPoint({ pk: data.pk, sk: data.sk });
        }
        this.removalList = [];

        this.overlayPointList = await OverlayPointModel.getOverlayPointList(this.tenantId, this.projectId, this.overlayDrawingId);

        // 完了メッセージ
        this.showBottomToast(`打設範囲登録処理が完了しました。`, 'info');
        // ローディング終了
        this.hideLoader(loader);

      } catch (e) {
        this.errorLog("onClickSave", this.parseErrorObject(e));
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * ポイントサイズ「✚」「ー」ボタン処理
     */
    /*
    onClickPlusReflect(){
      // 図形未選択の場合は処理を抜ける
      if (this.selectedPoint.length == 0) {
        this.showBottomToast(`反映対象の図形が選択されていません。\n図形を選択してください。`, "warning");
        return;
      }
      this.pointSize =parseFloat((this.pointSize+10).toFixed(3));
      this.Reflect();
    },

    onClickMinusReflect(){
      // 図形未選択の場合は処理を抜ける
      if (this.selectedPoint.length == 0) {
        this.showBottomToast(`反映対象の図形が選択されていません。\n図形を選択してください。`, "warning");
        return;
      }
      this.pointSize =parseFloat((this.pointSize-10).toFixed(3));
      this.Reflect();
    },

    Reflect() {
      // 図形種別に合わせて処理分岐
      if (this.selectedPoint.overlayPoint.shapeType == ID_SHAPE_TYPE_CIRCLE){
        this.reflectChanges(null);
      }
    },
    */

    /**
     * 選択解除ボタンクリックイベント
    */
    onClickDeselection() {
      this.$refs.map.isSelected = this.false;
      this.$refs.map.clearTempPoi();
      this.selectedPoint = [];
      this.formData.pointName = this.defaultPointName + this.formatNumber(this.pointCount);
      this.$refs.map.stopDrawShapeMode();
    },
    /**
     * 反映ボタンクリックイベント
     */
    onClickReflect() {
      // 図形未選択の場合は処理を抜ける
      if (this.selectedPoint.length == 0) {
        this.showBottomToast(`反映対象の図形が選択されていません。\n図形を選択してください。`, "warning");
        return;
      }
      this.$refs.form.validate(
        async function(result) {
          // フォーム入力基本チェック
          if (!result) {
            console.log(`error`);
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          if (!this.checkUniquePointName()) return;
          this.$refs.map.deletePoiById(
            this.selectedPoint.overlayPoint.id, this.selectedPoint.overlayPoint.pointName
          );
          this.selectedPoint.overlayPoint.pointName =this.formData.pointName ;
          // 図形種別に合わせて処理分岐
          if (this.selectedPoint.overlayPoint.shapeType == ID_SHAPE_TYPE_CIRCLE){
            this.reflectChanges(null);
          } else if (this.selectedPoint.overlayPoint.shapeType == ID_SHAPE_TYPE_POLYGON){
            this.editPointPolygon(null);
          }
        }.bind(this));
    },
    /**
     * 図面登録ボタンクリック時の処理
     */
    async onClickOverlayDrawing() {
      // 打ち重ね図面登録画面起動
      this.$router.push({
        path: "/overlayDrawing",
      });
    },
    /**
     * 運行情報画面ボタンクリック
    */
    async onClickDriveVue(){
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },
    /**
     * 予定登録日一覧画面に遷移
     */
    onClickReturn(){
      this.$router.push({
        path: this.getRoutePath(`planEdit`),
      });
    },
    /**
     * フォームのバリデーション(ポイント名)
    */
    checkName(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * 打設範囲登録ボタンクリック時の処理
     */
    async onClickOverlayPointRegister() {
      // 打設範囲登録画面起動
      this.$router.push({
        path: "/overlayTimeSetting",
      });
    },
    /**
     * 経過時間設定ボタンクリック時の処理
     */
    async onClickOverlayTimeSetting() {
      // 経過時間設定画面起動
      this.$router.push({
        path: "/overlayTimeSetting",
      });
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e);
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e);
      }
    },
  },
};
</script>
